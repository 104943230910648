import { Dispatch } from 'redux'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createReducer } from '@reduxjs/toolkit'
import { IUserInfo, UserInfo } from 'shared/proto/models'
import { IAppState } from '../rootReducer'
import { AuthService } from 'external/rp.ui/auth/services/authService'

const url = 'user'
export interface IUserState {
  userInfo: IUserInfo
}

const initialState: IUserState = {
  userInfo: {},
}

const GET_USER_INFO = 'GET_USER_INFO'
export const getUserInfo = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const res = await ProtoClient.get<IUserInfo>(url + '/info', UserInfo)
  //TODO: Раскомментировать после применения дизайна
  // if (res.imagePath) {
  //   const imageResponse = await WebClient.request('personal-profile/image/' + res.imagePath, 'GET')
  //   const image = await imageResponse.blob()
  //   res.imagePath = await formatImage(image)
  // }
  dispatch({ type: GET_USER_INFO, payload: res })
}

export const logout = () => {
  AuthService.Logout()
}

export default createReducer(initialState, {
  [GET_USER_INFO]: (state, action) => {
    state.userInfo = action.payload
  },
})
