import { createAction, createReducer } from '@reduxjs/toolkit'

export interface IProductCategoryState {
  isEditWindowOpen: boolean
}

const initialState: IProductCategoryState = {
  isEditWindowOpen: false,
}

const SET_PRODUCT_CATEGORY_MODAL_OPEN = 'SET_PRODUCT_CATEGORY_MODAL_OPEN'
export const setProductCategoryModalOpen = createAction(SET_PRODUCT_CATEGORY_MODAL_OPEN)

const productCategoryReducer = createReducer(initialState, {
  [SET_PRODUCT_CATEGORY_MODAL_OPEN]: (state, action) => {
    state.isEditWindowOpen = !state.isEditWindowOpen
  },
})

export default productCategoryReducer
