import RecordActionDialog, { RecordActionParams } from 'external/rp.ui/components/Modal/RecordActionModalDialog'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Link, navigate, withPrefix } from 'gatsby'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { initialize } from 'redux-form'
import { PagingAndSort } from 'shared/proto/models'

import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { IProvisorInvite, IProvisorInvites, ProvisorInvites } from '../../../proto/models'
import { IAppState } from '../../../reducers/rootReducer'
import { tableIcons } from '../../shared/tableIcons'

interface IProvisorInvitesDictionaryContext {
  dispatch: Dispatch
  path: string
}

class ProvisorInvitesDictionary extends React.Component<IProvisorInvitesDictionaryContext> {
  constructor(props: IProvisorInvitesDictionaryContext) {
    super(props)

    this.tableRef = React.createRef()
  }

  private tableRef: any

  private url = 'dictionaries/provisor-invite'

  private navigateToEditWindow = (provisorInviteId?: Uint8Array) => {
    let path = withPrefix('back-office/provisor-invite/edit')

    if (provisorInviteId) {
      const provisorInviteIdStr = GuidHelper.toString(provisorInviteId)
      path = `${path}/${provisorInviteIdStr}`
    }

    navigate(path)
  }

  private fetchProvisorInvites = async (query: Query<IProvisorInvite>): Promise<QueryResult<IProvisorInvite>> => {
    const paging = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: query.orderBy?.field ?? 'email',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
    })

    const provisorInvites = await ProtoClient.get<IProvisorInvites>(this.url, ProvisorInvites, paging)

    return {
      data: provisorInvites?.items ?? [],
      page: query.page,
      totalCount: provisorInvites?.rowCount ?? 0,
    }
  }

  private onEditButtonClick = (rowData: IProvisorInvite): void => {
    const form = {
      provisor: {
        id: rowData.id,
        name: rowData.email,
      },
    }
    this.props.dispatch(initialize('provisorInviteEditForm', form))
    this.navigateToEditWindow(rowData.id)
  }

  private onAddButtonClick = (): void => {
    this.navigateToEditWindow()
  }

  private onDeleteButtonClick = async (rowData: IProvisorInvite) => {
    await ProtoClient.delete(this.url, GuidHelper.toString(rowData.id))
    this.tableRef.current && this.tableRef.current.onQueryChange()
  }

  private ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n пользователя?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        await props.onAgreeBtnClick(rowData)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  viewColumnRenderer = (rowData: IProvisorInvite): JSX.Element => (
    <Link to={withPrefix(`back-office/provisor-invite/${GuidHelper.toString(rowData.id)}`)}>
      <Fab size="small" color="primary" aria-label="edit" style={{ margin: 0 }}>
        <VisibilityIcon />
      </Fab>
    </Link>
  )

  render() {
    return (
      <MaterialTable<IProvisorInvite>
        tableRef={this.tableRef}
        localization={LocalizationHelper.GetLocalization()}
        icons={tableIcons}
        columns={[
          // {
          //   title: '',
          //   filtering: false,
          //   sorting: false,
          //   field: 'edit',
          //   render: (rowData) => (
          //     <Fab
          //       size="small"
          //       color="primary"
          //       aria-label="edit"
          //       style={{ margin: 0 }}
          //       onClick={() => this.onEditButtonClick(rowData)}
          //     >
          //       <EditIcon />
          //     </Fab>
          //   ),
          // },
          {
            title: 'ФИО',
            field: 'fullName',
          },
          {
            title: 'Почта',
            field: 'email',
          },
          {
            title: 'Код',
            field: 'inviteCode',
            filtering: false,
            sorting: false,
          },
          {
            title: 'Приглашено',
            field: 'invitedPharmacistCount',
          },
          {
            title: 'Заблокирован',
            field: 'blocked',
            filtering: false,
            sorting: false,
            render: (rowData) => (rowData.blocked ? 'Да' : 'Нет'),
          },
          {
            title: '',
            filtering: false,
            sorting: false,
            field: 'view',
            render: this.viewColumnRenderer,
          },
          {
            title: '',
            filtering: false,
            sorting: false,
            field: 'delete',
            render: (rowData) => (
              <this.ConfirmDeletionModalButton
                onAgreeBtnClick={() => this.onDeleteButtonClick(rowData)}
                rowData={rowData}
                tableRef={this.tableRef}
              />
            ),
          },
        ]}
        options={{
          emptyRowsWhenPaging: false,
          draggable: false,
          pageSize: 10,
        }}
        data={this.fetchProvisorInvites}
        title="Приглашения провизоров"
        components={{
          Toolbar: (toolbarProps) => (
            <Box>
              <MTableToolbar {...toolbarProps} />
              <Box ml={2}>
                <Grid container direction={'row'} alignItems={'flex-start'}>
                  <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={(e) => this.onAddButtonClick()}>
                      Добавить
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ),
        }}
      />
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(ProvisorInvitesDictionary)
