import { BackOffice } from 'back-office'
import React, { ReactElement } from 'react'

import { withStyles } from '@material-ui/styles'

const GlobalCss = withStyles({
  '@global': {
    '.MuiTypography-root': {
      fontFamily: 'Inter',
    },
    '.MuiRadio-colorPrimary.Mui-checked': {
      color: '#30BE61',
    },
    '.MuiRadio-colorSecondary.Mui-checked': {
      color: '#30BE61',
    },
    '.MuiSlider-colorPrimary': {
      color: '#1EB2E0',
    },
    '.MuiSlider-colorSecondary': {
      color: '#1EB2E0',
    },
    '.MuiLinearProgress-colorPrimary': {
      backgroundColor: 'rgb(190, 190, 190, .3)',
    },
    '.MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#30BE61',
    },
  },
})(() => null)

const Main = (): ReactElement => (
  <>
    <GlobalCss />
    <BackOffice />
  </>
)

export default Main
