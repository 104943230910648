import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { navigate, withPrefix } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  Field,
  Form,
  FormErrors,
  formValueSelector,
  initialize,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
  WrappedFieldProps,
} from 'redux-form'

import { SelectField, TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  EmailExist,
  EOrganizationType,
  IEmailExist,
  IRoles,
  ISearchDto,
  ISearchDtos,
  IUserInvite,
  Roles,
  SearchDtos,
  UserInvite,
} from '../../../proto/models'
import { IAppState } from '../../../reducers/rootReducer'
import OrganizationSelectField from '../SelectFieldOrganizationType/OrganizationType'

export interface IUserInviteEditWindowContext {
  id?: string
  path: string
  organizationTypeValue: EOrganizationType
  organizationRegionPresenceValue: ISearchDto
  dispatch: Dispatch
  rolesValue: string[]
}

const requiredFieldError = 'Не заполнено обязательное поле'

const required = (value: any) => (value ? undefined : requiredFieldError)
const email = (value: string) => {
  const re = /[a-z0-9]*@[a-z0-9]*\.[a-z0-9]+/i
  return value.search(re) == -1 ? 'Адрес неверного формата' : undefined
}

const AutocompleteField = (
  props: { url: string; organizationRegionPresenceValue?: ISearchDto; disabled: boolean } & WrappedFieldProps
) => {
  const [options, setOptions] = React.useState<ISearchDto[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const onSearchValueChange = async (searchString: string): Promise<ISearchDto[]> => {
    if (!searchString || searchString === '') {
      return []
    }

    const params = {
      searchString: searchString,
    }
    if (props.organizationRegionPresenceValue) {
      params.id = GuidHelper.toString(props.organizationRegionPresenceValue?.id)
    }
    const res = await ProtoClient.get<ISearchDtos>(props.url, SearchDtos, params)
    return res?.items ?? []
  }
  const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)
  const {
    input: { onChange },
  } = props
  return (
    <Autocomplete
      multiple={false}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          meta={{ ...props.meta }}
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            readOnly: props.disabled,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const RoleSelect = (props: any) => {
  const [options, setOptions] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    if (options.length === 0) {
      setLoading(true)
      getRoles().then((res) => {
        setOptions(res)
        setLoading(false)
      })
    }
  }, [])

  props.input.value = !Array.isArray(props.input.value) ? [props.input.value] : props.input.value

  return (
    <SelectField {...props} multiple variant="outlined" fullwidth>
      {loading ? (
        <MenuItem>
          <CircularProgress color="inherit" size={20} />
        </MenuItem>
      ) : (
        options?.map((role, i) => (
          <MenuItem key={i} value={role}>
            {role}
          </MenuItem>
        ))
      )}
    </SelectField>
  )
}

const getorganizationRegionPresenceUrl = (type: EOrganizationType) => {
  if (
    type === EOrganizationType.OrganizationRegionPresence ||
    type === EOrganizationType.ManufacturerSubdivision ||
    type === EOrganizationType.Pharmacy
  ) {
    return 'dictionaries/user-invite/search-organization'
  }
}

const getSubdivisionPharmacyUrl = (type: EOrganizationType) => {
  if (type === EOrganizationType.ManufacturerSubdivision) {
    return 'dictionaries/user-invite/search-manufacturers-subdivision'
  }

  if (type === EOrganizationType.Pharmacy) {
    return 'dictionaries/user-invite/search-pharmacies'
  }
}

const EditForm = (
  props: IUserInviteEditWindowContext & InjectedFormProps<IUserInvite, IUserInviteEditWindowContext, string>
) => {
  const {
    error,
    handleSubmit,
    submitting,
    pristine,
    reset,
    organizationTypeValue,
    organizationRegionPresenceValue,
    id,
    dispatch,
    rolesValue,
  } = props

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Пользователь</DialogTitle>
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Почта</FormLabel>
              <Field
                placeholder={'Введите адрес электронной почты'}
                name={'email'}
                component={TextField}
                validate={[required, email]}
                maxLength={255}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Роли</FormLabel>
              <Field name={'roles'} component={RoleSelect} validate={required} variant="outlined" />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Привязать к</FormLabel>
              <Field
                name={'organizationType'}
                component={OrganizationSelectField}
                roles={rolesValue}
                validate={required}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Организация в регионе присутствия</FormLabel>
              <Field
                name={'organizationRegionPresence'}
                component={AutocompleteField}
                url={getorganizationRegionPresenceUrl(organizationTypeValue)}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Подразделение / Аптека</FormLabel>
              <Field
                name={'subdivisionPharmacy'}
                component={AutocompleteField}
                url={getSubdivisionPharmacyUrl(organizationTypeValue)}
                organizationRegionPresenceValue={organizationRegionPresenceValue}
                disabled={organizationTypeValue == EOrganizationType.OrganizationRegionPresence}
              />
            </FormControl>
          </Box>
          <Box p={1}>{error && <strong>{error}</strong>}</Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
            Продолжить
          </Button>

          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              reset()
              navigate(withPrefix('back-office/user-invite'))
            }}
          >
            Назад
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const url = 'dictionaries/user-invite'
const addUserInvite = async (newUserInvite: IUserInvite) => {
  await ProtoClient.put<IUserInvite>(url, UserInvite.create(newUserInvite), UserInvite, UserInvite)
}

const updateUserInvite = async (newUserInvite: IUserInvite) => {
  await ProtoClient.post<IUserInvite>(url, UserInvite.create(newUserInvite), UserInvite, UserInvite)
}

const isEmailExist = async (email: string | null): Promise<boolean> => {
  const isEmailExistUrl = `${url}/is-email-exist`
  const emailExist = await ProtoClient.get<IEmailExist>(isEmailExistUrl, EmailExist, { email })

  return emailExist.isEmailExist
}

const getUserInvite = async (id: string, dispatch: Dispatch) => {
  const invite = await ProtoClient.get<IUserInvite>(`${url}/edit`, UserInvite, { id })
  dispatch(initialize('userInviteEditForm', invite))
}

const getRoles = async () => {
  const roles = await ProtoClient.get<IRoles>(`${url}/roles`, Roles)
  return roles.roles
}

const validate = (values: IUserInvite, props: IUserInviteEditWindowContext): FormErrors<IUserInvite, string> => {
  const errors: FormErrors<IUserInvite, string> = {}
  if (
    (values.organizationType === EOrganizationType.Pharmacy ||
      values.organizationType === EOrganizationType.ManufacturerSubdivision) &&
    !values.subdivisionPharmacy
  ) {
    errors.subdivisionPharmacy = requiredFieldError
  }

  if (values.organizationType === EOrganizationType.OrganizationRegionPresence && !values.organizationRegionPresence) {
    errors.organizationRegionPresence = requiredFieldError
  }

  if (!values.organizationType) {
    errors.organizationType = requiredFieldError
  }

  if (!values.roles || values.roles.length === 0) {
    errors.roles = requiredFieldError
  }

  return errors
}

const UserInviteEditWindow = reduxForm<IUserInvite, IUserInviteEditWindowContext>({
  form: 'userInviteEditForm',
  validate,
  onSubmit: async (values, dispatch, props) => {
    if (!values.id && (await isEmailExist(values.email))) {
      throw new SubmissionError({
        email: 'Пользователь с таким email уже зарегистрирован в системе',
        _error: 'Не удлалось создать приглашение',
      })
    }
    if (values.id) {
      await updateUserInvite(values)
    } else {
      await addUserInvite(values)
    }
    navigate(withPrefix('back-office/user-invite'))
  },
})(EditForm)

const selector = formValueSelector('userInviteEditForm')

const mapStateToProps = (store: IAppState) => {
  const { organizationType, organizationRegionPresence, roles } = selector(
    store,
    'organizationType',
    'organizationRegionPresence',
    'roles'
  )
  return {
    dispatch: store.dispatch,
    organizationTypeValue: organizationType,
    organizationRegionPresenceValue: organizationRegionPresence,
    rolesValue: roles,
  }
}

export default connect(mapStateToProps, null)(UserInviteEditWindow)
