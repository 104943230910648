import { tableIcons } from 'back-office/components/shared/tableIcons'
import { ISeller, ISellers, Seller, Sellers } from 'back-office/proto/models'
import RecordActionDialog, { RecordActionParams } from 'external/rp.ui/components/Modal/RecordActionModalDialog'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { IPagingAndSort } from 'shared/proto/models'

import { Box, Button, Fab, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

interface ISellerTableOwnProps {
  requestUrl: string
  onEditButtonClick: (rowData: ISeller) => any
  onDeleteButtonClick: (rowData: ISeller) => any
  onAddButtonClick: () => void
  tableRef: any
}

const fetchData = async (query: Query<ISeller>, requestUrl: string): Promise<QueryResult<ISeller>> => {
  const paging: IPagingAndSort = {
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'name',
    sortDirection: query.orderDirection,
    searchQuery: query.search,
  }

  const sellers = await await ProtoClient.get<ISellers>(requestUrl, Sellers, paging)

  return {
    data: sellers?.items ?? [],
    page: query.page,
    totalCount: sellers?.rowCount ?? 0,
  }
}

const SellersTable = (props: ISellerTableOwnProps): React.ReactElement<ISellerTableOwnProps> => {
  const [isLoading, setLoading] = React.useState(false)

  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <MTableToolbar {...toolbarProps} />
      <Box ml={2}>
        <Grid container direction={'row'} alignItems={'flex-start'}>
          <Grid item xs={1}>
            <Button style={{ marginLeft: 25 }} variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  const ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n запись справочника?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        setLoading(true)
        await props.onAgreeBtnClick(rowData)
        props.tableRef.current && props.tableRef.current.onQueryChange()
        setLoading(false)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  const editColumnRenderer = (rowData: ISeller): JSX.Element => (
    <Fab
      onClick={() => props.onEditButtonClick(rowData)}
      size="small"
      color="primary"
      aria-label="edit"
      style={{ margin: 0 }}
    >
      <EditIcon />
    </Fab>
  )
  const deleteColumnRenderer = (rowData: ISeller): JSX.Element => (
    <ConfirmDeletionModalButton
      onAgreeBtnClick={props.onDeleteButtonClick}
      rowData={rowData}
      tableRef={props.tableRef}
    />
  )

  return (
    <MaterialTable<ISeller>
      tableRef={props.tableRef}
      isLoading={isLoading}
      localization={{
        toolbar: {
          searchTooltip: 'Поиск',
          searchPlaceholder: 'Поиск',
        },
        pagination: {
          labelRowsSelect: 'строк',
        },
      }}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: editColumnRenderer,
        },
        {
          title: 'Наименование',
          field: 'name',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: deleteColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: true,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, props.requestUrl)}
      title=""
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default SellersTable
