import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm, Validator } from 'redux-form'

import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'

import { IRegionalManager } from '../../../proto/models'

export interface RegionalManagerOwnProps {
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
}

const required: Validator = (value) => {
  return !value || value.toString().trim() === '' ? 'Не заполнено обязательное поле' : undefined
}

const EditForm = (
  props: RegionalManagerOwnProps & InjectedFormProps<IRegionalManager, RegionalManagerOwnProps, string>
) => {
  const { handleSubmit, submitting } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Региональный менеджер</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Наименование</FormLabel>
                <Field
                  placeholder={'Введите наименование'}
                  name={'name'}
                  component={TextField}
                  validate={required}
                  maxLength={255}
                />
              </Box>
              <Box p={1}>
                <FormLabel component="legend">Краткое наименование</FormLabel>
                <Field
                  placeholder={'Введите Краткое наименование'}
                  name={'shortName'}
                  component={TextField}
                  maxLength={255}
                  multiline
                  rowsMax={4}
                />
              </Box>
            </FormControl>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const RegionalManagerEditForm = reduxForm<IRegionalManager, RegionalManagerOwnProps>({
  form: 'regionalManagerEditForm',
})(EditForm)

export default RegionalManagerEditForm
