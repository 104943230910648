import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'

import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'

export interface EditFormOwnProps {
  handleClose: (values: any) => void
  isOpen: boolean
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const EditForm = (props: EditFormOwnProps & InjectedFormProps<{ name: string }, EditFormOwnProps, string>) => {
  const { handleSubmit, submitting, error } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Организация</DialogTitle>
        <DialogContent>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Наименование</FormLabel>
                <Field
                  placeholder={'Введите наименование'}
                  name={'name'}
                  component={TextField}
                  validate={required}
                  maxLength={255}
                />
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" disabled={submitting}>
            Продолжить
          </Button>
          <Button variant="contained" type="button" color="primary" onClick={props.handleClose}>
            Назад
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const OrganizationEditForm = reduxForm<{ name: string }, EditFormOwnProps>({
  form: 'organizationEditForm',
})(EditForm)

export default OrganizationEditForm
