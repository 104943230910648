import React from 'react'
import { connect } from 'react-redux'
import { initialize, SubmissionError } from 'redux-form'
import {
  IOrganization,
  IOrganizationCreateResult,
  Organization,
  OrganizationCreateResult,
} from 'back-office/proto/models'

import { ProtoClient } from 'external/rp.ui/utils/protoClient'

import { IAppState } from '../../../reducers/rootReducer'
import OrganizationEditForm, { EditFormOwnProps } from './OrganizationEditWindow'
import OrganizationTable from './OrganizationTable'

const url = 'dictionaries/organizations'

const OrganizationDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: EditFormOwnProps = {
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('organizationEditForm', {
          id: [],
          name: '',
          addressPart: null,
        })
      )
    },
    isOpen: open,
  }

  const onFormSubmit = async (values, dispatch, props) => {
    if (props.valid) {
      const toSave: IOrganization = {
        name: values.name,
        id: values.id,
      }
      const task =
        values.id?.length > 0
          ? ProtoClient.post<IOrganizationCreateResult>(url, toSave, Organization, OrganizationCreateResult)
          : ProtoClient.put<IOrganizationCreateResult>(url, toSave, Organization, OrganizationCreateResult)

      const result = await task

      if (result.success) {
        tableRef.current && tableRef.current.onQueryChange()
        setOpen(false)
        dispatch(
          initialize('organizationEditForm', {
            id: [],
            name: '',
            description: '',
          })
        )
      } else {
        throw new SubmissionError({
          name: 'Запись с таким наименованием уже существует.',
        })
      }
    }
  }

  return (
    <>
      <OrganizationEditForm {...windowParams} onSubmit={onFormSubmit} />
      <OrganizationTable
        onEditButtonClick={(rowData) => {
          props.dispatch(initialize('organizationEditForm', rowData))
          setOpen(true)
        }}
        tableRef={tableRef}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(OrganizationDictionary)
