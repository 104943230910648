import { navigate, withPrefix } from 'gatsby'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { initialize } from 'redux-form'

import RecordActionDialog, { RecordActionParams } from 'external/rp.ui/components/Modal/RecordActionModalDialog'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { PagingAndSort } from 'shared/proto/models'

import { IUserInvite, IUserInvites, UserInvites } from '../../../proto/models'
import { IAppState } from '../../../reducers/rootReducer'
import { tableIcons } from '../../shared/tableIcons'

interface IUserInvitesDictionaryContext {
  dispatch: Dispatch
  path: string
}

class UserInvitesDictionary extends React.Component<IUserInvitesDictionaryContext> {
  constructor(props: IUserInvitesDictionaryContext) {
    super(props)

    this.tableRef = React.createRef()
  }

  private tableRef: any

  private url = 'dictionaries/user-invite'

  private navigateToEditWindow = (userInviteId?: Uint8Array) => {
    let path = withPrefix('back-office/user-invite/edit')

    if (userInviteId) {
      const userInviteIdStr = GuidHelper.toString(userInviteId)
      path = `${path}/${userInviteIdStr}`
    }

    navigate(path)
  }

  private fetchUserInvites = async (query: Query<IUserInvite>): Promise<QueryResult<IUserInvite>> => {
    const paging = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: query.orderBy?.field ?? 'email',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
    })

    const userInvites = await ProtoClient.get<IUserInvites>(this.url, UserInvites, paging)

    return {
      data: userInvites?.items ?? [],
      page: query.page,
      totalCount: userInvites?.rowCount ?? 0,
    }
  }

  private onEditButtonClick = (rowData: any): void => {
    this.props.dispatch(initialize('userInviteEditForm', rowData))
    this.navigateToEditWindow(rowData.id)
  }

  private onAddButtonClick = (): void => {
    this.props.dispatch(initialize('userInviteEditForm', { roles: [] }))
    this.navigateToEditWindow()
  }

  private onDeleteButtonClick = async (rowData: IUserInvite) => {
    await ProtoClient.delete(this.url, GuidHelper.toString(rowData.id))
    this.tableRef.current && this.tableRef.current.onQueryChange()
  }

  private ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n пользователя?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        await props.onAgreeBtnClick(rowData)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  render() {
    return (
      <MaterialTable<IUserInvite>
        tableRef={this.tableRef}
        localization={LocalizationHelper.GetLocalization()}
        icons={tableIcons}
        columns={[
          {
            title: 'Почта',
            field: 'email',
            sorting: true,
            defaultSort: 'asc',
          },
          {
            title: 'Роли',
            field: 'roles',
            sorting: true,
            render: (data, type) =>
              data.roles?.reduce((accumulator, currentValue) => `${accumulator} ${currentValue}`, ''),
          },
          {
            title: 'Организация в регионе присутствия',
            field: 'organizationRegionPresence',
            sorting: true,
            render: (data, type) => data.organizationRegionPresence?.name,
          },
          {
            title: 'Подразделение / аптека',
            field: 'subdivisionPharmacy',
            sorting: true,
            render: (data, type) => data.subdivisionPharmacy?.name,
          },
          {
            title: '',
            filtering: false,
            sorting: false,
            field: 'delete',
            render: (rowData) => (
              <this.ConfirmDeletionModalButton
                onAgreeBtnClick={() => this.onDeleteButtonClick(rowData)}
                rowData={rowData}
                tableRef={this.tableRef}
              />
            ),
          },
          {
            title: '',
            filtering: false,
            sorting: false,
            field: 'edit',
            render: (rowData) => (
              <Fab
                onClick={() => this.onEditButtonClick(rowData)}
                size="small"
                color="primary"
                aria-label="edit"
                style={{ margin: 0 }}
              >
                <EditIcon />
              </Fab>
            ),
          },
        ]}
        options={{
          sorting: true,
          emptyRowsWhenPaging: false,
          draggable: false,
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
        }}
        data={this.fetchUserInvites}
        title="Приглашения пользователей"
        components={{
          Toolbar: (toolbarProps) => (
            <Box>
              <MTableToolbar {...toolbarProps} />
              <Box ml={2}>
                <Grid container direction={'row'} alignItems={'flex-start'}>
                  <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={(e) => this.onAddButtonClick()}>
                      Добавить
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ),
        }}
      />
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(UserInvitesDictionary)
