import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { SelectField, TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  change,
  Field,
  Form,
  FormErrors,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { EOrganizationType, IRoles, ISearchDto, ISearchDtos, IUserForm, Roles, SearchDtos } from '../../../proto/models'
import { IAppState } from '../../../reducers/rootReducer'
import OrganizationSelectField from '../SelectFieldOrganizationType/OrganizationType'

export interface IUserEditWindowContext {
  dispatch: Dispatch
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  url: string
  organizationTypeValue: EOrganizationType
  organizationNetworkValue: ISearchDto
  rolesValues: string[]
}

const getRoles = async () => {
  const result = await ProtoClient.get<IRoles>(`dictionaries/users/roles`, Roles)
  return result
}

const getRolesThrottled = throttleFetch(() => getRoles())

const RoleSelect = (props: WrappedFieldProps & { seletedRoles: string[] }) => {
  const [options, setOptions] = React.useState<string[]>([])
  const [applicationRoles, setApplicationRoles] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    if (options.length === 0) {
      setLoading(true)
      getRolesThrottled().then((res) => {
        setOptions(res.roles)
        setApplicationRoles(res.applicationRoles)
        setLoading(false)
      })
    }
  })

  props.input.value = !Array.isArray(props.input.value) ? [props.input.value] : props.input.value

  const applicationRoleselected =
    props.seletedRoles.filter((selectedRole) => applicationRoles.includes(selectedRole)).length > 0

  const roleDisabled = (role: string) =>
    (applicationRoleselected && !applicationRoles.includes(role)) ||
    (props.seletedRoles.length > 0 && !applicationRoleselected && applicationRoles.includes(role))

  return (
    <SelectField {...props} multiple variant="outlined" fullwidth>
      {loading ? (
        <MenuItem>
          <CircularProgress color="inherit" size={20} />
        </MenuItem>
      ) : (
        options?.map((role, i) => (
          <MenuItem key={i} value={role} disabled={roleDisabled(role)}>
            {role}
          </MenuItem>
        ))
      )}
    </SelectField>
  )
}

const AutocompleteField = (
  props: { url: string; organizationNetworkValue?: ISearchDto; disabled: boolean } & WrappedFieldProps
) => {
  const [options, setOptions] = React.useState<ISearchDto[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const onSearchValueChange = async (searchString: string): Promise<ISearchDto[]> => {
    if (!searchString || searchString === '') {
      return []
    }

    const params = {
      searchString: searchString,
    }
    if (props.organizationNetworkValue) {
      params.id = GuidHelper.toString(props.organizationNetworkValue?.id)
    }
    const res = await ProtoClient.get<ISearchDtos>(props.url, SearchDtos, params)
    return res?.items ?? []
  }
  const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)
  const {
    input: { onChange },
  } = props
  return (
    <Autocomplete
      multiple={false}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          meta={{ ...props.meta }}
          {...params}
          fullWidth
          InputProps={{
            readOnly: props.disabled,
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const getOrganizationNetworkUrl = (type: EOrganizationType) => {
  if (
    type === EOrganizationType.OrganizationRegionPresence ||
    type === EOrganizationType.ManufacturerSubdivision ||
    type === EOrganizationType.Pharmacy
  ) {
    return 'dictionaries/user-invite/search-organization'
  }
}

const getSubdivisionPharmacyUrl = (type: EOrganizationType) => {
  if (type === EOrganizationType.ManufacturerSubdivision) {
    return 'dictionaries/user-invite/search-manufacturers-subdivision'
  }

  if (type === EOrganizationType.Pharmacy) {
    return 'dictionaries/user-invite/search-pharmacies'
  }
}

const EditForm = (props: IUserEditWindowContext & InjectedFormProps<IUserForm, IUserEditWindowContext, string>) => {
  const {
    error,
    handleSubmit,
    submitting,
    organizationTypeValue,
    organizationNetworkValue,
    dispatch,
    rolesValues,
  } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
      <DialogTitle>Пользователь</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Email</FormLabel>
              <Field
                placeholder={'Введите адрес электронной почты'}
                name={'email'}
                component={TextField}
                maxLength={255}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Роли</FormLabel>
              <Field
                name={'roles'}
                component={RoleSelect}
                validate={required}
                variant="outlined"
                seletedRoles={rolesValues}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Привязать к</FormLabel>
              <Field
                name={'organizationType'}
                component={OrganizationSelectField}
                roles={rolesValues}
                variant="outlined"
                onChange={() => {
                  dispatch(change('userEditForm', 'subdivisionPharmacy', null))
                }}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Организация в регионе присутствия</FormLabel>
              <Field
                name={'organizationNetwork'}
                component={AutocompleteField}
                url={getOrganizationNetworkUrl(organizationTypeValue)}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Подразделение / Аптека</FormLabel>
              <Field
                name={'subdivisionPharmacy'}
                component={AutocompleteField}
                url={getSubdivisionPharmacyUrl(organizationTypeValue)}
                organizationNetworkValue={organizationNetworkValue}
                disabled={organizationTypeValue == EOrganizationType.OrganizationRegionPresence}
              />
            </FormControl>
          </Box>
          <Box p={1}>{error && <strong>{error}</strong>}</Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')
const email = (value: string) => {
  const re = /[a-z0-9]*@[a-z0-9]*\.[a-z0-9]+/i
  return value.search(re) == -1 ? true : false
}

const validate = (values: IUserForm, props: IUserEditWindowContext): FormErrors<IUserForm, string> => {
  const errors: FormErrors<IUserForm, string> = {}

  if (!values.email || email(values.email)) {
    errors.email = 'Адрес неверного формата'
  }

  if (
    (values.organizationType === EOrganizationType.Pharmacy ||
      values.organizationType === EOrganizationType.ManufacturerSubdivision) &&
    !values.subdivisionPharmacy
  ) {
    errors.subdivisionPharmacy = 'Не заполнено обязательное поле'
  }

  if (values.organizationType === EOrganizationType.OrganizationRegionPresence && !values.organizationNetwork) {
    errors.organizationNetwork = 'Не заполнено обязательное поле'
  }

  if (!values.organizationType) {
    errors.organizationType = 'Не заполнено обязательное поле'
  }

  if (!values.roles || values.roles.length === 0) {
    errors.roles = 'Не заполнено обязательное поле'
  }

  return errors
}

const UserEditForm = reduxForm<IUserForm, IUserEditWindowContext>({
  form: 'userEditForm',
  validate,
})(EditForm)

const selector = formValueSelector('userEditForm')

const mapStateToProps = (store: IAppState) => {
  const { organizationType, organizationNetwork, roles } = selector(
    store,
    'organizationType',
    'organizationNetwork',
    'roles'
  )
  return {
    dispatch: store.dispatch,
    organizationTypeValue: organizationType,
    organizationNetworkValue: organizationNetwork,
    rolesValues: roles,
  }
}

export default connect(mapStateToProps, null)(UserEditForm)
