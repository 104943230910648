import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import React from 'react'

/**Компонент для текстовых полей с форматированием чисел
 * Для использования необходимо установить пакет @unicef/material-ui-currency-textfield
 */

const CurrencyNumberFormat = (props) => {
  const {
    input: { onChange, value },
    meta: { error, submitFailed, touched },
    ...other
  } = props
  return (
    <CurrencyTextField
      onChange={(event, value) => {
        onChange(value)
      }}
      value={value}
      error={touched && submitFailed && error !== undefined}
      helperText={touched && submitFailed && error}
      {...other}
      helper
    />
  )
}

export default CurrencyNumberFormat
