import { AppDispatch, IAppState } from 'back-office/reducers/rootReducer'
import { withPrefix } from 'gatsby'
import React from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'
import NavigationTab from 'shared/components/NavigationTab/NavigationTab'

import { Box, Paper, Typography } from '@material-ui/core'

interface CertificateSettingsPageProps {
  path: string
  children: React.ReactNode
}

const CertificateSettingsPage = (props: CertificateSettingsPageProps) => {
  const { children } = props

  const getDataNavigation = () => {
    return [
      {
        id: nextId(),
        name: 'Категория',
        link: withPrefix(`back-office/certificate-settings/categories`),
      },
      {
        id: nextId(),
        name: 'Продавцы',
        link: withPrefix(`back-office/certificate-settings/sellers`),
      },
      {
        id: nextId(),
        name: 'Сертификаты',
        link: withPrefix(`back-office/certificate-settings/certificate`),
      },
      {
        id: nextId(),
        name: 'Заказы',
        link: withPrefix(`back-office/certificate-settings/order`),
      },
    ]
  }

  return (
    <Box>
      <Box pl={2} pt={2} pb={1}>
        <Typography variant="h6" noWrap>
          Настройки сертификатов
        </Typography>
      </Box>
      <Box pl={2} pt={2} pb={1}>
        <Paper elevation={2}>
          <NavigationTab data={getDataNavigation()} />
        </Paper>
        {children}
      </Box>
    </Box>
  )
}

export default connect(null, null)(CertificateSettingsPage)
