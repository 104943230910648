import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook'
import {
  CertificateForm,
  CertificateResponseResult,
  CurrencyExchangeRateList,
  ECertificateStatus,
  ICertificateForm,
  ICertificateResponseResult,
  IImage,
  ProductCategory,
} from 'back-office/proto/models'
import {
  getCurrencies,
  getProductCategory,
  getSellers,
  setCertificateTeqCost,
} from 'back-office/reducers/certificate/certificateReducer'
import CurrencyNumberFormat from 'external/rp.ui/components/CustomReduxComponents/CurrencyTextField'
import { RadioGroup, SelectField } from 'external/rp.ui/components/MaterialReduxForm'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { imageToObjectURL } from 'external/rp.ui/helpers/ImageHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { navigate, withPrefix } from 'gatsby'
import React, { useEffect } from 'react'
import {
  change,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  untouch,
  Validator,
  WrappedFieldInputProps,
} from 'redux-form'
import CustomInput from 'shared/components/CustomInput'
import ImageDropzone from 'shared/components/ImageDropzone'
import Notification from 'shared/components/Notification'
import { invalidImageSizeMessage, isImageSizeValid } from 'shared/helpers/imageValidatorHepler'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { containedPrimary, messageTeqCost, teqCostInfo, teqInfo } from './CertificateEditForm.module.scss'

const renderProductCategoryField = (props: any) => {
  const { options, label, placeholder, multiple, input, meta } = props
  const { onChange, ...rest } = input
  const { touched, invalid, error } = meta

  const getSelectedOption = () => {
    if (input.value) {
      const valueNames = input.value.map((item) => item.name)

      return options.filter((prod: ProductCategory) => valueNames.includes(prod.name))
    }

    return []
  }

  return (
    <Autocomplete
      value={getSelectedOption()}
      multiple={multiple}
      options={options}
      autoHighlight
      onChange={(_, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option.name || ''}
      onBlur={() => {
        input.onBlur(input.value)
      }}
      defaultValue={props.input.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          variant="standard"
          label={label}
          placeholder={placeholder}
          value={input.value}
          error={touched & invalid}
          helperText={touched && error}
        />
      )}
    />
  )
}

const renderSellerField = (props: any) => {
  const { options, label, placeholder, multiple, input, meta } = props
  const { onChange, ...rest } = input
  const { touched, invalid, error } = meta

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      autoHighlight
      onChange={(_, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option.name || ''}
      onBlur={() => {
        input.onBlur(input.value)
      }}
      defaultValue={props.input.value}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          {...rest}
          variant="standard"
          label={label}
          placeholder={placeholder}
          error={touched & invalid}
          helperText={touched && error}
        />
      )}
    />
  )
}

const renderCurrencyField = (props: any) => {
  const { options, label, placeholder, multiple, input, meta } = props
  const { onChange, ...rest } = input
  const { touched, invalid, error } = meta

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      autoHighlight
      onChange={(_, newValue) => {
        onChange(newValue)
      }}
      getOptionLabel={(option) => option.currencyCode || ''}
      onBlur={() => {
        input.onBlur(input.value)
      }}
      defaultValue={props.input.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          variant="standard"
          label={label}
          placeholder={placeholder}
          value={input.value}
          error={touched & invalid}
          helperText={touched && error}
        />
      )}
    />
  )
}

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched & invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )
}

const renderStatusSelect = (props: any) => {
  const { status, id } = props
  const isEditCertificate = id !== null

  return (
    <SelectField {...props} variant="outlined" fullwidth>
      <MenuItem disabled={status == ECertificateStatus.Archived} value={ECertificateStatus.Draft}>
        Черновик
      </MenuItem>
      <MenuItem
        disabled={status == ECertificateStatus.Archived || (status == ECertificateStatus.Draft && !isEditCertificate)}
        value={ECertificateStatus.Published}
      >
        Опубликован
      </MenuItem>
      <MenuItem disabled={status == ECertificateStatus.Draft} value={ECertificateStatus.Archived}>
        В архив
      </MenuItem>
    </SelectField>
  )
}

const required: Validator = (value) => {
  return !value || value.toString().trim() === '' ? 'Не заполнено обязательное поле' : undefined
}

const validate = (values) => {
  const message = 'Не заполнено обязательное поле'
  const errors = {}

  if (
    values.status === ECertificateStatus.Published &&
    (!values.productCategories || values.productCategories.length === 0)
  ) {
    errors.productCategories = message
  }

  if (values.isSubscribed === 'true' && !values.subscriptionTerm) {
    errors.subscriptionTerm = message
  }

  return errors
}

const url = 'dictionaries/certificates'

const EditForm = (props: InjectedFormProps<ICertificateForm>) => {
  const { handleSubmit, submitting } = props

  const availableExtensionsImage = ['jpg', 'jpeg', 'png']

  const [imgTooLargeError, setImgTooLargeError] = React.useState(false)
  const [imgInvalidExtensionError, setImgInvalidExtensionError] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [status, setStatus] = React.useState(0)
  const [idCertificate, setIdCertificate] = React.useState(null)
  const statusRef = React.useRef(true)

  const form = useAppSelector((state) => state.form.certificateEditForm)
  const teqCost = useAppSelector((state) => state.certificate.teqCost)
  const productCategories = useAppSelector((state) => state.certificate.productCategories)
  const sellers = useAppSelector((state) => state.certificate.sellers)
  const currencies = useAppSelector((state) => state.certificate.currencies)

  if (statusRef.current) {
    setStatus(form.values?.status)

    if (form.values?.id) {
      setIdCertificate(GuidHelper.toString(form.values?.id))
    }
    statusRef.current = false
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getProductCategory())
    dispatch(getSellers())
    dispatch(getCurrencies())
  }, [])

  const onIsSubscribedChange = (isSubscribed: string) => {
    dispatch(change('certificateEditForm', 'isSubscribed', isSubscribed))
    dispatch(change('certificateEditForm', 'subscriptionTerm', null))
  }

  const onChangeCurrencyValueHandle = (value: CurrencyExchangeRateList) => {
    const denomination = form.values?.denomination

    if (denomination) {
      dispatch(setCertificateTeqCost(denomination, value.toUsdExchangeRate))
    }
  }

  const onChangeDenominationValueHandle = (value: number) => {
    const currency = form.values?.currency

    if (currency) {
      dispatch(setCertificateTeqCost(value, currency.toUsdExchangeRate))
    }
  }

  const isImageExtensionValid = (name: string): boolean => {
    if (!name) {
      return
    }

    const extension = name.split('.').pop()

    return availableExtensionsImage.includes(extension)
  }

  const validateDropzone = React.useMemo(
    () => (file: File) => {
      if (!file || file?.size === 0) {
        return
      }

      const sizeValid = isImageSizeValid(file.size)
      const extValid = isImageExtensionValid(file.name)

      if (!sizeValid) {
        setImgTooLargeError(true)
      } else if (!extValid) {
        setImgInvalidExtensionError(true)
      }

      if (!sizeValid || !extValid) {
        dispatch(change('certificateEditForm', 'image', null))
      }
    },
    []
  )

  const onDropHandler = async (image: File, input: WrappedFieldInputProps) => {
    const arrayBuffer: ArrayBuffer = await image.arrayBuffer()
    const data = new Uint8Array(arrayBuffer)
    const newImage: IImage = {
      data: data,
      name: image.name,
      size: image.size,
      type: image.type,
    }
    input.onChange(newImage)
  }

  const handleClear = () => {
    dispatch(change('certificateEditForm', 'image', null))
    dispatch(untouch('certificateEditForm', 'image'))
  }

  const onSubmit = async (values, dispatch, props) => {
    setIsModalOpen(false)

    const toSave = {
      id: values.id,
      description: values.description,
      image: values.image,
      productCategories: values.productCategories,
      seller: values.seller,
      certificateTime: values.certificateTime,
      denomination: values.denomination,
      subscriptionTerm: values.subscriptionTerm,
      isSubscribed: values.isSubscribed === 'true',
      currency: values.currency,
      status: values.status,
      teqCost: values.teqCost,
    }

    const result =
      values.id?.length > 0
        ? await ProtoClient.post<ICertificateResponseResult>(url, toSave, CertificateForm, CertificateResponseResult)
        : await ProtoClient.put<ICertificateResponseResult>(url, toSave, CertificateForm, CertificateResponseResult)

    if (result.success) {
      backToCertificateTablePage()
    } else {
      setErrorMessage('Запись с таким наименованием уже существует.')
    }
  }

  const backToCertificateTablePage = () => {
    navigate(withPrefix('back-office/certificate-settings/certificate'))
  }

  const closeNotification = () => {
    setImgTooLargeError(false)
    setImgInvalidExtensionError(false)
  }

  const invalidImageExtensionMessage = (): string => {
    const extensions = availableExtensionsImage.join(', ')
    return `Невозможно загрузить изображение, допустимы только расширения: ${extensions}`
  }

  return (
    <Box>
      <Dialog open={isModalOpen} maxWidth="xs" fullWidth={true}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>Хотите сохрнаить изменения?</DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" color="primary" disabled={submitting}>
              Сохранить
            </Button>
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={() => {
                setIsModalOpen(false)
                backToCertificateTablePage()
              }}
            >
              Отмена
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box pl={3} pr={5} pt={2}>
          <Typography variant="h6" noWrap>
            Редактирование сертификата
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <Box pt={1}>
                <Field
                  fullWidth
                  name="description"
                  component={renderTextField}
                  label="Описание"
                  multiline
                  rowsMax="4"
                  margin="normal"
                />
              </Box>
              <Box pt={1}>
                <Notification
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={imgTooLargeError || imgInvalidExtensionError}
                  autoHideDuration={6000}
                  text={
                    imgTooLargeError
                      ? invalidImageSizeMessage()
                      : imgInvalidExtensionError
                      ? invalidImageExtensionMessage()
                      : ''
                  }
                  onClose={closeNotification}
                  response="negative"
                />
                <Field
                  name={'image'}
                  component={ImageDropzone}
                  handleClear={handleClear}
                  type="file"
                  format={imageToObjectURL}
                  onDropFunc={onDropHandler}
                  validate={validateDropzone}
                  shape="square"
                  isReserveLabelError={true}
                />
              </Box>
              <Box pt={1}>
                <Field
                  name={'productCategories'}
                  component={renderProductCategoryField}
                  placeholder={'Добавить категорию'}
                  fullWidth
                  multiple
                  options={productCategories}
                  label={'Категория сертификата'}
                />
              </Box>
              <Box pt={1}>
                <Field
                  name={'seller'}
                  component={renderSellerField}
                  placeholder={'Добавить продавца'}
                  fullWidth
                  options={sellers}
                  label={'Продавец'}
                  validate={required}
                />
              </Box>
              <Box pt={3}>
                <FormLabel component="legend">Срок действия, мес</FormLabel>
                <Field
                  component={CustomInput}
                  name={'certificateTime'}
                  size="small"
                  type="number"
                  step={1}
                  placeholder={'Введите срок действия'}
                  normalize={(n) => n && +n}
                />
              </Box>
              <Box pt={3}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormLabel component="legend">Номинал</FormLabel>
                    <Field
                      placeholder={'Введите номинал'}
                      name={'denomination'}
                      component={CurrencyNumberFormat}
                      variant="outlined"
                      validate={required}
                      textAlign="left"
                      currencySymbol=""
                      decimalCharacter=","
                      digitGroupSeparator=" "
                      minimumValue="0"
                      size="small"
                      onChange={onChangeDenominationValueHandle}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name={'currency'}
                      component={renderCurrencyField}
                      placeholder={'Выбрать валюту'}
                      fullWidth
                      options={currencies}
                      label={'Валюта'}
                      validate={required}
                      onChange={onChangeCurrencyValueHandle}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormLabel component="legend">Подписка</FormLabel>
                    <FormControl fullWidth>
                      <Field component={RadioGroup} name={'isSubscribed'} id={'isSubscribed'} row>
                        <FormControlLabel
                          value={'false'}
                          label="Нет"
                          control={<Radio />}
                          onChange={() => onIsSubscribedChange('false')}
                        />
                        <FormControlLabel
                          value={'true'}
                          label="Да"
                          control={<Radio />}
                          onChange={() => onIsSubscribedChange('true')}
                        />
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormLabel component="legend">Срок подписки, мес</FormLabel>
                      <Field
                        component={CustomInput}
                        name={'subscriptionTerm'}
                        size="small"
                        type="number"
                        step={1}
                        placeholder={'Введите срок подписки'}
                        normalize={(n) => n && +n}
                        disabled={!(form.values?.isSubscribed === 'true')}
                        isReserveLabelError={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormLabel component="legend">Стоимость TeQ</FormLabel>
                      <Field
                        placeholder={'Введите стоимость TeQ'}
                        name={'teqCost'}
                        component={CurrencyNumberFormat}
                        variant="outlined"
                        validate={required}
                        textAlign="left"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator=" "
                        minimumValue="0"
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={teqInfo}>
                      <div>
                        <span className={teqCostInfo}>Рекомендуемая стоимость: {teqCost} teq</span>
                      </div>
                      <span className={messageTeqCost}>* Минимальная сумма</span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box pl={4}>
                <FormControl fullWidth>
                  <FormLabel component="legend">Статус</FormLabel>
                  <Field
                    name={'status'}
                    component={renderStatusSelect}
                    variant="outlined"
                    size="small"
                    status={status}
                    id={idCertificate}
                  />
                </FormControl>
              </Box>
              <Box pl={4} pt={6}>
                <Button
                  className={containedPrimary}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={submitting}
                >
                  Сохранить
                </Button>
              </Box>

              <Box pl={4} pt={3}>
                <Button
                  className={containedPrimary}
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={() => {
                    form.anyTouched || props.dirty ? setIsModalOpen(true) : backToCertificateTablePage()
                  }}
                >
                  Закрыть
                </Button>
              </Box>

              <Box pl={4} pt={3}>
                <span className={messageTeqCost}>{errorMessage}</span>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Box>
  )
}

const CertificateEditForm = reduxForm<ICertificateForm>({
  form: 'certificateEditForm',
  validate,
})(EditForm)

export default CertificateEditForm
