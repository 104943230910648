import { useAppDispatch } from 'back-office/components/App/hook'
import {
  ApplicationVersionStandForm,
  ApplicationVersionStandResponseResult,
  EApplicationType,
  IApplicationVersionStandForm,
  IApplicationVersionStandResponseResult,
} from 'back-office/proto/models'
import { setAppVersionStandModalOpen } from 'back-office/reducers/applicationVersionStand/applicationVersionStandReducer'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import React from 'react'
import { initialize, SubmissionError } from 'redux-form'

import ApplicationVersionStandEditForm from './AppVersionStandEditFrom/AppVersionStandEditForm'
import AppVersionStandTable from './AppVersionStandTable/AppVersionStandTable'

const url = 'back-office/app-version-stand'
const formName = 'applicationVersionStand'

const emptyInitialStateEditForm: IApplicationVersionStandForm = {
  id: null,
  type: EApplicationType.Ios,
  version: null,
  stand: null,
}
interface IAppVersionStandError {
  version: string
  stand: string
}

const AppVersionStand = () => {
  const tableRef = React.createRef<any>()
  const dispatch = useAppDispatch()

  const onDelete = async (rowData: IApplicationVersionStandForm) => {
    await ProtoClient.delete(url, GuidHelper.toString(rowData.id))
  }

  const omAdd = () => {
    dispatch(setAppVersionStandModalOpen())
    dispatch(initialize(formName, emptyInitialStateEditForm))
  }

  const handleClose = () => {
    dispatch(setAppVersionStandModalOpen())
    dispatch(initialize(formName, emptyInitialStateEditForm))
  }

  const onEdit = async (rowData: IApplicationVersionStandForm) => {
    dispatch(initialize(formName, rowData))
    dispatch(setAppVersionStandModalOpen())
  }

  const onSubmit = async (values: any, dispatch: any, props: any) => {
    const errors: IAppVersionStandError = {
      version: null,
      stand: null,
    }

    if (props.valid) {
      if (Object.keys(values).length == 0 || !values.version || values.version.trim() === '') {
        errors.version = 'Не заполнено обязательное поле'
      }

      if (Object.keys(values).length == 0 || !values.stand || values.stand.trim() === '') {
        errors.stand = 'Не заполнено обязательное поле'
      }

      if (errors.version || errors.stand) {
        throw new SubmissionError(errors)
      }

      const requestDto: IApplicationVersionStandForm = {
        id: values.id,
        type: values.type,
        version: values.version,
        stand: values.stand,
      }

      const task =
        values.id?.length > 0
          ? ProtoClient.post<IApplicationVersionStandResponseResult>(
              url,
              requestDto,
              ApplicationVersionStandForm,
              ApplicationVersionStandResponseResult
            )
          : ProtoClient.put<IApplicationVersionStandResponseResult>(
              url,
              requestDto,
              ApplicationVersionStandForm,
              ApplicationVersionStandResponseResult
            )

      const result = await task

      if (result.success) {
        tableRef.current && tableRef.current.onQueryChange()
        dispatch(setAppVersionStandModalOpen())
        dispatch(initialize(formName, emptyInitialStateEditForm))
      } else {
        throw new SubmissionError({
          stand: result.message,
        })
      }
    }
  }

  return (
    <>
      <AppVersionStandTable
        tableRef={tableRef}
        onAddButtonClick={omAdd}
        onDeleteButtonClick={onDelete}
        onEditButtonClick={onEdit}
      />
      <ApplicationVersionStandEditForm handleClose={handleClose} onSubmit={onSubmit} />
    </>
  )
}

export default AppVersionStand
