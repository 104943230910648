import { createAction, createReducer, Dispatch } from "@reduxjs/toolkit"
import { CertificateOrderInfo, ICertificateOrderInfo, IOrderInfo, OrderInfo } from "back-office/proto/models"
import { ProtoClient } from "external/rp.ui/utils/protoClient"

const url = 'dictionaries/orders'

export interface IOrderState {
    loading: boolean
    orderInfo: IOrderInfo
    certificateOrderInfo: ICertificateOrderInfo
}

const initialState: IOrderState = {
    loading: false,
    orderInfo: {},
    certificateOrderInfo: {}
}

export const sendOrder = (orderId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: ORDER_INFO_LOADING, payload: true })

    const params = {
        id: orderId,
    }

    await ProtoClient.get(url + '/send-order', null, params)

    dispatch(resetState())

    dispatch(loadOrderInfo(orderId))
}

export const rejectOrder = (orderId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: ORDER_INFO_LOADING, payload: true })

    const params = {
        id: orderId,
    }

    await ProtoClient.get(url + '/reject-order', null, params)

    dispatch(resetState())

    dispatch(loadOrderInfo(orderId))
}

const GET_CERTIFICATE_ORDER_INFO = 'GET_CERTIFICATE_ORDER_INFO'

const GET_ORDER_INFO = 'GET_ORDER_INFO'
export const loadOrderInfo = (orderId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: ORDER_INFO_LOADING, payload: true })

    const params = {
        id: orderId,
    }

    const orderInfo = await ProtoClient.get<IOrderInfo>(url + '/order-info', OrderInfo, params)

    const certificateOrderInfo = await ProtoClient.get<ICertificateOrderInfo>(url + '/certificate-order-info', CertificateOrderInfo, params)

    dispatch({ type: GET_ORDER_INFO, payload: orderInfo })

    dispatch({ type: GET_CERTIFICATE_ORDER_INFO, payload: certificateOrderInfo })

    dispatch({ type: ORDER_INFO_LOADING, payload: false })
}

const RESET_ORDER_INFO_STATE = 'RESET_ORDER_INFO_STATE'
export const resetState = createAction(RESET_ORDER_INFO_STATE)

const ORDER_INFO_LOADING = 'ORDER_INFO_LOADING'
export const setLoadingOrderInfo = createAction(ORDER_INFO_LOADING)

const orderInfoReducer = createReducer(initialState, {
    [ORDER_INFO_LOADING]: (state, action) => {
        state.loading = action.payload
    },
    [GET_ORDER_INFO]: (state, action) => {
        state.orderInfo = action.payload
    },
    [GET_CERTIFICATE_ORDER_INFO]: (state, action) => {
        state.certificateOrderInfo = action.payload
    },
    [RESET_ORDER_INFO_STATE]: (state) => {
        state.orderInfo = {}
    },
})

export default orderInfoReducer