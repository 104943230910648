import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import getAutocompleteField, {
  AutocompleteFieldProps,
} from 'back-office/components/shared/AutocompleteSearchField/AutocompleteSearchField'
import { IRegionalManagerSearchResult } from 'back-office/proto/models'

import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import { IAutocompleteSearchResult } from 'shared/proto/models'

import { AppDispatch } from '../../../../reducers/rootReducer'
import getRegionalManagerSearchField from './RegionalManagerSearchField'

const orpSearchUrl = 'dictionaries/organization-region-presences/search-organization-region-presence'
const rmSearchUrl = 'dictionaries/regional-manager-branch/search-regional-manager'

export interface IRegionalManagerBranchEditModalProps {
  dispatch?: AppDispatch
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  url: string
  organizationId: string
}

export interface IRegionalManagerBranchFormValues {
  id: Uint8Array
  contractCode: string
  regionalManager: IRegionalManagerSearchResult
  organizationRegionPresence: IAutocompleteSearchResult
}

const required = (value) => (value ? undefined : 'Не заполнено обязательное поле')

const AutocompleteField = (props: AutocompleteFieldProps) => getAutocompleteField(props)
const RegionalManagerSearchField = (props: AutocompleteFieldProps) => getRegionalManagerSearchField(props)

const EditForm = (
  props: IRegionalManagerBranchEditModalProps &
    InjectedFormProps<IRegionalManagerBranchFormValues, IRegionalManagerBranchEditModalProps, string>
) => {
  const { error, handleSubmit, submitting } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Филиал регионального менеджера</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Организация</FormLabel>
              <Field
                placeholder={'Введите регионального менеджера'}
                name={'regionalManager'}
                component={RegionalManagerSearchField}
                validate={required}
                url={rmSearchUrl}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Номер договора</FormLabel>
              <Field
                placeholder={'Введите номер договора'}
                name={'contractCode'}
                component={TextField}
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Регион присутствия</FormLabel>
              <Field
                placeholder={'Введите регион присутствия'}
                name={'organizationRegionPresence'}
                component={AutocompleteField}
                validate={required}
                url={orpSearchUrl}
                additionalOptions={{ organizationId: props.organizationId }}
              />
            </FormControl>
          </Box>
          <Box p={1}>{error && <strong>{error}</strong>}</Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const RegionalManagerBranchEditForm = reduxForm<IRegionalManagerBranchFormValues, IRegionalManagerBranchEditModalProps>(
  {
    form: 'regionalManagerBranchEditForm',
  }
)(EditForm)

export default RegionalManagerBranchEditForm
