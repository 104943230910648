import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps, Validator } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { ICategoryForm } from '../../../proto/models'

export interface ICategoryEditWindowContext {
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
}

const required: Validator = (value) => {
  return !value || value.toString().trim() === '' ? 'Не заполнено обязательное поле' : undefined
}

const EditForm = (
  props: ICategoryEditWindowContext & InjectedFormProps<ICategoryForm, ICategoryEditWindowContext, string>
) => {
  const { handleSubmit, submitting } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Бизнес категории</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Наименование</FormLabel>
                <Field
                  placeholder={'Введите наименование'}
                  name={'name'}
                  component={TextField}
                  validate={required}
                  maxLength={255}
                />
              </Box>
              <Box p={1}>
                <FormLabel component="legend">Описание категории</FormLabel>
                <Field
                  placeholder={'Введите Описание категории'}
                  name={'description'}
                  component={TextField}
                  maxLength={255}
                  multiline
                  rowsMax={4}
                />
              </Box>
            </FormControl>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

let CategoryEditForm = reduxForm<ICategoryForm, ICategoryEditWindowContext>({
  form: 'categoryEditForm',
})(EditForm)

export default CategoryEditForm
