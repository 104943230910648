import { Typography, Box, Paper, FormLabel, FormControl, Button } from '@material-ui/core'
import { Form, Field, reduxForm, submit } from 'redux-form'
import React from 'react'
import { IAppState, AppDispatch } from '../../../reducers/rootReducer'
import { connect } from 'react-redux'
import { saveCourseSettings, loadCourseSettings } from '../../../reducers/courseSettings/courseSettingsReducer'
import { ICourseSettingsForm } from '../../../proto/models'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { createNumberMask } from 'redux-form-input-masks'
import CurrencyNumberFormat from 'external/rp.ui/components/CustomReduxComponents/CurrencyTextField'

const required = (value: any) => (value ? undefined : 'Обязательно')

interface ICourseSettingsFormContext {
  dispatch: AppDispatch
  loading: boolean
  settings: ICourseSettingsForm
}

const numberMask = createNumberMask({
  allowEmpty: false,
  allowNegative: false,
  showPlusSign: false,
  spaceAfterSign: false,
  stringValue: true,
  locale: 'ru-RU',
})

const EditForm = (props: any) => {
  const { handleSubmit, submitting, loading } = props
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box>
          <Box pl={2} pt={2} pb={1}>
            <Typography variant="h6" noWrap>
              Настройки курсов
            </Typography>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Количество токенов за тест сложностью “Простой”</FormLabel>
              <Field
                placeholder={'Введите количество токенов'}
                name={'simpleCoursePoints'}
                component={CurrencyNumberFormat}
                variant="outlined"
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
                minimumValue="0"
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Количество токенов за тест сложностью “Средний”</FormLabel>
              <Field
                placeholder={'Введите количество токенов'}
                name={'middleCoursePoints'}
                component={CurrencyNumberFormat}
                variant="outlined"
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
                minimumValue="0"
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Количество токенов за тест сложностью “Сложный”</FormLabel>
              <Field
                placeholder={'Введите количество токенов'}
                name={'difficultCoursePoints'}
                component={CurrencyNumberFormat}
                variant="outlined"
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
                minimumValue="0"
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Количество токенов за тест сложностью “Бесплатный”</FormLabel>
              <Field
                placeholder={'Введите количество токенов'}
                name={'freeCoursePoints'}
                component={TextField}
                variant="outlined"
                validate={required}
                {...numberMask}
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.dispatch(submit('courseSettingsForm'))
              }}
              disabled={loading || submitting}
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Form>
    </>
  )
}

let CourseSettingsReduxForm = reduxForm({
  form: 'courseSettingsForm',
})(EditForm)

CourseSettingsReduxForm = connect((store: IAppState) => ({
  initialValues: store.courseSettings.settings,
}))(CourseSettingsReduxForm)

class CourseSettingsForm extends React.Component<ICourseSettingsFormContext> {
  componentDidMount() {
    this.props.dispatch(loadCourseSettings())
  }

  render() {
    return (
      <Paper elevation={2}>
        {this.props.loading ? (
          <></>
        ) : (
          <CourseSettingsReduxForm
            onSubmit={() => {
              this.props.dispatch(saveCourseSettings())
            }}
            loading={this.props.loading}
          />
        )}
      </Paper>
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.courseSettings.loading,
    settings: store.courseSettings.settings,
  }
}

export default connect(mapStateToProps, null)(CourseSettingsForm)
