import MaterialTable, { Query, QueryResult } from 'material-table'
import React from 'react'

import RecordActionDialog, { RecordActionParams } from 'external/rp.ui/components/Modal/RecordActionModalDialog'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { PagingAndSort } from 'shared/proto/models'

import { IUserList, IUsers, Users } from '../../../proto/models'
import { tableIcons } from '../../shared/tableIcons'

interface ITableProps {
  onEditButtonClick: (rowData: IUserList) => any
  onDeleteButtonClick: (rowData: IUserList) => any
  url: string
  tableRef: any
}

const fetchData = async (query: Query<IUserList>, url: string): Promise<QueryResult<IUserList>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'name',
    sortDirection: query.orderDirection === '' ? 'asc' : query.orderDirection,
    searchQuery: query.search,
  })

  const records = await ProtoClient.get<IUsers>(url, Users, paging)

  return {
    data: records?.items ?? [],
    page: query.page,
    totalCount: records?.rowCount ?? 0,
  }
}

export default (props: ITableProps) => {
  const [isLoading, setLoading] = React.useState(false)

  const ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n пользователя?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        setLoading(true)
        await props.onAgreeBtnClick(rowData)
        props.tableRef.current && props.tableRef.current.onQueryChange()
        setLoading(false)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  return (
    <MaterialTable<IUserList>
      title="Текущие пользователи"
      isLoading={isLoading}
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: (rowData) => (
            <Fab
              onClick={() => props.onEditButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <EditIcon />
            </Fab>
          ),
        },
        {
          title: 'Телефон',
          field: 'phoneNumber',
          sorting: true,
          render: (rowData) => `${rowData.phoneNumber}`,
        },
        { title: 'ФИО', field: 'fullName', sorting: true },
        { title: 'email', field: 'email', sorting: true },
        {
          title: 'Роли',
          field: 'roles',
          sorting: true,
          render: (data, type) =>
            data.roles?.reduce((accumulator, currentValue) => `${accumulator} ${currentValue}`, ''),
        },
        {
          title: 'Организация в регионе присутствия',
          field: 'organizationRegionPresence',
          sorting: true,
          render: (data, type) => data.organizationRegionPresence?.name,
        },
        {
          title: 'Подразделение / аптека',
          field: 'subdivisionPharmacy',
          sorting: true,
          render: (data, type) => data.subdivisionPharmacy?.name,
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: (rowData) => (
            <ConfirmDeletionModalButton
              onAgreeBtnClick={props.onDeleteButtonClick}
              rowData={rowData}
              tableRef={props.tableRef}
            />
          ),
        },
      ]}
      options={{
        sorting: true,
        search: true,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, props.url)}
    />
  )
}
