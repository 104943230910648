import React from 'react'
import ManufacturersOrganizationTable from './ManufacturerBranchTable'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import ManufacturerBranchEditModal, {
  IManufacturerBranchEditFormValues,
  IManufacturerBranchEditModalProps,
} from './ManufacturerBranchEditModal'
import { submit, initialize, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import {
  AccountOperationForm,
  IAccountOperationForm,
  EManufacturerOrganizationType,
  ManufacturerBranch,
  IManufacturerBranchResponse,
  ManufacturerBranchResponse,
  IManufacturerBranch,
} from '../../../../proto/models'
import AddFundsToAccountForm, {
  IAddFundsToAccountModalContext,
} from '../../../dictionaries/AddFundsToAccountModal/AddFundsToAccountModal'
import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import moment from 'moment'

const manufacturersOrganizationsUrl = 'dictionaries/manufacturers'

interface IManufacturerBranchDictionaryOwnProps {
  dispatch: AppDispatch
  organizationId: string
}

const ManufacturerBranchDictionary = (props: IManufacturerBranchDictionaryOwnProps) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const [rowType, setRowType] = React.useState(EManufacturerOrganizationType.Manufacturer)
  const [openAddFundsModal, setOpenAddFundsModal] = React.useState(false)

  const initialFormValues: IManufacturerBranchEditFormValues = {
    id: null,
    manufacturer: null,
    contractCode: '',
    organizationType: EManufacturerOrganizationType.Manufacturer,
    organizationRegionPresence: null,
    administrator: null,
  }

  const initialAddFundsFormValues: IAccountOperationForm = {
    id: new Uint8Array(),
  }

  const modalParams: IManufacturerBranchEditModalProps = {
    organizationId: props.organizationId,
    createButtonClickHandler: () => {
      props.dispatch(submit('manufacturersOrganizationEditorForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(initialize('manufacturersOrganizationEditorForm', initialFormValues))
    },
    isOpen: open,
    manufacturersOrganizationsUrl,
  }

  const addFundsModalParams: IAddFundsToAccountModalContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('addFundsToAccountForm'))
    },
    handleClose: () => {
      setOpenAddFundsModal(false)
      props.dispatch(initialize('addFundsToAccountForm', initialAddFundsFormValues))
    },
    isOpen: openAddFundsModal,
    dispatch: props.dispatch,
  }
  return (
    <>
      <AddFundsToAccountForm
        {...addFundsModalParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            const data = AccountOperationForm.create({
              id: values.id,
              date: DateHelper.toNumber(moment()),
              offset: DateHelper.getOffset(),
              freeTestCount: Number(values.freeTestCount),
              easyTestCount: Number(values.easyTestCount),
              mediumTestCount: Number(values.mediumTestCount),
              hardTestCount: Number(values.hardTestCount),
            })

            const task = ProtoClient.post<IAccountOperationForm>(
              manufacturersOrganizationsUrl + '/create-account-operation',
              data,
              AccountOperationForm
            )

            await task
            tableRef.current && tableRef.current.onQueryChange()
            setOpenAddFundsModal(false)
            dispatch(initialize('addFundsToAccountForm', initialAddFundsFormValues))
          }
        }}
      />
      <ManufacturerBranchEditModal
        rowType={rowType}
        {...modalParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            const data = ManufacturerBranch.create({
              id: values.id,
              manufacturerId: values.manufacturer?.manufacturerId,
              contractCode: values.contractCode,
              administratorOrganizationId: values.administrator?.manufacturerId,
              organizationType: +values.organizationType,
              organizationRegionPresenceId: values.organizationRegionPresence.id,
            })

            const task =
              values.id?.length > 0
                ? ProtoClient.post<IManufacturerBranchResponse>(
                    manufacturersOrganizationsUrl,
                    data,
                    ManufacturerBranch,
                    ManufacturerBranchResponse
                  )
                : ProtoClient.put<IManufacturerBranchResponse>(
                    manufacturersOrganizationsUrl,
                    data,
                    ManufacturerBranch,
                    ManufacturerBranchResponse
                  )

            const result = await task

            if (!result.success) {
              throw new SubmissionError({
                _error: 'Организация уже создана для производителя.',
              })
            }

            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(initialize('manufacturersOrganizationEditorForm', initialFormValues))
          }
        }}
      />
      <ManufacturersOrganizationTable
        tableRef={tableRef}
        organizationId={props.organizationId}
        onAddButtonClick={() => {
          setRowType(EManufacturerOrganizationType.Manufacturer)
          setOpen(true)
          props.dispatch(initialize('manufacturersOrganizationEditorForm', initialFormValues))
        }}
        onDeleteButtonClick={(rowData) =>
          ProtoClient.delete(manufacturersOrganizationsUrl, GuidHelper.toString(rowData.id))
        }
        onEditButtonClick={(rowData) => {
          setRowType(rowData.organizationType)

          const values: IManufacturerBranchEditFormValues = {
            contractCode: rowData.contractCode,
            id: rowData.id,
            organizationType: rowData.organizationType.toString(),
            organizationRegionPresence: {
              id: rowData.organizationRegionPresenceId,
              fullName: rowData.organizationRegionPresence,
            },
          }

          if (rowData.organizationType === EManufacturerOrganizationType.Manufacturer) {
            values.manufacturer = { manufacturerId: rowData.manufacturerId, manufacturerName: rowData.fullName }
          } else {
            values.administrator = { manufacturerId: rowData.manufacturerId, manufacturerName: rowData.fullName }
          }

          props.dispatch(initialize('manufacturersOrganizationEditorForm', values))
          setOpen(true)
        }}
        onAddFundsButtonClick={(rowData) => {
          props.dispatch(
            initialize('addFundsToAccountForm', {
              id: rowData.id,
              organizationName: rowData.fullName,
              amount: 0,
              easyTestCount: 0,
              freeTestCount: 0,
              mediumTestCount: 0,
              hardTestCount: 0,
            })
          )
          setOpenAddFundsModal(true)
        }}
        manufacturersOrganizationsUrl={manufacturersOrganizationsUrl}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(ManufacturerBranchDictionary)
