import { IProductCategory } from 'back-office/proto/models'
import { setProductCategoryModalOpen } from 'back-office/reducers/productCategory/productCategoryReducer'
import { IAppState } from 'back-office/reducers/rootReducer'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'

export interface EditFormProps {
  handleClose: (values: any) => void
  dispatch: Dispatch
  isEditWindowOpen: boolean
}

const EditForm = (props: EditFormProps & InjectedFormProps<IProductCategory, EditFormProps>) => {
  const { handleSubmit, submitting, handleClose } = props

  return (
    <Dialog
      open={props.isEditWindowOpen}
      onClose={() => {
        props.dispatch(setProductCategoryModalOpen())
      }}
    >
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Добавление категории</DialogTitle>
        <DialogContent>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Категория сертификатов</FormLabel>
                <Field
                  placeholder={'Введите наименование категории'}
                  name="name"
                  component={TextField}
                  maxLength={255}
                  style={{ width: '300px' }}
                />
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" disabled={submitting}>
            Сохранить
          </Button>
          <Button variant="contained" type="button" color="primary" onClick={handleClose}>
            Отменить
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    isEditWindowOpen: store.productCategory.isEditWindowOpen,
  }
}

const ProductCategoryEditForm = reduxForm<IProductCategory, EditFormProps>({
  form: 'productCategoryEditForm',
})(EditForm)

const connectedForm = connect(mapStateToProps, null)(ProductCategoryEditForm)

export default connectedForm
