import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { PagingAndSort } from 'shared/proto/models'

import { ActionTypeCosts, EPharmacistActionType, IActionTypeCost, IActionTypeCosts } from '../../../../proto/models'
import { tableIcons } from '../../../shared/tableIcons'
import getDisplayName from './enumDisplayName'

interface IActionTypeCostTableProps {
  onEditButtonClick: (rowData: IActionTypeCost) => any
  onDeleteButtonClick: (rowData: IActionTypeCost) => any
  onAddButtonClick: () => any
  fetchActionTypeCosts: (query: Query<IActionTypeCost>) => Promise<QueryResult<IActionTypeCost>>
  tableRef: any
}

const ActionTypeCostTable = (props: IActionTypeCostTableProps) => {
  return (
    <MaterialTable<IActionTypeCost>
      title="Настройка рейтинга"
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        {
          title: 'Тип действия',
          field: 'pharmacistActionType',
          sorting: true,
          render: (rowData) => getDisplayName(rowData.pharmacistActionType),
        },
        {
          title: 'Стоимость действия',
          field: 'amount',
          sorting: true,
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: (rowData) => (
            <Fab
              onClick={async () => {
                await props.onDeleteButtonClick(rowData)
                props.tableRef.current && props.tableRef.current.onQueryChange()
              }}
              size="small"
              color="secondary"
              aria-label="delete"
              style={{ margin: 0 }}
            >
              <DeleteIcon />
            </Fab>
          ),
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: (rowData) => (
            <Fab
              onClick={() => props.onEditButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <EditIcon />
            </Fab>
          ),
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => props.fetchActionTypeCosts(query)}
      components={{
        Toolbar: (toolbarProps) => (
          <Box>
            <MTableToolbar {...toolbarProps} />
            <Box ml={2}>
              <Grid container direction={'row'} alignItems={'flex-start'}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
                    Добавить тип действия
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ),
      }}
    />
  )
}

export default ActionTypeCostTable
