import { tableIcons } from 'back-office/components/shared/tableIcons'
import { IProvisorInvitedProxy, IProvisorInvitedProxyList, ProvisorInvitedProxyList } from 'back-office/proto/models'
import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import MaterialTable, { Query, QueryResult } from 'material-table'
import React from 'react'
import { PagingAndSort } from 'shared/proto/models'

const url = 'dictionaries/provisor-invite/get-provisor-invite-proxy-list'
interface IProvisorInviteInfoTableProps {
  tableRef: any
  inviteId: string
}

const ProvisorInviteInfoTable = (props: IProvisorInviteInfoTableProps) => {
  const [isLoading, setLoading] = React.useState(false)

  const fetchData = async (
    query: Query<IProvisorInvitedProxy>,
    url: string
  ): Promise<QueryResult<IProvisorInvitedProxy>> => {
    const paging = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: query.orderBy?.field ?? 'name',
      sortDirection: query.orderDirection || 'asc',
      searchQuery: query.search,
    })

    paging.userProvisorInviteId = props.inviteId

    const records = await ProtoClient.get<IProvisorInvitedProxyList>(url, ProvisorInvitedProxyList, paging)

    const result: QueryResult<IProvisorInvitedProxy> = {
      data: records?.items ?? [],
      page: query.page,
      totalCount: records?.rowCount ?? 0,
    }

    return result
  }

  return (
    <MaterialTable<IProvisorInvitedProxy>
      isLoading={isLoading}
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        {
          title: 'ФИО',
          field: 'fullName',
          filtering: false,
          sorting: false,
        },
        {
          title: 'Телефон',
          field: 'phoneNumber',
          filtering: false,
          sorting: false,
        },
        {
          title: 'Наименование сети',
          field: 'pharmacyName',
          filtering: false,
          sorting: false,
        },
        {
          title: 'Адрес филиала',
          field: 'addressBranch',
          filtering: false,
          sorting: false,
        },
        {
          title: 'Дата регистрации',
          filtering: false,
          sorting: false,
          render: (rowData) => DateHelper.toStringWithTime(rowData.createDate as number, 'DD.MM.YYYY'),
        },
      ]}
      options={{
        showTitle: false,
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, url)}
    />
  )
}

export default ProvisorInviteInfoTable
