import React from 'react'

import Navigation from '../Navigation'
import UserInfo from '../user/UserInfo'

const Sidebar = () => {
  return (
    <aside>
      <UserInfo />
      <Navigation />
    </aside>
  )
}

export default Sidebar
