import { useAppDispatch } from 'back-office/components/App/hook'
import { CertificateForm, ECertificateStatus, ICertificateForm, ICertificateList } from 'back-office/proto/models'
import { getBillingSetting, setCertificateTeqCost } from 'back-office/reducers/certificate/certificateReducer'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { navigate, withPrefix } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { initialize } from 'redux-form'

import CertificateTable from './CertificateTable'

const url = 'dictionaries/certificates'

const CertificateDictionary = (props: any) => {
  const [status, setStatus] = useState(ECertificateStatus.Draft)

  useEffect(() => {
    dispatch(getBillingSetting())
  }, [])

  const dispatch = useAppDispatch()

  const tableRef = React.createRef<any>()

  const openModalHandle = () => {
    const emptyInitialStateEditForm = {
      isSubscribed: 'false',
      status: ECertificateStatus.Draft,
    }

    dispatch(setCertificateTeqCost(0, 1))
    dispatch(initialize('certificateEditForm', emptyInitialStateEditForm))
    navigate(withPrefix(props.location.pathname + `/0`))
  }

  const onEditHandle = async (rowData: ICertificateList) => {
    const record = await ProtoClient.get<ICertificateForm>(url + '/edit', CertificateForm, {
      id: GuidHelper.toString(rowData.id),
    })

    const certificateRowValue = {
      id: record.id,
      description: record.description,
      image: record.image.size !== 0 ? record.image : null,
      productCategories: record.productCategories,
      seller: record.seller,
      certificateTime: record.certificateTime,
      denomination: record.denomination,
      subscriptionTerm: record.subscriptionTerm,
      isSubscribed: record.isSubscribed ? 'true' : 'false',
      currency: record.currency,
      status: record.status,
      teqCost: record.teqCost,
    }

    const denomination = record.denomination
    const currency = record.currency

    if (denomination && currency) {
      dispatch(setCertificateTeqCost(denomination, currency.toUsdExchangeRate))
    }

    setStatus(record.status)
    dispatch(initialize('certificateEditForm', certificateRowValue))
    navigate(withPrefix(props.location.pathname + `/${GuidHelper.toString(rowData.id)}`))
  }

  return <CertificateTable tableRef={tableRef} onAddButtonClick={openModalHandle} onEditButtonClick={onEditHandle} />
}

export default CertificateDictionary
