import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, Typography } from '@material-ui/core'
import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import RadioGroup from 'external/rp.ui/components/MaterialReduxForm/RadioGroup'
import React from 'react'
import { change, Field, Form, InjectedFormProps, reduxForm, submit, Validator } from 'redux-form'
import { IAlertForm } from 'shared/proto/models'

const required: Validator = (value) => {
  return !value || value.toString().trim() === '' ? 'Не заполнено обязательное поле' : undefined
}

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched & invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )
}

const Forms = (props: InjectedFormProps<IAlertForm, string>) => {
  const { handleSubmit, submitting } = props

  const loading = useAppSelector((state) => state.alert.loading)
  const dispatch = useAppDispatch()

  const onIsShowMessageChange = (isShowMessage: string) => {
    dispatch(change('alertForm', 'isShowMessage', isShowMessage))
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box>
          <Box pl={2} pt={2} pb={1}>
            <Typography variant="h6" noWrap>
              Оповещения
            </Typography>
          </Box>
          <Box p={1} pl={2}>
            <Box pb={1}>
              <FormLabel component="legend">Показывать сообщение о технических работах</FormLabel>
            </Box>
            <FormControl fullWidth>
              <Field component={RadioGroup} name={'isShowMessage'} id={'isShowMessage'} row>
                <FormControlLabel
                  value={'false'}
                  label="Нет"
                  control={<Radio />}
                  onChange={() => onIsShowMessageChange('false')}
                />
                <FormControlLabel
                  value={'true'}
                  label="Да"
                  control={<Radio />}
                  onChange={() => onIsShowMessageChange('true')}
                />
              </Field>
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">
                Текст сообщения
              </FormLabel>
              <Field
                name="message"
                component={renderTextField}
                label="Текст сообщения"
                multiline
                rowsMax="4"
                margin="normal"
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(submit('alertForm'))
              }}
              disabled={loading || submitting}
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Form>
    </>
  )
}

let AlertsForm = reduxForm<IAlertForm>({
  form: 'alertForm',
})(Forms)

export default AlertsForm