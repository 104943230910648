import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook'
import { ISeller } from 'back-office/proto/models'
import { setSellerModalOpen } from 'back-office/reducers/seller/sellerReducer'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'

export interface ISellerEditFormOwnProps {
  handleClose: (values: any) => void
}

const EditForm = (props: ISellerEditFormOwnProps & InjectedFormProps<ISeller, ISellerEditFormOwnProps>) => {
  const isEditModalOpen = useAppSelector((state) => state.seller.isEditWindowOpen)
  const dispatch = useAppDispatch()

  const { handleSubmit, submitting, handleClose } = props

  return (
    <Dialog
      open={isEditModalOpen}
      onClose={() => {
        dispatch(setSellerModalOpen())
      }}
    >
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Добавление продавца</DialogTitle>
        <DialogContent>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Наименование</FormLabel>
                <Field
                  placeholder={'Введите наименование продавца'}
                  name="name"
                  component={TextField}
                  maxLength={255}
                  style={{ width: '300px' }}
                />
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" disabled={submitting}>
            Сохранить
          </Button>
          <Button variant="contained" type="button" color="primary" onClick={handleClose}>
            Отменить
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const SellerEditForm = reduxForm<ISeller, ISellerEditFormOwnProps>({
  form: 'sellerEditForm',
})(EditForm)

export default SellerEditForm
