import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { IRegionalManagerBranch, IRegionalManagerBranches, RegionalManagerBranches } from 'back-office/proto/models'

import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import EditIcon from '@material-ui/icons/Edit'
import { PagingAndSort } from 'shared/proto/models'

import { tableIcons } from '../../../shared/tableIcons'

interface IRegionalManagerBranchTableProps {
  onEditButtonClick: (rowData: IRegionalManagerBranch) => any
  onAddFundsButtonClick: (rowData: IRegionalManagerBranch) => any
  onDeleteButtonClick: (rowData: IRegionalManagerBranch) => any
  url: string
  onAddButtonClick: () => void
  tableRef: any
  organizationId: string
}

const fetchData = async (
  query: Query<IRegionalManagerBranch>,
  url: string,
  organizationId: string
): Promise<QueryResult<IRegionalManagerBranch>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'contractCode',
    sortDirection: query.orderDirection,
    searchQuery: query.search,
    organizationId: organizationId,
  })

  const branches = await ProtoClient.get<IRegionalManagerBranches>(url, RegionalManagerBranches, paging)

  return {
    data: branches?.items ?? [],
    page: query.page,
    totalCount: branches?.rowCount ?? 0,
  }
}

const Table = (props: IRegionalManagerBranchTableProps): React.ReactElement<IRegionalManagerBranchTableProps> => {
  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <MTableToolbar {...toolbarProps} />
      <Box ml={2}>
        <Grid container direction={'row'} alignItems={'flex-start'}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  return (
    <MaterialTable<IRegionalManagerBranch>
      tableRef={props.tableRef}
      localization={{
        toolbar: {
          searchTooltip: 'Поиск',
          searchPlaceholder: 'Поиск',
        },
        pagination: {
          labelRowsSelect: 'строк',
        },
      }}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          // eslint-disable-next-line react/display-name
          render: (rowData) => (
            <Fab
              onClick={() => props.onEditButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <EditIcon />
            </Fab>
          ),
        },
        { title: 'Наименование', field: 'name', sorting: true },
        { title: 'Краткое наименование', field: 'shortName', sorting: true },
        {
          title: 'Номер договора',
          field: 'contractCode',
          sorting: true,
          defaultSort: 'asc',
        },
        { title: 'Регион присутствия', field: 'oragnizationRegionPresence', sorting: false },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          // eslint-disable-next-line react/display-name
          render: (rowData) => (
            <Fab
              onClick={() => props.onAddFundsButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <AccountBalanceWallet />
            </Fab>
          ),
        },
      ]}
      options={{
        sorting: true,
        search: true,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, props.url, props.organizationId)}
      title="Филиал регионального менеджера"
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default Table
