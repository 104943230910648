import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps, formValueSelector, FormErrors } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  Grid,
} from '@material-ui/core'
import { IAccountOperationForm } from '../../../proto/models'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { connect } from 'react-redux'
import { createNumberMask } from 'redux-form-input-masks'
import { Dispatch } from 'redux'

export interface IAddFundsToAccountModalContext {
  handleClose: () => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  dispatch: Dispatch
}

const numberMask = createNumberMask({
  allowEmpty: false,
  allowNegative: true,
  showPlusSign: false,
  spaceAfterSign: false,
  stringValue: true,
  locale: 'ru-RU',
})

const validate = (values: IAccountOperationForm) => {
  const errors: FormErrors<IAccountOperationForm> = {}

  if (Number(values.freeTestCount) < 0) {
    errors.freeTestCount = 'Количество тестов организации не может быть меньше 0'
  }

  if (Number(values.easyTestCount) < 0) {
    errors.easyTestCount = 'Количество тестов организации не может быть меньше 0'
  }

  if (Number(values.mediumTestCount) < 0) {
    errors.mediumTestCount = 'Количество тестов организации не может быть меньше 0'
  }

  if (Number(values.hardTestCount) < 0) {
    errors.hardTestCount = 'Количество тестов организации не может быть меньше 0'
  }

  return errors
}

const EditForm = (
  props: IAddFundsToAccountModalContext &
    InjectedFormProps<IAccountOperationForm, IAddFundsToAccountModalContext, string>
) => {
  const { handleSubmit, submitting } = props

  const onClose = () => {
    props.handleClose()
  }

  const onCreate = (params) => {
    props.createButtonClickHandler(params)
  }

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
      <DialogTitle>Пополнение баланса</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <Field label="Организация" variant="outlined" name={'organizationName'} component={TextField} />
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel>Количество тестов</FormLabel>
            </FormControl>
          </Box>
          <Box p={1}>
            <Grid container direction={'row'} alignItems={'flex-start'} spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel component="legend">Бесплатные</FormLabel>
                  <Field
                    variant="outlined"
                    placeholder={'Введите число'}
                    name={'freeTestCount'}
                    component={TextField}
                    {...numberMask}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel component="legend">Простые</FormLabel>
                  <Field
                    variant="outlined"
                    placeholder={'Введите число'}
                    name={'easyTestCount'}
                    component={TextField}
                    {...numberMask}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel component="legend">Средние</FormLabel>
                  <Field
                    variant="outlined"
                    placeholder={'Введите число'}
                    name={'mediumTestCount'}
                    component={TextField}
                    {...numberMask}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel component="legend">Сложные</FormLabel>
                  <Field
                    variant="outlined"
                    placeholder={'Введите число'}
                    name={'hardTestCount'}
                    component={TextField}
                    {...numberMask}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCreate} disabled={submitting}>
          Пополнить
        </Button>

        <Button variant="contained" color="primary" onClick={onClose}>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddFundsToAccountReduxForm = reduxForm<IAccountOperationForm, IAddFundsToAccountModalContext>({
  form: 'addFundsToAccountForm',
  validate,
})(EditForm)

const selector = formValueSelector('addFundsToAccountForm')

const AddFundsToAccountForm = connect((state) => {
  const freeTestCount = selector(state, 'freeTestCount')
  const easyTestCount = selector(state, 'easyTestCount')
  const mediumTestCount = selector(state, 'mediumTestCount')
  const hardTestCount = selector(state, 'hardTestCount')

  return {
    freeTestCount,
    easyTestCount,
    mediumTestCount,
    hardTestCount,
  }
})(AddFundsToAccountReduxForm)

export default AddFundsToAccountForm
