import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { IOrganization, IOrganizations, Organizations } from 'back-office/proto/models'

import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { PagingAndSort } from 'shared/proto/models'

import { tableIcons } from '../../shared/tableIcons'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { Link, withPrefix } from 'gatsby'

interface ITableProps {
  onEditButtonClick: (rowData: IOrganization) => void
  onAddButtonClick: () => void
  url: string
  tableRef: any
}

const fetchData = async (query: Query<IOrganization>, url: string): Promise<QueryResult<IOrganization>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'name',
    sortDirection: query.orderDirection || 'asc',
    searchQuery: query.search,
  })

  const records = await ProtoClient.get<IOrganizations>(url, Organizations, paging)

  const result: QueryResult<IOrganization> = {
    data: records?.items ?? [],
    page: query.page,
    totalCount: records?.rowCount ?? 0,
  }

  return result
}

const Table = (props: ITableProps) => {
  const editColumnRenderer = (rowData: IOrganization): JSX.Element => (
    <Fab
      onClick={() => props.onEditButtonClick(rowData)}
      size="small"
      color="primary"
      aria-label="edit"
      style={{ margin: 0 }}
    >
      <EditIcon />
    </Fab>
  )

  const viewColumnRenderer = (rowData: IOrganization): JSX.Element => (
    <Link to={withPrefix(`back-office/organizations/${GuidHelper.toString(rowData.id)}/regions-of-presence`)}>
      <Fab size="small" color="primary" aria-label="edit" style={{ margin: 0 }}>
        <VisibilityIcon />
      </Fab>
    </Link>
  )

  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <MTableToolbar {...toolbarProps} />
      <Box ml={2}>
        <Grid container direction={'row'} alignItems={'flex-start'}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  return (
    <MaterialTable<IOrganization>
      title="Организации"
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: editColumnRenderer,
        },
        { title: 'Наименование', field: 'name', sorting: true },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'view',
          render: viewColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, props.url)}
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default Table
