import { EPharmacistActionType } from '../../../../proto/models'

const getDisplayName = (value: EPharmacistActionType): string => {
  switch (value) {
    case EPharmacistActionType.CompletedFreeCourseFirstTry:
      return 'Сдан бесплатный курс с 1 попытки'
    case EPharmacistActionType.CompletedFreeCourseSecondTry:
      return 'Сдан бесплатный курс со 2 попытки'
    case EPharmacistActionType.CompletedFreeCourseThirdTry:
      return 'Сдан бесплатный курс с 3 попытки'
    case EPharmacistActionType.FreeCourseNotPassed:
      return 'Не сдан бесплатный курс'
    case EPharmacistActionType.CompletedEasyCourseFirstTry:
      return 'Сдан простой курс с 1 попытки'
    case EPharmacistActionType.CompletedEasyCourseSecondTry:
      return 'Сдан простой курс со 2 попытки'
    case EPharmacistActionType.CompletedEasyCourseThirdTry:
      return 'Сдан простой курс с 3 попытки'
    case EPharmacistActionType.EasyCourseNotPassed:
      return 'Не сдан простой курс'
    case EPharmacistActionType.PassedMediumCourseFirstTry:
      return 'Сдан средний курс с 1 попытки'
    case EPharmacistActionType.CompletedMediumCourseSecondTry:
      return 'Сдан средний курс со 2 попытки'
    case EPharmacistActionType.CompletedMediumCourseThirdTry:
      return 'Сдан средний курс с 3 попытки'
    case EPharmacistActionType.MediumCourseNotPassed:
      return 'Не сдан средний курс'
    case EPharmacistActionType.PassedHardCourseFirstTry:
      return 'Сдан сложный курс с 1 попытки'
    case EPharmacistActionType.CompletedHardCourseSecondTry:
      return 'Сдан сложный курс со 2 попытки'
    case EPharmacistActionType.CompletedHardCourseThirdTry:
      return 'Сдан сложный курс с 3 попытки'
    case EPharmacistActionType.HardCourseNotPassed:
      return 'Не сдан сложный курс'
    case EPharmacistActionType.Feedback:
      return 'Отзыв и оценка'
    case EPharmacistActionType.Grade:
      return 'Оценка'

    default:
      return ''
  }
}

export default getDisplayName
