import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createReducer, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { Organization, IOrganization } from '../../proto/models'

const url = 'dictionaries/organizations'
export interface IOrganizationState {
  orgnization: IOrganization
  loading: boolean
}

const initialState: IOrganizationState = {
  orgnization: null,
  loading: true,
}

const FETCH = 'ORGANIZATION_FETCH'
const CLEAR = 'ORGANIZATION_CLEAR'

export const fetchOrganization = createAsyncThunk<IOrganization, string>(FETCH, async (organizationId) => {
  const response = await ProtoClient.get<IOrganization>(url + '/edit', Organization, { organizationId })

  return response
})

export const clearOrganization = createAction(CLEAR)

const organizationReducer = createReducer(initialState, {
  [fetchOrganization.pending.toString()]: (state) => {
    state.loading = true
  },
  [fetchOrganization.rejected.toString()]: (state) => {
    state.loading = false
  },
  [fetchOrganization.fulfilled.toString()]: (state, action: { payload: IOrganization }) => {
    state.orgnization = action.payload
    state.loading = false
  },
  [CLEAR]: (state) => {
    state.loading = true
    state.orgnization = null
  },
})

export default organizationReducer
