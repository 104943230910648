import React from 'react'
import { connect } from 'react-redux'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import { load, save } from 'back-office/reducers/administratorOrganizations/administratorOrganizationReducer'

import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import { Box, Button, FormControl, FormLabel, Paper, Typography } from '@material-ui/core'

import { IAdministratorOrganization } from '../../../proto/models'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import Loader from 'react-loader-spinner'

const required = (value: any) => (value ? undefined : 'Обязательно')

interface IEditFormProps {
  dispatch: AppDispatch
  loading: boolean
  organization: IAdministratorOrganization
}

const EditForm = (props: InjectedFormProps<IAdministratorOrganization, {}>) => {
  const { handleSubmit, submitting, pristine } = props
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box>
          <Box pl={2} pt={2} pb={1}>
            <Typography variant="h6" noWrap>
              Организация администратора
            </Typography>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Наименование</FormLabel>
              <Field
                placeholder={'Введите наименование'}
                name={'name'}
                component={TextField}
                variant="outlined"
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Краткое наименование</FormLabel>
              <Field
                placeholder={'Введите краткое наименование'}
                name={'shortName'}
                component={TextField}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
              Сохранить
            </Button>
          </Box>
        </Box>
      </Form>
    </>
  )
}

const throttledSave = throttleFetch((organization: IAdministratorOrganization, dispatch: AppDispatch) =>
  dispatch(save(organization))
)

let AdministratorOrganizationReduxForm = reduxForm<IAdministratorOrganization, {}>({
  form: 'administratorOrganizationForm',
  onSubmit: (values, dispatch, props) => {
    throttledSave(values, dispatch)
  },
})(EditForm)

const ConnectedAdministratorOrganizationReduxForm = connect((store: IAppState) => ({
  initialValues: store.administratorOrganization.organization,
}))(AdministratorOrganizationReduxForm)

class AdministratorOrganizationForm extends React.Component<IEditFormProps> {
  componentDidMount() {
    this.props.dispatch(load())
  }

  render() {
    return (
      <Paper elevation={2}>
        {this.props.loading ? (
          <Loader type="TailSpin" color="#3f51b5" height={284.5} />
        ) : (
          <ConnectedAdministratorOrganizationReduxForm />
        )}
      </Paper>
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.administratorOrganization.loading,
    organization: store.administratorOrganization.organization,
  }
}

export default connect(mapStateToProps, null)(AdministratorOrganizationForm)
