import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook'
import { setAppVersionStandModalOpen } from 'back-office/reducers/applicationVersionStand/applicationVersionStandReducer'
import React from 'react'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

import { EApplicationType, IApplicationVersionStandForm } from '../../../../proto/models'
import { SelectField, TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { field } from './AppVersionStandEditForm.module.scss'

export interface EditFormProps {
  handleClose: (values: any) => void
}

const renderStatusSelect = (props: any) => {
  return (
    <SelectField {...props} variant="outlined" fullwidth>
      <MenuItem value={EApplicationType.Ios}>IOS</MenuItem>
      <MenuItem value={EApplicationType.Android}>Android</MenuItem>
    </SelectField>
  )
}

const EditForm = (props: EditFormProps & InjectedFormProps<IApplicationVersionStandForm, EditFormProps>) => {
  const dispatch = useAppDispatch()
  const isEditWindowOpen = useAppSelector((state) => state.appVersionStand.isEditWindowOpen)

  const { handleSubmit, submitting, handleClose } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={isEditWindowOpen}
      onClose={() => {
        dispatch(setAppVersionStandModalOpen())
      }}
    >
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Редактирование стенда по умолчанию</DialogTitle>
        <DialogContent>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Тип приложения</FormLabel>
                <Field className={field} name={'type'} component={renderStatusSelect} size="small" />
              </Box>
              <Box p={1}>
                <FormLabel component="legend">Версия приложения</FormLabel>
                <Field
                  className={field}
                  placeholder={'Введите версию приложения'}
                  name={'version'}
                  component={TextField}
                  maxLength={255}
                />
              </Box>
              <Box p={1}>
                <FormLabel component="legend">Стенд по умолчанию</FormLabel>
                <Field
                  className={field}
                  placeholder={'Введите ссылку на стенд'}
                  name={'stand'}
                  component={TextField}
                  maxLength={255}
                />
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" disabled={submitting}>
            Сохранить
          </Button>
          <Button variant="contained" type="button" color="primary" onClick={handleClose}>
            Отменить
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const ApplicationVersionStandEditForm = reduxForm<IApplicationVersionStandForm, EditFormProps>({
  form: 'applicationVersionStand',
})(EditForm)

export default ApplicationVersionStandEditForm
