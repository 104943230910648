import React from 'react'
import { connect } from 'react-redux'
import { initialize, SubmissionError, submit } from 'redux-form'

import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import Notification from 'shared/components/Notification'

import {
  IRegionalManagerCreateResult,
  IRegionalManagerDeleteResult,
  RegionalManager,
  RegionalManagerCreateResult,
  RegionalManagerDeleteResult,
} from '../../../proto/models'
import { IAppState } from '../../../reducers/rootReducer'
import RegionalManagerEditForm, { RegionalManagerOwnProps } from './RegionalManagerEditWindow'
import RegionalManagerTable from './RegionalManagerTable'

const url = 'dictionaries/regional-manager'

const RegionalManagerDictionary = (props: any) => {
  const [notificationMessage, setNotificationMessage] = React.useState<string>(null)

  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: RegionalManagerOwnProps = {
    createButtonClickHandler: () => {
      props.dispatch(submit('regionalManagerEditForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('regionalManagerEditForm', {
          id: [],
          name: '',
          description: '',
        })
      )
    },
    isOpen: open,
  }

  const onDelete = async (rowData) => {
    const result = await ProtoClient.delete<IRegionalManagerDeleteResult>(
      url,
      GuidHelper.toString(rowData.id),
      RegionalManagerDeleteResult
    )
    if (!result.success) {
      setNotificationMessage(result.message)
    }
  }

  return (
    <>
      <Notification
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notificationMessage != null}
        autoHideDuration={6000}
        text={notificationMessage}
        onClose={() => setNotificationMessage(null)}
        response="negative"
      />
      <RegionalManagerEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            const task =
              values.id?.length > 0
                ? ProtoClient.post<IRegionalManagerCreateResult>(
                    url,
                    values,
                    RegionalManager,
                    RegionalManagerCreateResult
                  )
                : ProtoClient.put<IRegionalManagerCreateResult>(
                    url,
                    values,
                    RegionalManager,
                    RegionalManagerCreateResult
                  )

            const result = await task

            if (result.success) {
              tableRef.current && tableRef.current.onQueryChange()
              setOpen(false)
              dispatch(
                initialize('regionalManagerEditForm', {
                  id: [],
                  name: '',
                  description: '',
                })
              )
            } else {
              throw new SubmissionError({
                name: 'Запись с таким наименованием уже существует.',
              })
            }
          }
        }}
      />
      <RegionalManagerTable
        tableRef={tableRef}
        onDeleteButtonClick={onDelete}
        onEditButtonClick={(rowData) => {
          props.dispatch(initialize('regionalManagerEditForm', rowData))
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(RegionalManagerDictionary)
