import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps, WrappedFieldProps } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
} from '@material-ui/core'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { ISearchDto, SearchDto } from '../../../proto/models'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { navigate, withPrefix } from 'gatsby'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'

export interface IProvisorInviteEditWindowContext {
  id?: string
  path: string
}

export interface IProvisorInviteFrom {
  provisor: ISearchDto
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const onSearchValueChange = async (
  searchString: string,
  url: string,
  setOpen: (v: boolean) => void,
  setOptions: (v: ISearchDto[]) => void,
  setLoading: (v: boolean) => void
): Promise<void> => {
  if (!searchString || searchString === '') {
    setOpen(false)
  }

  const params = {
    email: searchString,
  }
  const res = await ProtoClient.get<ISearchDto>(url, SearchDto, params)
  if (res && res.name) {
    setOptions([res])
    setLoading(false)
    setOpen(true)
  } else {
    setLoading(false)
    setOpen(false)
    setOptions([])
  }
}
const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 2000)

const AutocompleteField = (props: { url: string } & WrappedFieldProps) => {
  const [options, setOptions] = React.useState<ISearchDto[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props
  return (
    <Autocomplete
      multiple={false}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, props.url, setOpen, setOptions, setLoading)
        }
        if (reason === 'clear') {
          setOptions([])
          setOpen(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          meta={{ ...props.meta }}
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const EditForm = (
  props: IProvisorInviteEditWindowContext &
    InjectedFormProps<IProvisorInviteFrom, IProvisorInviteEditWindowContext, string>
) => {
  const { error, handleSubmit, submitting, pristine, reset } = props

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Пользователь</DialogTitle>
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Почта</FormLabel>
              <Field
                name={'provisor'}
                component={AutocompleteField}
                url={'dictionaries/provisor-invite/search-medical-representative'}
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1}>{error && <strong>{error}</strong>}</Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
            Продолжить
          </Button>

          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              reset()
              navigate(withPrefix('back-office/provisor-invite'))
            }}
          >
            Назад
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const addProvisorInvite = async (newProvisorInvite: IProvisorInviteFrom) => {
  await ProtoClient.put<ISearchDto>(
    'dictionaries/provisor-invite',
    SearchDto.create(newProvisorInvite.provisor),
    SearchDto,
    SearchDto
  )
}

export default reduxForm<IProvisorInviteFrom, IProvisorInviteEditWindowContext>({
  form: 'provisorInviteEditForm',
  onSubmit: async (values, dispatch, props) => {
    await addProvisorInvite(values)
    navigate(withPrefix('back-office/provisor-invite'))
  },
})(EditForm)
