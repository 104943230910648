import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'

import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import EditIcon from '@material-ui/icons/Edit'
import { IPagingAndSort } from 'shared/proto/models'

import { tableIcons } from '../../../shared/tableIcons'
import { IManufacturerBranches, IManufacturerBranchList, ManufacturerBranches } from 'back-office/proto/models'

interface IManufacturerBranchTableProps {
  onEditButtonClick: (rowData: IManufacturerBranchList) => any
  onAddFundsButtonClick: (rowData: IManufacturerBranchList) => any
  onDeleteButtonClick: (rowData: IManufacturerBranchList) => any
  manufacturersOrganizationsUrl: string
  onAddButtonClick: () => void
  tableRef: any
  organizationId: string
}

type ManBranchPagingAndSort = IPagingAndSort & { organizationId: string }

const fetchConnectedSalePoints = async (
  query: Query<IManufacturerBranchList>,
  manufacturersOrganizationsUrl: string,
  organizationId: string
): Promise<QueryResult<IManufacturerBranchList>> => {
  const paging: ManBranchPagingAndSort = {
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'contractCode',
    sortDirection: query.orderDirection,
    searchQuery: query.search,
    organizationId: organizationId,
  }

  const manufacturersOrganizations = await ProtoClient.get<IManufacturerBranches>(
    manufacturersOrganizationsUrl,
    ManufacturerBranches,
    paging
  )

  return {
    data: manufacturersOrganizations?.items ?? [],
    page: query.page,
    totalCount: manufacturersOrganizations?.rowCount ?? 0,
  }
}

const Table = (props: IManufacturerBranchTableProps): React.ReactElement<IManufacturerBranchTableProps> => {
  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <MTableToolbar {...toolbarProps} />
      <Box ml={2}>
        <Grid container direction={'row'} alignItems={'flex-start'}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  return (
    <MaterialTable<IManufacturerBranchList>
      tableRef={props.tableRef}
      localization={{
        toolbar: {
          searchTooltip: 'Поиск',
          searchPlaceholder: 'Поиск',
        },
        pagination: {
          labelRowsSelect: 'строк',
        },
      }}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          // eslint-disable-next-line react/display-name
          render: (rowData) => (
            <Fab
              onClick={() => props.onEditButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <EditIcon />
            </Fab>
          ),
        },
        { title: 'Наименование', field: 'fullName', sorting: true },
        { title: 'Краткое наименование', field: 'shortName', sorting: true },
        {
          title: 'Номер договора',
          field: 'contractCode',
          sorting: true,
          defaultSort: 'asc',
        },
        { title: 'Регион присутствия', field: 'organizationRegionPresence', sorting: false },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          // eslint-disable-next-line react/display-name
          render: (rowData) => (
            <Fab
              onClick={() => props.onAddFundsButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <AccountBalanceWallet />
            </Fab>
          ),
        },
      ]}
      options={{
        sorting: true,
        search: true,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchConnectedSalePoints(query, props.manufacturersOrganizationsUrl, props.organizationId)}
      title="Производитель"
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default Table
