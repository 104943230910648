import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
} from '@material-ui/core'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import {
  ICurrencyExchangeRateForm,
  ICurrencySearch,
  ICurrencySearches,
  CurrencySearches,
} from '../../../../proto/models'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import CurrencyNumberFormat from 'external/rp.ui/components/CustomReduxComponents/CurrencyTextField'

export interface ICurrencyExchangeRateEditWindowContext {
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  url: string
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const onSearchValueChange = async (searchString: string, url: string): Promise<ICurrencySearch[]> => {
  if (!searchString || searchString === '') {
    return []
  }
  const res = await ProtoClient.get<ICurrencySearches>(url + '/search', CurrencySearches, {
    searchString: searchString,
  })
  return res?.currencies ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const AutocompleteField = (props: any) => {
  const [options, setOptions] = React.useState<ICurrencySearch[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, props.url).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.fullName === value.fullName}
      options={options}
      renderOption={(option) => option.fullName}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.fullName ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          placeholder={'Выберите валюту'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const EditForm = (
  props: ICurrencyExchangeRateEditWindowContext &
    InjectedFormProps<ICurrencyExchangeRateForm, ICurrencyExchangeRateEditWindowContext, string>
) => {
  const { handleSubmit, submitting } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Валюта</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Наименование валюты</FormLabel>
              <Field name={'currency'} component={AutocompleteField} validate={required} url={props.url} />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Курс к доллару</FormLabel>
              <Field
                placeholder={'Введите значение курса к доллару'}
                name={'toUsdExchangeRate'}
                component={CurrencyNumberFormat}
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
                minimumValue="0"
              />
            </FormControl>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

let CurrencyExchangeRateEditForm = reduxForm<ICurrencyExchangeRateForm, ICurrencyExchangeRateEditWindowContext>({
  form: 'currencyExchangeRateForm',
})(EditForm)

export default CurrencyExchangeRateEditForm
