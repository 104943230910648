import { Box } from '@material-ui/core'
import React from 'react'
import BillingSettingsForm from './BillingSettingsForm'
import CurrencyExchangeRateDictionary from './CurrencyExchangeRate/CurrencyExchangeRateDictionary'

export default (props: any) => (
  <>
    <Box>
      <Box p={1}>
        <BillingSettingsForm />
      </Box>
      <Box p={1}>
        <CurrencyExchangeRateDictionary />
      </Box>
    </Box>
  </>
)
