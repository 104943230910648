import {
  IProvisorInviteInfoState,
  loadProvisorInviteInfo,
} from 'back-office/reducers/provisorInviteInfoReducer/provisorInviteInfoReducer'
import { AppDispatch, IAppState } from 'back-office/reducers/rootReducer'
import { navigate, withPrefix } from 'gatsby'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { Box, Button, Grid, Typography } from '@material-ui/core'

import { header, headerText } from './ProvisorInviteInfo.module.scss'
import ProvisorInviteInfoTable from './ProvisorInviteInfoTable'

interface IProvisorInviteInfo {
  dispatch: AppDispatch
  loading: boolean
  provisorInviteInfo: IProvisorInviteInfoState
  inviteId: string
  url: string
}

const ProvisorInviteInfo = (props: IProvisorInviteInfo) => {
  const tableRef = React.createRef<any>()

  useEffect(() => {
    props.dispatch(loadProvisorInviteInfo(props.inviteId))
  }, [])

  return (
    <div>
      {props.loading ? (
        <></>
      ) : (
        <>
          <Box pl={2} pt={2} pb={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(withPrefix('back-office/provisor-invite'))
              }}
            >
              Вернуться
            </Button>
          </Box>
          <Box pl={2} pt={2} pb={1}>
            <Typography variant="h6" noWrap>
              Приглашенные провизоры
            </Typography>
          </Box>
          <Box pl={2} pt={2} pb={1}>
            <div className={header}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box pl={2}>
                    <span>ФИО</span>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box pl={2}>
                    <span>Пригласительный код</span>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box pl={2}>
                    <span className={headerText}>{props.provisorInviteInfo.fullName}</span>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box pl={2}>
                    <span className={headerText}>{props.provisorInviteInfo.inviteCode}</span>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box pl={2} pt={2} pb={1}>
            <ProvisorInviteInfoTable tableRef={tableRef} inviteId={props.inviteId} />
          </Box>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.provisorInviteInfo.loading,
    provisorInviteInfo: store.provisorInviteInfo.provisorInviteInfo,
  }
}

export default connect(mapStateToProps, null)(ProvisorInviteInfo)
