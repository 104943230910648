import { Box } from '@material-ui/core'
import { resetSmsCodeState } from 'back-office/reducers/smsCode/smsCodeReducer'
import React, { useEffect } from 'react'
import { resetAlertState } from 'shared/reducers/alert/alertReducer'
import { useAppDispatch } from '../App/hook'
import Alerts from './Alerts/Alerts'
import AppVersionStand from './AppVersionStand/AppVersionStand'
import SmsCode from './SmsCode/SmsCode'

const SystemSettings = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetAlertState())
      dispatch(resetSmsCodeState())
    }
  })

  return (
    <>
      <Alerts />
      <SmsCode />
      <Box pt={4}>
        <AppVersionStand />
      </Box>
    </>
  )
}

export default SystemSettings
