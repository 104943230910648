import React from 'react'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { submit, initialize, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { IAppState } from '../../../reducers/rootReducer'
import UserEditForm, { IUserEditWindowContext } from './UserEditWindow'
import UserTable from './UserTable'
import { UserForm, IUserForm, IUserResponse, UserResponse, EOrganizationType } from '../../../proto/models'

const url = 'dictionaries/users'

const editFormInitialValues = {
  id: [],
  blocked: false,
  email: '',
  roles: [],
  organizationType: EOrganizationType.ManufacturerOrganization,
  organizationNetwork: null,
  subdivisionPharmacy: null,
}

const UserDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: IUserEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('userEditForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(initialize('userEditForm', editFormInitialValues))
    },
    isOpen: open,
    url: url,
  }

  return (
    <>
      <UserEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            let result = null

            result = await ProtoClient.post<IUserResponse>(url, values, UserForm, UserResponse)

            if (!result.success) {
              throw new SubmissionError({
                _error: result.message,
              })
            }

            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(initialize('userEditForm', editFormInitialValues))
          }
        }}
      />
      <UserTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize('userEditForm', {
              id: rowData.id,
              email: rowData.email,
              roles: rowData.roles,
              organizationType: rowData.organizationType,
              organizationNetwork: rowData.organizationRegionPresence,
              subdivisionPharmacy: rowData.subdivisionPharmacy,
            })
          )
          setOpen(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(UserDictionary)
