import {
  IProductCategory,
  IProductCategoryResponseResult,
  ProductCategory,
  ProductCategoryResponseResult,
} from 'back-office/proto/models'
import { setProductCategoryModalOpen } from 'back-office/reducers/productCategory/productCategoryReducer'
import { IAppState } from 'back-office/reducers/rootReducer'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient/clients/protoClient'
import React from 'react'
import { connect } from 'react-redux'
import { initialize, SubmissionError } from 'redux-form'

import ProductCategoryEditForm from './ProductCategoryEditForm'
import CategoryTable from './ProductCategoryTable'

const url = 'dictionaries/product-categories'

const emptyInitialStateEditForm: IProductCategory = {
  id: null,
  name: '',
}

const ProductCategoryDictionary = (props: any) => {
  const tableRef = React.createRef<any>()

  const onDelete = async (rowData: IProductCategory) => {
    await ProtoClient.delete(url, GuidHelper.toString(rowData.id))
  }

  const handleClose = () => {
    props.dispatch(setProductCategoryModalOpen())
    props.dispatch(initialize('productCategoryEditForm', emptyInitialStateEditForm))
  }

  const onEdit = async (rowData: IProductCategory) => {
    props.dispatch(initialize('productCategoryEditForm', rowData))
    props.dispatch(setProductCategoryModalOpen())
  }

  const onSubmit = async (values: any, dispatch: any, props: any) => {
    if (props.valid) {
      if (Object.keys(values).length == 0 || values.name.trim() === '') {
        throw new SubmissionError({
          name: 'Не заполнено обязательное поле',
        })
      }

      const requestDto: IProductCategory = {
        name: values.name,
        id: values.id,
      }

      const task =
        values.id?.length > 0
          ? ProtoClient.post<IProductCategoryResponseResult>(
              url,
              requestDto,
              ProductCategory,
              ProductCategoryResponseResult
            )
          : ProtoClient.put<IProductCategoryResponseResult>(
              url,
              requestDto,
              ProductCategory,
              ProductCategoryResponseResult
            )

      const result = await task

      if (result.success) {
        tableRef.current && tableRef.current.onQueryChange()
        dispatch(setProductCategoryModalOpen())
        dispatch(initialize('productCategoryEditForm', emptyInitialStateEditForm))
      } else {
        throw new SubmissionError({
          name: result.message,
        })
      }
    }
  }

  return (
    <>
      <CategoryTable
        tableRef={tableRef}
        onAddButtonClick={() => {
          props.dispatch(setProductCategoryModalOpen())
        }}
        onDeleteButtonClick={onDelete}
        onEditButtonClick={onEdit}
      />
      <ProductCategoryEditForm handleClose={handleClose} onSubmit={onSubmit} />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(ProductCategoryDictionary)
