import { createAction, createReducer } from '@reduxjs/toolkit'
import { IProvisorInviteInfo, ProvisorInviteInfo } from 'back-office/proto/models'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Dispatch } from 'redux'

const url = 'dictionaries/provisor-invite/get-provisor-invite-info'

export interface IProvisorInviteInfoState {
  loading: boolean
  provisorInviteInfo: IProvisorInviteInfo
}

const initialState: IProvisorInviteInfoState = {
  loading: false,
  provisorInviteInfo: {},
}

const GET_PROVISOR_INVITE_INFO = 'GET_PROVISOR_INVITE_INFO'
export const loadProvisorInviteInfo = (inviteId: string) => async (dispatch: Dispatch) => {
  dispatch(setLoadingProvisotInviteInfo())

  const params = {
    inviteId: inviteId,
  }

  const provisorInviteInfo = await ProtoClient.get<IProvisorInviteInfo>(url, ProvisorInviteInfo, params)

  dispatch({ type: GET_PROVISOR_INVITE_INFO, payload: provisorInviteInfo })

  dispatch(setLoadingProvisotInviteInfo())
}

const PROVISOR_INVITE_INFO_LOADING = 'PROVISOR_INVITE_INFO_LOADING'
export const setLoadingProvisotInviteInfo = createAction(PROVISOR_INVITE_INFO_LOADING)

const provisorInviteInfoReducer = createReducer(initialState, {
  [PROVISOR_INVITE_INFO_LOADING]: (state) => {
    state.loading = !state.loading
  },
  [GET_PROVISOR_INVITE_INFO]: (state, action) => {
    state.provisorInviteInfo = action.payload
  },
})

export default provisorInviteInfoReducer
