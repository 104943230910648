import { Typography, Box, Paper, FormLabel, FormControl, TextField, Button } from '@material-ui/core'
import { Form, Field, reduxForm, submit, initialize } from 'redux-form'
import React, { Component, useEffect } from 'react'
import { Dispatch } from 'redux'
import { IBillingSettings } from '../../../proto/models'
import { loadBillingSettings, saveBillingSettings } from '../../../reducers/billingSettings/billingSettingsReducer'
import { IAppState } from '../../../reducers/rootReducer'
import { connect } from 'react-redux'
import CurrencyNumberFormat from 'external/rp.ui/components/CustomReduxComponents/CurrencyTextField'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

interface IBillingSettingsProps {
  dispatch: Dispatch
  loading: boolean
  settings: IBillingSettings
}

const EditForm = (props: any) => {
  const { handleSubmit, submitting, loading, settings: IBillingSettings } = props

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box>
          <Box pl={2} pt={2} pb={1}>
            <Typography variant="h6" noWrap>
              Настройки биллинга
            </Typography>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Минимальная сумма вывода</FormLabel>
              <Field
                placeholder={'Введите минимальную сумму доступную для вывода'}
                name={'minimalWithdrawAmount'}
                component={CurrencyNumberFormat}
                variant="outlined"
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
                minimumValue="0"
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Курс USD к TEQ</FormLabel>
              <Field
                placeholder={'Введите сумму'}
                name={'usdToTeqRate'}
                component={CurrencyNumberFormat}
                variant="outlined"
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
                minimumValue="0"
              />
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.dispatch(submit('billingSettingsForm'))
              }}
              disabled={loading || submitting}
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Form>
    </>
  )
}

let BillingSettingsReduxForm = reduxForm({
  form: 'billingSettingsForm',
})(EditForm)

BillingSettingsReduxForm = connect((store: IAppState) => ({
  initialValues: {
    minimalWithdrawAmount: store.billingSettings.billingSettigns.minimalWithdrawAmount,
    usdToTeqRate: store.billingSettings.billingSettigns.usdToTeqRate,
  },
}))(BillingSettingsReduxForm)

const BillingSettingsForm = (props: IBillingSettingsProps) => {
  const settings = props.settings

  const isLoadProps = Object.keys(props.settings).length !== 0

  useEffect(() => {
    if (!isLoadProps) {
      props.dispatch(loadBillingSettings())
    }

    if (isLoadProps) {
      const initialValue = {
        minimalWithdrawAmount: settings.minimalWithdrawAmount,
        usdToTeqRate: settings.usdToTeqRate,
      }

      props.dispatch(initialize('billingSettingsForm', initialValue))
    }
  }, [props.settings.id])

  const saveBillingSettingApi = () => {
    props.dispatch(saveBillingSettings())
  }

  const saveBillingSettingApiDebounced = AwesomeDebouncePromise(saveBillingSettingApi, 2000)

  return (
    <Paper elevation={2}>
      <BillingSettingsReduxForm onSubmit={saveBillingSettingApiDebounced} loading={props.loading} />
    </Paper>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.billingSettings.loading,
    settings: store.billingSettings.billingSettigns,
  }
}

export default connect(mapStateToProps, null)(BillingSettingsForm)
