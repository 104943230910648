import React from 'react'
import { connect } from 'react-redux'
import { Action } from 'redux'
import { getUserInfo } from '../../reducers/user/userReducer'
import { IAppState } from '../../reducers/rootReducer'
import { ThunkDispatch } from 'redux-thunk'

interface IUserInfoProps {
  dispatch: ThunkDispatch<IAppState, {}, Action>
  name: string
  imagePath?: string
}

class UserInfo extends React.Component<IUserInfoProps> {
  componentDidMount() {
    this.props.dispatch(getUserInfo())
  }
  render() {
    // let image = <EmptyAvatar class={profileImage} />

    // if (this.props.imagePath) {
    //   image = <img className={profileImage} src={this.props.imagePath} alt="Avatar" />
    // }

    return (
      //TODO: раскомментировать когда понадобится применить дизайн и добавить сайдбар с пользователем
      <></>
      // <div className={profile}>
      //   <div className={profileBlockImage}>{image}</div>
      //   <div className={profileBlockText}>
      //     <span className={profileName}>{this.props.name}</span>
      //   </div>
      //   <div className={profileBlock}>
      //     <span className={profileExit} onClick={() => logout()}></span>
      //   </div>
      // </div>
    )
  }
}

const mapStateToProps = (state: IAppState): IUserInfoProps => {
  return {
    dispatch: state.dispatch,
    name: state.user.userInfo?.name,
    imagePath: state.user.userInfo?.imagePath,
  }
}

export default connect(mapStateToProps)(UserInfo)
