import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook';
import { loadSmsCode, updateOrCreateSmsCode } from 'back-office/reducers/smsCode/smsCodeReducer';
import React, { useEffect } from 'react';
import { initialize } from 'redux-form';

import SmsCodeForm from './SmsCodeForm';

const SmsCode = () => {
    const smsCode = useAppSelector((state) => state.smsCode.smsCode)
  
    const dispatch = useAppDispatch()
  
    const isLoadProps = Object.keys(smsCode).length !== 0
  
    const saveSmsCodeApi = () => {
      dispatch(updateOrCreateSmsCode())
    }
  
    const saveSmsCodeApiDebounced = AwesomeDebouncePromise(saveSmsCodeApi, 2000)
  
    useEffect(() => {
      if (!isLoadProps) {
        dispatch(loadSmsCode())
      }
  
      if (isLoadProps) {
        const form = {
          isDevMode: smsCode.isDevMode.toString(),
          developmentValidCode: smsCode.developmentValidCode,
        }
  
        dispatch(initialize('smsCode', form))
      }
    }, [smsCode.isDevMode])
  
    return <SmsCodeForm onSubmit={saveSmsCodeApiDebounced} />
  }
  
  export default SmsCode