import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'

import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { PagingAndSort } from 'shared/proto/models'

import { CurrencyExchangeRates, ICurrencyExchangeRateList, ICurrencyExchangeRates } from '../../../../proto/models'
import { tableIcons } from '../../../shared/tableIcons'

interface ICurrencyExchangeRateTableProps {
  onEditButtonClick: (rowData: ICurrencyExchangeRateList) => any
  onDeleteButtonClick: (rowData: ICurrencyExchangeRateList) => any
  onAddButtonClick: () => any
  url: string
  tableRef: any
}

const fetchCurrencyExchangeRates = async (
  query: Query<ICurrencyExchangeRateList>,
  url: string
): Promise<QueryResult<ICurrencyExchangeRateList>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'currencyName',
    sortDirection: query.orderDirection === '' ? 'asc' : query.orderDirection,
    searchQuery: query.search,
  })

  const rates = await ProtoClient.get<ICurrencyExchangeRates>(url, CurrencyExchangeRates, paging)

  return {
    data: rates?.items ?? [],
    page: query.page,
    totalCount: rates?.rowCount ?? 0,
  }
}

export default (props: ICurrencyExchangeRateTableProps) => {
  const [isLoading, setLoading] = React.useState(false)

  return (
    <MaterialTable<ICurrencyExchangeRateList>
      title="Настройка валют"
      isLoading={isLoading}
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        { title: 'Наименование валюты', field: 'currencyName', sorting: true },
        { title: 'Буквенный код', field: 'currencyCode', sorting: true },
        {
          title: 'В USD',
          field: 'toUsdExchangeRate',
          sorting: true,
          render: (rowData) => {
            return new Intl.NumberFormat('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              style: 'decimal',
            }).format(rowData.toUsdExchangeRate)
          },
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: (rowData) => (
            <Fab
              onClick={async () => {
                await props.onDeleteButtonClick(rowData)
                props.tableRef.current && props.tableRef.current.onQueryChange()
              }}
              size="small"
              color="secondary"
              aria-label="delete"
              style={{ margin: 0 }}
            >
              <DeleteIcon />
            </Fab>
          ),
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: (rowData) => (
            <Fab
              onClick={() => props.onEditButtonClick(rowData)}
              size="small"
              color="primary"
              aria-label="edit"
              style={{ margin: 0 }}
            >
              <EditIcon />
            </Fab>
          ),
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchCurrencyExchangeRates(query, props.url)}
      components={{
        Toolbar: (toolbarProps) => (
          <Box>
            <MTableToolbar {...toolbarProps} />
            <Box ml={2}>
              <Grid container direction={'row'} alignItems={'flex-start'}>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
                    Добавить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ),
      }}
    />
  )
}
