import { EOrganizationType } from 'back-office/proto/models'
import { SelectField } from 'external/rp.ui/components/MaterialReduxForm'
import React, { Component, useEffect } from 'react'
import { WrappedFieldProps } from 'redux-form'

import { MenuItem } from '@material-ui/core'

export interface IUserRoleValues {
  roles: string[]
}

export type OrganizationTypeSelectFieldProps = IUserRoleValues & WrappedFieldProps

const rolesTeq = {
  admin: 'TeQ.Admin',
  manufacturerSpecialist: 'TeQ.ManufacturerSpecialist',
  manufacturerManager: 'TeQ.ManufacturerManager',
  pharmacist: 'TeQ.Pharmacist',
  pharmacyManager: 'TeQ.PharmacyManager',
  pharmacySpecialist: 'TeQ.PharmacySpecialist',
  regionalManager: 'TeQ.RegionalManager',
  medicalRepresentative: 'TeQ.MedicalRepresentative',
}

const isOptionDisabled = (organizationType: EOrganizationType, rolesValues: string[]): boolean => {
  switch (organizationType) {
    case EOrganizationType.ManufacturerSubdivision:
      return !rolesValues.some(
        (role) =>
          role === rolesTeq.admin ||
          role === rolesTeq.manufacturerSpecialist ||
          role === rolesTeq.manufacturerManager ||
          role === rolesTeq.medicalRepresentative
      )
    case EOrganizationType.OrganizationRegionPresence:
      return !rolesValues.some(
        (role) =>
          role === rolesTeq.admin ||
          role === rolesTeq.manufacturerSpecialist ||
          role === rolesTeq.manufacturerManager ||
          role === rolesTeq.pharmacyManager ||
          role === rolesTeq.pharmacySpecialist ||
          role === rolesTeq.regionalManager ||
          role === rolesTeq.medicalRepresentative
      )
    case EOrganizationType.Pharmacy:
      return !rolesValues.some((role) => role === rolesTeq.admin || role === rolesTeq.pharmacist)
  }
}

const OrganizationSelectField = (
  props: OrganizationTypeSelectFieldProps
): React.ReactElement<OrganizationTypeSelectFieldProps> => {
  return (
    <SelectField {...props}>
      <MenuItem
        value={EOrganizationType.OrganizationRegionPresence}
        disabled={isOptionDisabled(EOrganizationType.OrganizationRegionPresence, props.roles)}
      >
        организации
      </MenuItem>
      <MenuItem value={EOrganizationType.Pharmacy} disabled={isOptionDisabled(EOrganizationType.Pharmacy, props.roles)}>
        аптекe
      </MenuItem>
      <MenuItem
        value={EOrganizationType.ManufacturerSubdivision}
        disabled={isOptionDisabled(EOrganizationType.ManufacturerSubdivision, props.roles)}
      >
        подразделению
      </MenuItem>
    </SelectField>
  )
}

export default OrganizationSelectField
