import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook';
import React, { useEffect } from 'react';
import { initialize } from 'redux-form';
import { loadAlert, updateOrCreateAlert } from 'shared/reducers/alert/alertReducer';

import AlertsForm from './AlertsForm';

const Alerts = () => {
  const alert = useAppSelector((state) => state.alert.alert)

  const dispatch = useAppDispatch()

  const isLoadProps = Object.keys(alert).length !== 0

  const saveAlertApi = () => {
    dispatch(updateOrCreateAlert())
  }

  const saveAlertApiDebounced = AwesomeDebouncePromise(saveAlertApi, 2000)

  useEffect(() => {
    if (!isLoadProps) {
      dispatch(loadAlert())
    }

    if (isLoadProps) {
      const form = {
        isShowMessage: alert.isShowMessage.toString(),
        message: alert.message,
      }

      dispatch(initialize('alertForm', form))
    }
  }, [alert.isShowMessage])

  return <AlertsForm onSubmit={saveAlertApiDebounced} />
}

export default Alerts