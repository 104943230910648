import { createAction, createReducer, Dispatch } from "@reduxjs/toolkit"
import { ISmsCodeForm, SmsCodeForm } from "back-office/proto/models"
import { ProtoClient } from "external/rp.ui/utils/protoClient"
import { IAppState } from 'back-office/reducers/rootReducer'

const smsCodeUrl = 'back-office/system-settings'

export interface ISmsCodeState {
    loading: boolean
    smsCode: ISmsCodeForm
}

const initialSmsCodeState: ISmsCodeState = {
    loading: false,
    smsCode: {},
}

const SET_SMS_CODE = 'SET_SMS_CODE'
export const loadSmsCode = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(smsCodeLoading())

    const smsCode = await ProtoClient.get<ISmsCodeForm>(smsCodeUrl + '/get-sms', SmsCodeForm)

    dispatch({ type: SET_SMS_CODE, payload: smsCode })
    dispatch(smsCodeLoading())
}

export const updateOrCreateSmsCode = () => async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    dispatch(smsCodeLoading())

    const state = getState()

    const smsCodeToSave: ISmsCodeForm = {
        isDevMode: state.form.smsCode.values.isDevMode === 'true' ? true : false,
        developmentValidCode: state.form.smsCode.values.developmentValidCode,
    }

    try {
        await ProtoClient.post<ISmsCodeForm>(smsCodeUrl + '/sms-create-or-update', smsCodeToSave, SmsCodeForm)
        dispatch(smsCodeLoading())
    } catch {
        dispatch(resetSmsCodeState())
    }
}

const SMS_CODE_LOADING = 'SMS_CODE_LOADING'
const smsCodeLoading = createAction(SMS_CODE_LOADING)

const RESET_SMS_CODE_STATE = 'RESET_SMS_CODE_STATE'
export const resetSmsCodeState = createAction(RESET_SMS_CODE_STATE)

const smsCodeReducer = createReducer(initialSmsCodeState, {
    [SMS_CODE_LOADING]: (state) => {
        state.loading = !state.loading
    },
    [SET_SMS_CODE]: (state, action) => {
        state.smsCode = action.payload
    },
    [RESET_SMS_CODE_STATE]: (state) => {
        state.loading = initialSmsCodeState.loading
        state.smsCode = initialSmsCodeState.smsCode
    },
})

export default smsCodeReducer