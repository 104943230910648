import { createAction } from '@reduxjs/toolkit'
import { Action, combineReducers } from 'redux'
import { FormStateMap, reducer as formReducer } from 'redux-form'
import { ThunkDispatch } from 'redux-thunk'
import alertReducer, { IAlertState } from '../../shared/reducers/alert/alertReducer'
import administratorOrganizationReducer, {
  IAdministratorOrganizationState,
} from './administratorOrganizations/administratorOrganizationReducer'
import billingSettingsReducer, { IBillingSettingsState } from './billingSettings/billingSettingsReducer'
import courseSettingsReducer, { ICourseSettingsState } from './courseSettings/courseSettingsReducer'
import organizationReducer, { IOrganizationState } from './organizations/organizationReducer'
import productCategoryReducer, { IProductCategoryState } from './productCategory/productCategoryReducer'
import provisorInviteInfoReducer, {
  IProvisorInviteInfoState,
} from './provisorInviteInfoReducer/provisorInviteInfoReducer'
import sellerReducer, { ISellerState } from './seller/sellerReducer'
import certificateReducer, { ICertificateState } from './certificate/certificateReducer'
import userReducer, { IUserState } from './user/userReducer'
import smsCodeReducer, { ISmsCodeState } from './smsCode/smsCodeReducer'
import appVersionStandReducer, { IApplicationVersionStandState } from './applicationVersionStand/applicationVersionStandReducer'
import orderInfoReducer, { IOrderState } from './orders/ordersReducer'

export interface IAppState {
  dispatch: ThunkDispatch<IAppState, {}, Action>
  billingSettings: IBillingSettingsState
  courseSettings: ICourseSettingsState
  form: FormStateMap
  user: IUserState
  administratorOrganization: IAdministratorOrganizationState
  organization: IOrganizationState
  productCategory: IProductCategoryState
  alert: IAlertState
  seller: ISellerState
  certificate: ICertificateState
  provisorInviteInfo: IProvisorInviteInfoState
  smsCode: ISmsCodeState
  appVersionStand: IApplicationVersionStandState,
  order: IOrderState
}

export type AppDispatch = ThunkDispatch<IAppState, {}, Action>

const appReducer = combineReducers({
  billingSettings: billingSettingsReducer,
  courseSettings: courseSettingsReducer,
  form: formReducer,
  user: userReducer,
  administratorOrganization: administratorOrganizationReducer,
  organization: organizationReducer,
  productCategory: productCategoryReducer,
  alert: alertReducer,
  seller: sellerReducer,
  certificate: certificateReducer,
  provisorInviteInfo: provisorInviteInfoReducer,
  smsCode: smsCodeReducer,
  appVersionStand: appVersionStandReducer,
  order: orderInfoReducer
})

export const resetAppState = createAction('RESET_STATE')

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}
