import React from 'react'
import { connect } from 'react-redux'
import { initialize, SubmissionError } from 'redux-form'
import {
  IOrganization,
  IOrganizationRegionPresence,
  IOrganizationRegionPresenceCreateResult,
  OrganizationRegionPresence,
  OrganizationRegionPresenceCreateResult,
} from 'back-office/proto/models'
import { AppDispatch, IAppState } from 'back-office/reducers/rootReducer'

import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import Notification from 'shared/components/Notification'

import OrganizationRegionPresenceEditWindow, { EditFormOwnProps } from './OrganizationRegionPresenceEditWindow'
import OrganizationRegionPresenceTable from './OrganizationRegionPresenceTable'

const url = 'dictionaries/organization-region-presences'

interface OrganizationRegionPresenceDictionaryOwnProps {
  organizationId: string
  path: string
}

interface OrganizationRegionPresenceDictionaryStateProps {
  dispatch: AppDispatch
  organization: IOrganization
}

type OrganizationRegionPresenceDictionaryProps = OrganizationRegionPresenceDictionaryOwnProps &
  OrganizationRegionPresenceDictionaryStateProps

const OrganizationRegionPresenceDictionary = (props: OrganizationRegionPresenceDictionaryProps) => {
  const { organization, dispatch } = props

  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)

  const [notificationMessage, setNotificationMessage] = React.useState<string>(null)

  const windowParams: EditFormOwnProps = {
    handleClose: () => {
      setOpen(false)
      dispatch(
        initialize('organizationRegionPresenceEditForm', {
          id: [],
          organizationId: organization.id,
          organizationName: organization.name,
          regionPresence: null,
        })
      )
    },
    isOpen: open,
  }

  const onFormSubmit = async (values, dispatch, props) => {
    if (props.valid) {
      const toSave: IOrganizationRegionPresence = {
        organizationId: values.organizationId,
        regionPresenceId: values.regionPresence.id,
        id: values.id,
      }
      const task =
        values.id?.length > 0
          ? ProtoClient.post<IOrganizationRegionPresenceCreateResult>(
              url,
              toSave,
              OrganizationRegionPresence,
              OrganizationRegionPresenceCreateResult
            )
          : ProtoClient.put<IOrganizationRegionPresenceCreateResult>(
              url,
              toSave,
              OrganizationRegionPresence,
              OrganizationRegionPresenceCreateResult
            )

      const result = await task

      if (result.success) {
        tableRef.current && tableRef.current.onQueryChange()
        setOpen(false)
        dispatch(
          initialize('organizationRegionPresenceEditForm', {
            id: [],
            organizationId: organization.id,
            organizationName: organization.name,
            regionPresence: null,
          })
        )
      } else {
        throw new SubmissionError({
          regionPresence: 'Запись с таким регионом присутствия уже существует.',
        })
      }
    }
  }

  const onDelete = async (rowData) => {
    const result = await ProtoClient.delete<IOrganizationRegionPresenceCreateResult>(
      url,
      GuidHelper.toString(rowData.id),
      OrganizationRegionPresenceCreateResult
    )
    if (!result.success) {
      setNotificationMessage(result.message)
    }
  }

  return (
    <>
      <Notification
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notificationMessage != null}
        autoHideDuration={6000}
        text={notificationMessage}
        onClose={() => setNotificationMessage(null)}
        response="negative"
      />
      <OrganizationRegionPresenceEditWindow {...windowParams} onSubmit={onFormSubmit} />
      <OrganizationRegionPresenceTable
        onDeleteButtonClick={onDelete}
        tableRef={tableRef}
        onAddButtonClick={() => {
          dispatch(
            initialize('organizationRegionPresenceEditForm', {
              id: [],
              organizationId: organization.id,
              organizationName: organization.name,
              regionPresence: null,
            })
          )
          setOpen(true)
        }}
        url={url}
        organizationId={props.organizationId}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    organization: store.organization.orgnization,
  }
}

export default connect(mapStateToProps, null)(OrganizationRegionPresenceDictionary)
