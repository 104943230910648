import { createAction, createReducer } from '@reduxjs/toolkit'

export interface ISellerState {
  isEditWindowOpen: boolean
}

const initialState: ISellerState = {
  isEditWindowOpen: false,
}

const SET_SELLER_MODAL_OPEN = 'SET_SELLER_MODAL_OPEN'
export const setSellerModalOpen = createAction(SET_SELLER_MODAL_OPEN)

const sellerReducer = createReducer(initialState, {
  [SET_SELLER_MODAL_OPEN]: (state) => {
    state.isEditWindowOpen = !state.isEditWindowOpen
  },
})

export default sellerReducer
