import React from 'react'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { submit, initialize } from 'redux-form'
import { connect } from 'react-redux'
import { IAppState } from '../../../../reducers/rootReducer'
import ActionTypeCostTable from './ActionTypeCostTable'
import ActionTypeCostEditForm, { IActionTypeCostEditWindowContext } from './ActionTypeCostEditWindow'
import {
  ActionTypeCost,
  IActionTypeCost,
  ActionTypeCostResponse,
  IActionTypeCosts,
  ActionTypeCosts,
  EPharmacistActionType,
} from '../../../../proto/models'
import { Query, QueryResult } from 'material-table'

const url = 'registries/action-type-cost'

const fetchActionTypeCosts = async (query: Query<IActionTypeCost>): Promise<QueryResult<IActionTypeCost>> => {
  const paging = {
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'pharmacistActionType',
    sortDirection: query.orderDirection === '' ? 'asc' : query.orderDirection,
    searchQuery: query.search,
  }

  const rates = await ProtoClient.get<IActionTypeCosts>(url, ActionTypeCosts, paging)

  return {
    data: rates?.items ?? [],
    page: query.page,
    totalCount: rates?.rowCount ?? 0,
  }
}

const ActionTypeCostDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const [existActionTypes, setExistActionTypes] = React.useState<EPharmacistActionType[]>([])
  const windowParams: IActionTypeCostEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('ActionTypeCostForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('ActionTypeCostForm', {
          id: [],
          pharmacistActionType: null,
          amount: '',
        })
      )
    },
    isOpen: open,
    existActionTypes: existActionTypes,
  }

  return (
    <>
      <ActionTypeCostEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            const task =
              values.id?.length > 0
                ? ProtoClient.post<IActionTypeCost>(url, values, ActionTypeCost, ActionTypeCostResponse)
                : ProtoClient.put<IActionTypeCost>(url, values, ActionTypeCost, ActionTypeCostResponse)

            await task
            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(
              initialize('ActionTypeCostForm', {
                id: [],
                currency: null,
                toUsdExchangeRate: '',
              })
            )
          }
        }}
      />
      <ActionTypeCostTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize('ActionTypeCostForm', {
              id: rowData.id,
              pharmacistActionType: rowData.pharmacistActionType,
              amount: rowData.amount,
            })
          )
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        fetchActionTypeCosts={async (query) => {
          const res = await fetchActionTypeCosts(query)
          setExistActionTypes(res.data.map((value) => value.pharmacistActionType))
          return res
        }}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(ActionTypeCostDictionary)
