import React from 'react'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { submit, initialize, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { IAppState } from '../../../reducers/rootReducer'
import CategoryEditForm, { ICategoryEditWindowContext } from './CategoryEditWindow'
import CategoryTable from './CategoryTable'
import { CategoryForm, CategoryListResponseResult, ICategoryListResponseResult } from '../../../proto/models'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'

const url = 'dictionaries/categories'

const CategoryDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: ICategoryEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('categoryEditForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('categoryEditForm', {
          id: [],
          name: '',
          description: '',
        })
      )
    },
    isOpen: open,
  }

  return (
    <>
      <CategoryEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            var data = CategoryForm.create({
              id: values.id,
              name: values.name,
              description: values.description,
            })

            const task =
              values.id?.length > 0
                ? ProtoClient.post<ICategoryListResponseResult>(url, data, CategoryForm, CategoryListResponseResult)
                : ProtoClient.put<ICategoryListResponseResult>(url, data, CategoryForm, CategoryListResponseResult)

            const result = await task

            if (result.success) {
              tableRef.current && tableRef.current.onQueryChange()
              setOpen(false)
              dispatch(
                initialize('categoryEditForm', {
                  id: [],
                  name: '',
                  description: '',
                })
              )
            } else {
              throw new SubmissionError({
                name: result.message,
              })
            }
          }
        }}
      />
      <CategoryTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize('categoryEditForm', {
              id: rowData.id,
              name: rowData.name,
              description: rowData.description,
            })
          )
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(CategoryDictionary)
