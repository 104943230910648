import { createAction, createReducer } from "@reduxjs/toolkit"

export interface IApplicationVersionStandState {
    isEditWindowOpen: boolean
}

const initialState: IApplicationVersionStandState = {
    isEditWindowOpen: false,
}

const SET_APP_VERSION_STAND_MODAL_OPEN = 'SET_APP_VERSION_STAND_MODAL_OPEN'
export const setAppVersionStandModalOpen = createAction(SET_APP_VERSION_STAND_MODAL_OPEN)

const appVersionStandReducer = createReducer(initialState, {
    [SET_APP_VERSION_STAND_MODAL_OPEN]: (state) => {
        state.isEditWindowOpen = !state.isEditWindowOpen
    },
})

export default appVersionStandReducer