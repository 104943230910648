import { Box } from '@material-ui/core'
import React from 'react'
import ActionTypeCostDictionary from './ActionTypeCosts/ActionTypeCostDictionary'
import CourseSettingsForm from './CourseSettingsForm'

const CourseSettings = (props: any) => (
  <>
    <Box>
      <Box p={1}>
        <CourseSettingsForm />
      </Box>
      <Box p={1}>
        <ActionTypeCostDictionary />
      </Box>
    </Box>
  </>
)

export default CourseSettings
