import { useAppDispatch } from 'back-office/components/App/hook'
import { ISeller, ISellersResponseResult, Seller, SellersResponseResult } from 'back-office/proto/models'
import { setSellerModalOpen } from 'back-office/reducers/seller/sellerReducer'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import React from 'react'
import { initialize, SubmissionError } from 'redux-form'
import SellerEditForm from './SellerEditForm'
import SellersTable from './SellerTable'

const url = 'dictionaries/sellers'

const emptyInitialStateEditForm: ISeller = {
  id: null,
  name: '',
}

const SellerDictionary = () => {
  const dispatch = useAppDispatch()

  const tableRef = React.createRef<any>()

  const onDelete = async (rowData: ISeller) => {
    await ProtoClient.delete(url, GuidHelper.toString(rowData.id))
  }

  const openAndInitialize = () => {
    dispatch(setSellerModalOpen())
    dispatch(initialize('sellerEditForm', emptyInitialStateEditForm))
  }

  const handleClose = () => {
    openAndInitialize()
  }

  const onEdit = async (rowData: ISeller) => {
    dispatch(initialize('sellerEditForm', rowData))
    dispatch(setSellerModalOpen())
  }

  const onSubmit = async (values: any, dispatch: any, props: any) => {
    if (props.valid) {
      if (Object.keys(values).length == 0 || values.name.trim() === '') {
        throw new SubmissionError({
          name: 'Не заполнено обязательное поле',
        })
      }

      const requestDto: ISeller = {
        name: values.name,
        id: values.id,
      }

      const task =
        values.id?.length > 0
          ? ProtoClient.post<ISellersResponseResult>(url, requestDto, Seller, SellersResponseResult)
          : ProtoClient.put<ISellersResponseResult>(url, requestDto, Seller, SellersResponseResult)

      const result = await task

      if (result.success) {
        tableRef.current && tableRef.current.onQueryChange()
        openAndInitialize()
      } else {
        throw new SubmissionError({
          name: result.message,
        })
      }
    }
  }

  return (
    <>
      <SellersTable
        tableRef={tableRef}
        onAddButtonClick={() => {
          dispatch(setSellerModalOpen())
        }}
        onDeleteButtonClick={onDelete}
        onEditButtonClick={onEdit}
        requestUrl={url}
      />
      <SellerEditForm handleClose={handleClose} onSubmit={onSubmit} />
    </>
  )
}

export default SellerDictionary
