import { Router } from '@reach/router'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { rootReducer } from 'back-office/reducers/rootReducer'
import { AuthError, ThrottleError } from 'external/rp.ui/utils/protoClient'
import { navigate, withPrefix } from 'gatsby'
import React from 'react'
import { Provider } from 'react-redux'
import PrivateRoute from 'shared/components/PrivateRoute/PrivateRoute'

import CategoryDictionary from '../dictionaries/Categories/CategoryDictionary'
import { ProvisorInviteEditWindow, ProvisorInviteTable } from '../dictionaries/ProvisorInvite'
import ProvisorInviteInfo from '../dictionaries/ProvisorInvite/ProvisorInviteInfo/ProvisorInviteInfo'
import { ProvisorPaymentDictionary } from '../dictionaries/ProvisorPayment'
import { UserInviteEditWindow, UserInviteTable } from '../dictionaries/UserInvite'
import UserDictionary from '../dictionaries/Users/UserDictionary'
import AdministratorOrganizations from '../Registries/AdministratorOrganizations/AdministratorOrganizations'
import BillingSettings from '../Registries/BillingSettings/BillingSettings'
import ProductCategoryDictionary from '../Registries/CertificateSettings/Category/ProductCategoryDictionary'
import CertificateSettingsPage from '../Registries/CertificateSettings/CertificateSettings'
import SellerDictionary from '../Registries/CertificateSettings/Seller/SellerDictionary'
import CourseSettings from '../Registries/CourseSettings/CourseSettings'
import ManufacturersOrganizationDictionary from '../Registries/Organizations/ManufacturersBranches/ManufacturerBranchDictionary'
import OrganizationPage from '../Registries/Organizations/Organization'
import OrganizationDictionary from '../Registries/Organizations/OrganizationDictionary'
import PharmacyOrganizationBranchDictionary from '../Registries/Organizations/PharmacyOrganizationBranches/PharmacyOrganizationBranchDictionary'
import RegionalManagerBranchDictionary from '../Registries/Organizations/RegionalManagerBranches/RegionalManagerBranchDictionary'
import OrganizationRegionPresenceDictionary from '../Registries/Organizations/RegionPresences/OrganizationRegionPresenceDictionary'
import RegionalManagerDictionary from '../Registries/RegionalManagers/RegionalManagerDictionary'
import RegionPresenceDictionary from '../Registries/RegionPresence/RegionPresenceDictionary'
import AppWrapper from './AppWrapper'
import CertificateDictionary from '../Registries/CertificateSettings/Certificate/CertificateDicitonary'
import CertificateEditForm from '../Registries/CertificateSettings/Certificate/CertificateEditForm/CertificateEditForm'
import SystemSettings from '../SystemSettings/SystemSetings'
import OrdersTable from '../Registries/CertificateSettings/Order/OrderTable/OrdersTable'
import OrderInfo from '../Registries/CertificateSettings/Order/OrderInfo/OrderInfo'

const middleware = getDefaultMiddleware({
  immutableCheck: true,
  serializableCheck: false,
  thunk: true,
})

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: true,
})

export type BackOfficeRootState = ReturnType<typeof store.getState>
export type BackOfficeAppDispatch = typeof store.dispatch

const App = (): React.ReactElement => {
  React.useEffect(() => {
    window.onunhandledrejection = (ev: PromiseRejectionEvent) => {
      if (ev.reason instanceof ThrottleError) {
        return
      }

      if (ev.reason instanceof AuthError) {
        ev.preventDefault()
        navigate(`/`)
        return
      }
      return
    }

    return () => {
      window.onunhandledrejection = null
    }
  })

  return (
    <Provider store={store}>
      <Router>
        <PrivateRoute path={withPrefix('/back-office')} component={AppWrapper}>
          <BillingSettings path="billing-settings" />
          <CategoryDictionary path="categories" />
          <UserDictionary path="users" />
          <CourseSettings path="course-settings" />
          <UserInviteTable path="/user-invite" />
          <UserInviteEditWindow path="/user-invite/edit" />
          <UserInviteEditWindow path="/user-invite/edit/:id" />
          <ProvisorInviteTable path="/provisor-invite" />
          <ProvisorInviteInfo path="/provisor-invite/:inviteId" />
          <ProvisorInviteEditWindow path="/provisor-invite/edit" />
          <ProvisorPaymentDictionary path="/provisor-payment" />
          <AdministratorOrganizations path="/administrator-organizations" />
          <RegionalManagerDictionary path="/regional-manager" />
          <RegionPresenceDictionary path="/region-of-presence" />
          <OrganizationDictionary path="/organizations" />
          <OrganizationPage path="/organizations/:organizationId">
            <OrganizationRegionPresenceDictionary path="regions-of-presence" />
            <ManufacturersOrganizationDictionary path="manufacturer-branches" />
            <PharmacyOrganizationBranchDictionary path="pharmacy-organization-branches" />
            <RegionalManagerBranchDictionary path="regional-manager-branches" />
          </OrganizationPage>
          <CertificateSettingsPage path="/certificate-settings">
            <ProductCategoryDictionary path="categories" />
            <SellerDictionary path="sellers" />
            <CertificateDictionary path="certificate" />
            <OrdersTable path="order" />
          </CertificateSettingsPage>
          <CertificateEditForm path="/certificate-settings/certificate/:id" />
          <OrderInfo path="/certificate-settings/order/:id" />
          <SystemSettings path="/system-settings" />
        </PrivateRoute>
      </Router>
    </Provider>
  )
}

export default App
