import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Dispatch } from 'redux'
import { createReducer, createAction } from '@reduxjs/toolkit'
import { IBillingSettings, BillingSettings } from '../../proto/models'
import { IAppState } from '../rootReducer'

const url = 'registries/billing-settings'
export interface IBillingSettingsState {
  loading: boolean
  billingSettigns: IBillingSettings
}

const initialState: IBillingSettingsState = {
  loading: false,
  billingSettigns: {},
}

export const loadBillingSettings = () => async (dispatch: Dispatch) => {
  dispatch({
    type: BILLING_SETTINGS_LOADING,
  })

  const settings = await ProtoClient.get<IBillingSettings>(url, BillingSettings)

  dispatch({
    type: BILLING_SETTINGS_LOADED,
    payload: settings,
  })
}

export const saveBillingSettings = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const state = getState()

  var settingsToSave: IBillingSettings = {
    id: state.billingSettings.billingSettigns.id,
    minimalWithdrawAmount: state.form.billingSettingsForm.values.minimalWithdrawAmount,
    usdToTeqRate: state.form.billingSettingsForm.values.usdToTeqRate,
  }

  dispatch({
    type: BILLING_SETTINGS_LOADING,
  })

  const settings =
    settingsToSave.id.length > 0
      ? await ProtoClient.post<IBillingSettings>(url, settingsToSave, BillingSettings, BillingSettings)
      : await ProtoClient.put<IBillingSettings>(url, settingsToSave, BillingSettings, BillingSettings)

  dispatch({
    type: BILLING_SETTINGS_LOADED,
    payload: settings,
  })
}

const BILLING_SETTINGS_LOADING = 'BILLING_SETTINGS_LOADING'
const BILLING_SETTINGS_LOADED = 'BILLING_SETTINGS_LOADED'

const billingSettingsReducer = createReducer(initialState, {
  [BILLING_SETTINGS_LOADING]: (state, action) => {
    state.loading = true
  },
  [BILLING_SETTINGS_LOADED]: (state, action) => {
    state.loading = false
    state.billingSettigns = action.payload
  },
})

export default billingSettingsReducer
