import { createReducer, Dispatch } from '@reduxjs/toolkit'
import {
  BillingSettings,
  CurrencyList,
  IBillingSettings,
  ICurrencyExchangeRateList,
  ICurrencyList,
  IProductCategoriesList,
  IProductCategory,
  ISeller,
  ISellersList,
  ProductCategoriesList,
  SellersList,
} from 'back-office/proto/models'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { IAppState } from '../rootReducer'

const urlProductCategories = 'dictionaries/product-categories/get-product-categories'
const urlSellers = 'dictionaries/sellers/get-seller'
const urlCurrencies = 'registries/currency-exchange-rates/get-currencies'
const urlBillingSetting = 'registries/billing-settings'

export interface ICertificateState {
  isEditWindowOpen: boolean
  productCategories: IProductCategory[]
  sellers: ISeller[]
  currencies: ICurrencyExchangeRateList[]
  billingSettings: IBillingSettings
  teqCost: string
}

const initialState: ICertificateState = {
  isEditWindowOpen: false,
  productCategories: [],
  sellers: [],
  currencies: [],
  billingSettings: {},
  teqCost: '0.00',
}

const GET_PRODUCT_CATEGORY_LIST = 'GET_PRODUCT_CATEGORY_LIST'
export const getProductCategory = () => async (dispatch: Dispatch) => {
  const response = await ProtoClient.get<IProductCategoriesList>(urlProductCategories, ProductCategoriesList)

  dispatch({ type: GET_PRODUCT_CATEGORY_LIST, payload: response })
}

const GET_SELLER_LIST = 'GET_SELLER_LIST'
export const getSellers = () => async (dispatch: Dispatch) => {
  const response = await ProtoClient.get<ISellersList>(urlSellers, SellersList)

  dispatch({ type: GET_SELLER_LIST, payload: response })
}

const SET_CERTIFICATE_TEQ_COST = 'SET_CERTIFICATE_TEQ_COST'
export const setCertificateTeqCost = (denomination: number, currency: number) => async (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const billingSetting = getState().certificate.billingSettings.usdToTeqRate
  const result = ((denomination / currency) * billingSetting).toFixed(2).toString()

  dispatch({ type: SET_CERTIFICATE_TEQ_COST, payload: result })
}

const GET_CERTIFICATE_BILLING_SETTING = 'GET_CERTIFICATE_BILLING_SETTING'
export const getBillingSetting = () => async (dispatch: Dispatch) => {
  const settings = await ProtoClient.get<IBillingSettings>(urlBillingSetting, BillingSettings)

  dispatch({ type: GET_CERTIFICATE_BILLING_SETTING, payload: settings })
}

const GET_CURRENCIES_LIST = 'GET_CURRENCIES_LIST'
export const getCurrencies = () => async (dispatch: Dispatch) => {
  const response = await ProtoClient.get<ICurrencyList>(urlCurrencies, CurrencyList)

  dispatch({ type: GET_CURRENCIES_LIST, payload: response })
}

const certificateReducer = createReducer(initialState, {
  [GET_PRODUCT_CATEGORY_LIST]: (state, action) => {
    state.productCategories = action.payload.items
  },
  [GET_SELLER_LIST]: (state, action) => {
    state.sellers = action.payload.items
  },
  [GET_CURRENCIES_LIST]: (state, action) => {
    state.currencies = action.payload.items
  },
  [GET_CERTIFICATE_BILLING_SETTING]: (state, action) => {
    state.billingSettings = action.payload
  },
  [SET_CERTIFICATE_TEQ_COST]: (state, action) => {
    state.teqCost = action.payload
  },
})

export default certificateReducer
