import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
} from '@material-ui/core'
import { IAppState } from 'back-office/reducers/rootReducer'
import CurrencyNumberFormat from 'external/rp.ui/components/CustomReduxComponents/CurrencyTextField'
import { SelectField } from 'external/rp.ui/components/MaterialReduxForm'
import React from 'react'
import { connect } from 'react-redux'
import { Field, Form, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'

import { EPharmacistActionType, IActionTypeCost } from '../../../../proto/models'
import getDisplayName from './enumDisplayName'

export interface IActionTypeCostEditWindowContext {
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  existActionTypes: EPharmacistActionType[]
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const menuItems = (existActionTypes: EPharmacistActionType[]) => {
  return Object.keys(EPharmacistActionType)
    .filter((key) => !existActionTypes.find((v) => v === EPharmacistActionType[key]))
    .map((key) => (
      <MenuItem key={key} value={EPharmacistActionType[key]}>
        {getDisplayName(EPharmacistActionType[key])}
      </MenuItem>
    ))
}

const EditForm = (
  props: IActionTypeCostEditWindowContext &
    InjectedFormProps<IActionTypeCost, IActionTypeCostEditWindowContext & StateProps, string> &
    StateProps
) => {
  const { handleSubmit, submitting } = props
  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Редактирование настройки рейтинга</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Тип действия провизора</FormLabel>
              <Field name={'pharmacistActionType'} component={SelectField} validate={required}>
                {menuItems(props.existActionTypes.filter((value) => value !== props.pharmacistActionType))}
              </Field>
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <FormLabel component="legend">Стоимость</FormLabel>
              <Field
                placeholder={'Введите стоимость'}
                name={'amount'}
                component={CurrencyNumberFormat}
                validate={required}
                textAlign="left"
                currencySymbol=""
                decimalCharacter=","
                digitGroupSeparator=" "
              />
            </FormControl>
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ActionTypeCostEditForm = reduxForm<IActionTypeCost, IActionTypeCostEditWindowContext & StateProps>({
  form: 'ActionTypeCostForm',
})(EditForm)

type StateProps = {
  pharmacistActionType: EPharmacistActionType
}

const selector = formValueSelector('ActionTypeCostForm')

const mapStateToProps = (store: IAppState): StateProps => {
  const pharmacistActionType = selector(store, 'pharmacistActionType')

  return {
    pharmacistActionType,
  }
}

export default connect<StateProps, undefined, IActionTypeCostEditWindowContext, IAppState>(mapStateToProps)(
  ActionTypeCostEditForm
)
