import { Box, Fab, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { tableIcons } from 'back-office/components/shared/tableIcons'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Link, withPrefix } from 'gatsby'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React, { useEffect, useRef } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { IPagingAndSort } from 'shared/proto/models'
import { formControl, icon, toolBar } from './OrderTable.module.scss'
import { EOrderStatus, IOrder, IOrderListResult, OrderListResult } from 'back-office/proto/models'
import { DateHelper } from 'external/rp.ui/helpers/DateHelper'

const requestUrl = 'dictionaries/orders'

interface IOrderPagingAndSort extends IPagingAndSort {
  status: number
}

const OrdersTable = () => {
  const [isLoading, setLoading] = React.useState(false)
  const [status, setStatus] = React.useState(0)
  const didMountRef = useRef(false)
  const tableRef = React.createRef<any>()

  const fetchData = async (query: Query<IOrder>): Promise<QueryResult<IOrder>> => {
    const paging: IOrderPagingAndSort = {
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: 'createDate',
      sortDirection: 'dec',
      searchQuery: query.search,
      status: status,
    }

    const orders = await ProtoClient.get<IOrderListResult>(requestUrl, OrderListResult, paging)

    return {
      data: orders?.items ?? [],
      page: query.page,
      totalCount: orders?.rowCount ?? 0,
    }
  }

  useEffect(() => {
    if (didMountRef.current) {
      tableRef.current && tableRef.current.onQueryChange()
    } else {
      didMountRef.current = true
    }
  }, [status])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as number)
  }

  const viewColumnRenderer = (rowData: IOrder): JSX.Element => (
    <Link to={withPrefix(`back-office/certificate-settings/order/${GuidHelper.toString(rowData.id)}`)}>
      <Fab size="small" color="primary" aria-label="edit" className={icon}>
        <VisibilityIcon />
      </Fab>
    </Link>
  )

  const statusColumnRender = (rowData: IOrder): JSX.Element => (
    <>{getStatus(rowData.status)}</>
  )

  const getStatus = (status: EOrderStatus): string => {
    switch (status) {
      case EOrderStatus.InProgress:
        return 'В обработке'
      case EOrderStatus.Done:
        return 'Завершен'
      case EOrderStatus.Rejected:
        return 'Отклонен'
      default:
        return 'status not inmplement'
    }
  }

  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <div className={toolBar}>
        <MTableToolbar {...toolbarProps} />
        <FormControl className={formControl}>
          <InputLabel id="demo-controlled-open-select-label">Статус</InputLabel>
          <Select id="status" value={status} onChange={handleChange}>
            <MenuItem value={0}>Все</MenuItem>
            <MenuItem value={EOrderStatus.InProgress}>В обработке</MenuItem>
            <MenuItem value={EOrderStatus.Done}>Завершен</MenuItem>
            <MenuItem value={EOrderStatus.Rejected}>Отклонен</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Box>
  )

  return (
    <MaterialTable<IOrder>
      tableRef={tableRef}
      isLoading={isLoading}
      localization={{
        toolbar: {
          searchTooltip: 'Поиск',
          searchPlaceholder: 'Поиск',
        },
        pagination: {
          labelRowsSelect: 'строк',
        },
      }}
      icons={tableIcons}
      columns={[
        {
          title: 'Номер заказа',
          field: 'number',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Статус',
          field: 'status',
          sorting: true,
          defaultSort: 'asc',
          render: statusColumnRender
        },
        {
          title: 'Получатель',
          field: 'recipient',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Адрес доставки',
          field: 'email',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Дата заказа',
          field: 'createDate',
          sorting: true,
          defaultSort: 'asc',
          render: (rowData) => DateHelper.toStringWithTime(rowData.createDate as number, 'DD.MM.YYYY'),
        },
        {
          title: 'Не позже',
          field: 'deadLineDate',
          sorting: true,
          defaultSort: 'asc',
          render: (rowData) => DateHelper.toStringWithTime(rowData.deadLineDate as number, 'DD.MM.YYYY'),
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'showInfo',
          render: viewColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: true,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
        rowStyle: rowData => {
          if(rowData.isDeadLineOver) {
            return {backgroundColor: '#FA4444'};
          }
          
          return {};
        }
      }}
      data={(query) => fetchData(query)}
      title=""
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default OrdersTable
