import React from 'react'
import { submit, initialize, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { IAppState } from '../../../reducers/rootReducer'
import RegionPresenceEditForm, { EditFormOwnProps } from './RegionPresenceEditWindow'
import RegionPresenceTable from './RegionPresenceTable'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import {
  IRegionPresence,
  IRegionPresenceCreateResult,
  IRegionPresenceSearchResult,
  RegionPresence,
  RegionPresenceCreateResult,
} from 'back-office/proto/models'

const url = 'dictionaries/region-of-presence'

const RegionPresenceDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: EditFormOwnProps = {
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('regionPresenceEditForm', {
          id: [],
          name: '',
          addressPart: null,
        })
      )
    },
    isOpen: open,
  }

  const onFormSubmit = async (values: { regionPresence: IRegionPresenceSearchResult }, dispatch, props) => {
    if (props.valid) {
      const toSave: IRegionPresence = {
        countryNameId: values.regionPresence.countryNameId,
        regionNameId: values.regionPresence.regionNameId,
        settlementNameId: values.regionPresence.settlementNameId,
      }
      const result = await ProtoClient.put<IRegionPresenceCreateResult>(
        url,
        toSave,
        RegionPresence,
        RegionPresenceCreateResult
      )

      if (result.success) {
        tableRef.current && tableRef.current.onQueryChange()
        setOpen(false)
        dispatch(
          initialize('regionPresenceEditForm', {
            id: [],
            name: '',
            description: '',
          })
        )
      } else {
        throw new SubmissionError({
          regionPresence: 'Запись с таким наименованием уже существует.',
        })
      }
    }
  }

  return (
    <>
      <RegionPresenceEditForm {...windowParams} onSubmit={onFormSubmit} />
      <RegionPresenceTable
        tableRef={tableRef}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(RegionPresenceDictionary)
