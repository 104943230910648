import { Box } from '@material-ui/core'
import React from 'react'
import AdministratorOrganizationForm from './AdministratorOrganizationForm'

export default (props: any) => (
  <Box flex="">
    <Box p={1}>
      <AdministratorOrganizationForm />
    </Box>
  </Box>
)
