import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { initialize, SubmissionError, submit } from 'redux-form'
import {
  AccountOperationForm,
  IAccountOperationForm,
  IRegionalManagerCreateResult,
  RegionalManagerBranch,
  RegionalManagerCreateResult,
} from 'back-office/proto/models'

import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'

import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import AddFundsToAccountForm, {
  IAddFundsToAccountModalContext,
} from '../../../dictionaries/AddFundsToAccountModal/AddFundsToAccountModal'
import RegionalManagerBranchEditModal, {
  IRegionalManagerBranchEditModalProps,
  IRegionalManagerBranchFormValues,
} from './RegionalManagerBranchEditModal'
import RegionalManagerBranchTable from './RegionalManagerBranchTable'

const url = 'dictionaries/regional-manager-branch'

interface IRegionalManagerBranchDictionaryOwnProps {
  dispatch: AppDispatch
  organizationId: string
  path: string
}

const RegionalManagerBranchDictionary = (props: IRegionalManagerBranchDictionaryOwnProps) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const [openAddFundsModal, setOpenAddFundsModal] = React.useState(false)

  const initialFormValues: IRegionalManagerBranchFormValues = {
    id: new Uint8Array(),
    contractCode: '',
    regionalManager: null,
    organizationRegionPresence: null,
  }

  const initialAddFundsFormValues: IAccountOperationForm = {
    id: new Uint8Array(),
  }

  const modalParams: IRegionalManagerBranchEditModalProps = {
    organizationId: props.organizationId,
    createButtonClickHandler: () => {
      props.dispatch(submit('regionalManagerBranchEditForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(initialize('regionalManagerBranchEditForm', initialFormValues))
    },
    isOpen: open,
    url: url,
  }

  const addFundsModalParams: IAddFundsToAccountModalContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('addFundsToAccountForm'))
    },
    handleClose: () => {
      setOpenAddFundsModal(false)
      props.dispatch(initialize('addFundsToAccountForm', initialAddFundsFormValues))
    },
    isOpen: openAddFundsModal,
    dispatch: props.dispatch,
  }
  return (
    <>
      <AddFundsToAccountForm
        {...addFundsModalParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            const data = AccountOperationForm.create({
              id: values.id,
              date: DateHelper.toNumber(moment()),
              offset: DateHelper.getOffset(),
              freeTestCount: Number(values.freeTestCount),
              easyTestCount: Number(values.easyTestCount),
              mediumTestCount: Number(values.mediumTestCount),
              hardTestCount: Number(values.hardTestCount),
            })

            const task = ProtoClient.post<IAccountOperationForm>(
              url + '/create-account-operation',
              data,
              AccountOperationForm
            )

            await task
            tableRef.current && tableRef.current.onQueryChange()
            setOpenAddFundsModal(false)
            dispatch(initialize('addFundsToAccountForm', initialAddFundsFormValues))
          }
        }}
      />
      <RegionalManagerBranchEditModal
        {...modalParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            const data = RegionalManagerBranch.create({
              id: values.id,
              contractCode: values.contractCode,
              organizationRegionPresenceId: values.organizationRegionPresence.id,
              regionalManagerId: values.regionalManager.id,
              name: values.regionalManager.name,
            })

            const task =
              values.id?.length > 0
                ? ProtoClient.post<IRegionalManagerCreateResult>(
                    url,
                    data,
                    RegionalManagerBranch,
                    RegionalManagerCreateResult
                  )
                : ProtoClient.put<IRegionalManagerCreateResult>(
                    url,
                    data,
                    RegionalManagerBranch,
                    RegionalManagerCreateResult
                  )

            const result = await task

            if (!result.success) {
              throw new SubmissionError({
                _error: 'Для этого регионального менеждера с указанным регионом присутствия филиал уже создан',
              })
            }

            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(initialize('regionalManagerBranchEditForm', initialFormValues))
          }
        }}
      />
      <RegionalManagerBranchTable
        tableRef={tableRef}
        organizationId={props.organizationId}
        onAddButtonClick={() => {
          setOpen(true)
          props.dispatch(initialize('regionalManagerBranchEditForm', initialFormValues))
        }}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          const values: IRegionalManagerBranchFormValues = {
            contractCode: rowData.contractCode,
            id: rowData.id,
            organizationRegionPresence: {
              id: rowData.organizationRegionPresenceId,
              fullName: rowData.oragnizationRegionPresence,
            },
            regionalManager: {
              id: rowData.regionalManagerId,
              fullName: `${rowData.shortName}, ${rowData.name}`,
              name: rowData.name,
            },
          }

          props.dispatch(initialize('regionalManagerBranchEditForm', values))
          setOpen(true)
        }}
        onAddFundsButtonClick={(rowData) => {
          props.dispatch(
            initialize('addFundsToAccountForm', {
              id: rowData.id,
              organizationName: `${rowData.name}`,
              amount: 0,
              easyTestCount: 0,
              freeTestCount: 0,
              mediumTestCount: 0,
              hardTestCount: 0,
            })
          )
          setOpenAddFundsModal(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(RegionalManagerBranchDictionary)
