import MaterialTable, { Query, QueryResult } from 'material-table'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { PagingAndSort } from 'shared/proto/models'

import { IProvisorPayment, IProvisorPayments, ProvisorPayments } from '../../../proto/models'
import { IAppState } from '../../../reducers/rootReducer'
import { tableIcons } from '../../shared/tableIcons'

interface IProvisorPaymentDictionaryContext {
  dispatch: Dispatch
  path: string
}

class ProvisorPaymentDictionary extends React.Component<IProvisorPaymentDictionaryContext> {
  constructor(props: IProvisorPaymentDictionaryContext) {
    super(props)

    this.tableRef = React.createRef()
  }

  private tableRef: any

  private url = 'dictionaries/provisor-payment'

  private fetchProvisorInvites = async (query: Query<IProvisorPayment>): Promise<QueryResult<IProvisorPayment>> => {
    const paging = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: query.orderBy?.field ?? 'email',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
    })

    const provisorInvites = await ProtoClient.get<IProvisorPayments>(this.url, ProvisorPayments, paging)

    return {
      data: provisorInvites?.items ?? [],
      page: query.page,
      totalCount: provisorInvites?.rowCount ?? 0,
    }
  }

  render() {
    return (
      <MaterialTable<IProvisorPayment>
        tableRef={this.tableRef}
        localization={LocalizationHelper.GetLocalization()}
        icons={tableIcons}
        title="Оплата провизорам"
        columns={[
          {
            title: 'Телефон',
            field: 'phone',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'ФИО',
            field: 'fullName',
          },
          {
            title: 'Баланс',
            field: 'balance',
            render: (rowdata) =>
              Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'decimal',
              }).format(rowdata.balance),
          },
          {
            title: 'Дата списания',
            field: 'checkoutDate',
            render: (rowdata) =>
              rowdata.checkoutDate ? DateHelper.fromNumber(rowdata.checkoutDate as number).format('DD.MM.YYYY') : '',
          },
        ]}
        options={{
          emptyRowsWhenPaging: false,
          draggable: false,
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          selection: true,
        }}
        data={this.fetchProvisorInvites}
      />
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(ProvisorPaymentDictionary)
