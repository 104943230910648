import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
} from '@material-ui/core'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import {
  IRegionPresenceSearchResult,
  IRegionPresenceSearchResults,
  RegionPresenceSearchResults,
} from '../../../proto/models'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import Autocomplete from '@material-ui/lab/Autocomplete'

export interface EditFormOwnProps {
  handleClose: (values: any) => void
  isOpen: boolean
}

const url = 'dictionaries/region-of-presence/search'

const onSearchValueChange = async (searchString: string): Promise<IRegionPresenceSearchResult[]> => {
  if (!searchString || searchString === '') {
    return []
  }
  const res = await ProtoClient.get<IRegionPresenceSearchResults>(url, RegionPresenceSearchResults, {
    searchString: searchString,
  })
  return res?.searchResults ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const AutocompleteField = (props: any) => {
  const [options, setOptions] = React.useState<IRegionPresenceSearchResult[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const required = (value: any) => (value ? undefined : 'Не заполнено обязательное поле')

const EditForm = (
  props: EditFormOwnProps & InjectedFormProps<{ regionPresence: IRegionPresenceSearchResult }, EditFormOwnProps, string>
) => {
  const { handleSubmit, submitting, error } = props

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Регион присутствия</DialogTitle>
        <DialogContent>
          <Box p={1}>
            <FormControl>
              <Box p={1}>
                <FormLabel component="legend">Регион присутствия</FormLabel>
                <Field
                  name={'regionPresence'}
                  component={AutocompleteField}
                  placeholder={'Выберите регион присутствия'}
                  validate={required}
                />
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" disabled={submitting}>
            Продолжить
          </Button>
          <Button variant="contained" type="button" color="primary" onClick={props.handleClose}>
            Назад
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const RegionPresenceEditForm = reduxForm<{ regionPresence: IRegionPresenceSearchResult }, EditFormOwnProps>({
  form: 'regionPresenceEditForm',
})(EditForm)

export default RegionPresenceEditForm
