import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps, change } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { TextField, RadioGroup } from 'external/rp.ui/components/MaterialReduxForm'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import {
  IManufacturersSearchs,
  ManufacturersSearchs,
  IManufacturersSearch,
  EManufacturerOrganizationType,
  IManufacturerBranch,
} from '../../../../proto/models'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { AppDispatch } from '../../../../reducers/rootReducer'
import getAutocompleteField, {
  AutocompleteFieldProps,
} from 'back-office/components/shared/AutocompleteSearchField/AutocompleteSearchField'
import { IAutocompleteSearchResult } from 'shared/proto/models'

const searchOrganizationRegionPresence =
  'dictionaries/organization-region-presences/search-organization-region-presence'
export interface IManufacturerBranchEditModalProps {
  dispatch?: AppDispatch
  handleClose: (values: any) => void
  createButtonClickHandler: (e: any) => void
  isOpen: boolean
  manufacturersOrganizationsUrl: string
  organizationId: string
  rowType?: EManufacturerOrganizationType
}

export interface IManufacturerBranchEditFormValues {
  id: Uint8Array
  contractCode: string
  organizationType: string | EManufacturerOrganizationType
  organizationRegionPresence: IAutocompleteSearchResult
  administrator?: IManufacturersSearch
  manufacturer?: IManufacturersSearch
}

const onSearchValueChange = async (s: string, url: string): Promise<IManufacturersSearch[]> => {
  if (!s || s === '') {
    return []
  }
  const res = await ProtoClient.get<IManufacturersSearchs>(url, ManufacturersSearchs, { s })
  return res?.manufacturers ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const required = (value) => (value ? undefined : 'Не заполнено обязательное поле')

const AutocompleteField = (props: any) => {
  const [options, setOptions] = React.useState<IManufacturersSearch[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, props.manufacturersOrganizationsUrl).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.manufacturerName === value.manufacturerName}
      options={options}
      renderOption={(option) => option.manufacturerName}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.manufacturerName ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const AutocompleteAdminField = (props: any) => {
  const [options, setOptions] = React.useState<IManufacturersSearch[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, props.manufacturersOrganizationsUrl).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.manufacturerName === value.manufacturerName}
      options={options}
      renderOption={(option) => option.manufacturerName}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.manufacturerName ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const RpAutocompleteField = (props: AutocompleteFieldProps) => getAutocompleteField(props)

const EditForm = (
  props: IManufacturerBranchEditModalProps &
    InjectedFormProps<IManufacturerBranchEditFormValues, IManufacturerBranchEditModalProps, string>
) => {
  const { error, handleSubmit, submitting } = props

  const [type, setType] = React.useState(props.rowType)

  React.useEffect(() => {
    setType(props.rowType)
  }, [props.isOpen])

  const onTypeChange = (type: EManufacturerOrganizationType) => {
    setType(type)
    props.dispatch(change('manufacturersOrganizationEditorForm', 'organizationType', type.toString()))
    props.dispatch(change('manufacturersOrganizationEditorForm', 'manufacturer', null))
    props.dispatch(change('manufacturersOrganizationEditorForm', 'administrator', null))
  }

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Филиал организации производителя</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <Field component={RadioGroup} name={'organizationType'} id={'organizationType'} row>
              <FormControlLabel
                value={`${EManufacturerOrganizationType.Manufacturer}`}
                label="Производитель"
                control={<Radio />}
                onChange={(_) => onTypeChange(EManufacturerOrganizationType.Manufacturer)}
              />
              <FormControlLabel
                value={`${EManufacturerOrganizationType.Teq}`}
                label="TeQ"
                control={<Radio />}
                onChange={(_) => onTypeChange(EManufacturerOrganizationType.Teq)}
              />
            </Field>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Организация</FormLabel>
              {type === EManufacturerOrganizationType.Manufacturer ? (
                <Field
                  name={'manufacturer'}
                  component={AutocompleteField}
                  placeholder={'Выберите производителя'}
                  validate={required}
                  manufacturersOrganizationsUrl={props.manufacturersOrganizationsUrl + '/search'}
                />
              ) : (
                <Field
                  name={'administrator'}
                  component={AutocompleteAdminField}
                  placeholder={'Выберите производителя'}
                  validate={required}
                  manufacturersOrganizationsUrl={props.manufacturersOrganizationsUrl + '/search-administrator'}
                />
              )}
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Номер договора</FormLabel>
              <Field
                placeholder={'Введите номер договора'}
                name={'contractCode'}
                component={TextField}
                validate={required}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Регион присутствия</FormLabel>
              <Field
                placeholder={'Введите регион присутствия'}
                name={'organizationRegionPresence'}
                component={RpAutocompleteField}
                validate={required}
                url={searchOrganizationRegionPresence}
                additionalOptions={{ organizationId: props.organizationId }}
              />
            </FormControl>
          </Box>
          <Box p={1}>{error && <strong>{error}</strong>}</Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.createButtonClickHandler} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ManufacturerBranchEditForm = reduxForm<IManufacturerBranchEditFormValues, IManufacturerBranchEditModalProps>({
  form: 'manufacturersOrganizationEditorForm',
})(EditForm)

export default ManufacturerBranchEditForm
