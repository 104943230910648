import { tableIcons } from 'back-office/components/shared/tableIcons'
import {
  ApplicationVersionStandResult,
  EApplicationType,
  IApplicationVersionStandForm,
  IApplicationVersionStandResult,
} from 'back-office/proto/models'
import RecordActionDialog, { RecordActionParams } from 'external/rp.ui/components/Modal/RecordActionModalDialog'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React from 'react'
import { IPagingAndSort } from 'shared/proto/models'

import { Box, Button, Fab, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { icon } from './AppVersionStandTable.module.scss'

const url = 'back-office/app-version-stand'

interface IAppVersionStandTableProps {
  onEditButtonClick: (rowData: IApplicationVersionStandForm) => any
  onDeleteButtonClick: (rowData: IApplicationVersionStandForm) => any
  onAddButtonClick: () => void
  tableRef: any
}

const fetchData = async (
  query: Query<IApplicationVersionStandForm>
): Promise<QueryResult<IApplicationVersionStandForm>> => {
  const paging: IPagingAndSort = {
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'version',
    sortDirection: query.orderDirection,
  }

  const appVersionStand = await ProtoClient.get<IApplicationVersionStandResult>(
    url,
    ApplicationVersionStandResult,
    paging
  )

  return {
    data: appVersionStand?.items ?? [],
    page: query.page,
    totalCount: appVersionStand?.rowCount ?? 0,
  }
}

const AppVersionStandTable = (props: IAppVersionStandTableProps): React.ReactElement<IAppVersionStandTableProps> => {
  const [isLoading, setLoading] = React.useState(false)

  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <>
      <MTableToolbar {...toolbarProps} />
      <Grid container direction={'row'} alignItems={'flex-start'}>
        <Grid item xs={1}>
          <Box pl={2}>
            <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )

  const ConfirmDeletionModalButton = (props: {
    rowData: any
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab onClick={async () => {}} size="small" color="secondary" aria-label="delete" className={icon}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n запись справочника?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        setLoading(true)
        await props.onAgreeBtnClick(rowData)
        props.tableRef.current && props.tableRef.current.onQueryChange()
        setLoading(false)
      },
      disagreeCallback(): void {},
    }
    return RecordActionDialog(modalParams)
  }

  const editColumnRenderer = (rowData: IApplicationVersionStandForm): JSX.Element => (
    <Fab
      onClick={() => props.onEditButtonClick(rowData)}
      size="small"
      color="primary"
      aria-label="edit"
      className={icon}
    >
      <EditIcon />
    </Fab>
  )

  const deleteColumnRenderer = (rowData: IApplicationVersionStandForm): JSX.Element => (
    <ConfirmDeletionModalButton
      onAgreeBtnClick={props.onDeleteButtonClick}
      rowData={rowData}
      tableRef={props.tableRef}
    />
  )

  const displayType = (type: EApplicationType): string => {
    switch (type) {
      case EApplicationType.Ios:
        return 'IOS'
      case EApplicationType.Android:
        return 'Android'
      default:
        throw 'Not implement type'
    }
  }

  const typeColumnRender = (rowData: IApplicationVersionStandForm): JSX.Element => <>{displayType(rowData.type)}</>

  return (
    <MaterialTable<IApplicationVersionStandForm>
      tableRef={props.tableRef}
      isLoading={isLoading}
      localization={{
        pagination: {
          labelRowsSelect: 'строк',
        },
      }}
      icons={tableIcons}
      columns={[
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: editColumnRenderer,
          width: '200px',
        },
        {
          title: 'Тип приложения',
          field: 'type',
          sorting: false,
          filtering: false,
          width: '300px',
          render: typeColumnRender,
        },
        {
          title: 'Версия приложения',
          field: 'version',
          sorting: true,
          defaultSort: 'asc',
          width: '800px',
        },
        {
          title: 'Стенд по умолчанию',
          field: 'stand',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'delete',
          render: deleteColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query)}
      title="Стенд по умолчанию"
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default AppVersionStandTable
