import { navigate, withPrefix } from 'gatsby'
import React from 'react'

import { List, ListItem, ListSubheader } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocalGroceryStoreSharp from '@material-ui/icons/LocalGroceryStoreSharp'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import SettingsIcon from '@material-ui/icons/Settings'

const Navigation = (props) => {
  return (
    <List component="nav" subheader={<ListSubheader component="div">Справочники</ListSubheader>}>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/billing-settings'))}>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Настройки биллинга" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/categories'))}>
        <ListItemIcon>
          <LocalGroceryStoreSharp />
        </ListItemIcon>
        <ListItemText primary="Бизнес категории" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/course-settings'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Настройки курсов" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/users'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Пользователи" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/user-invite'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Приглашение пользователей" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/provisor-invite'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Приглашение провизоров" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/provisor-payment'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Оплата провизорам" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/administrator-organizations'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Организация администратора" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/regional-manager'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Региональные менеджеры" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/region-of-presence'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Регион присутствия" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/organizations'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Организации" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/certificate-settings/categories'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Настройки сертификатов" />
      </ListItem>
      <ListItem button onClick={(e) => navigate(withPrefix('back-office/system-settings'))}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Настройки системы" />
      </ListItem>
    </List>
  )
}

export default Navigation
