import React from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'
import { IOrganization } from 'back-office/proto/models'
import { clearOrganization, fetchOrganization } from 'back-office/reducers/organizations/organizationReducer'
import { AppDispatch, IAppState } from 'back-office/reducers/rootReducer'

import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import NavigationTab from 'shared/components/NavigationTab/NavigationTab'
import { withPrefix } from 'gatsby'

interface OrganizationPageOwnProps {
  organizationId: string
  path: string
  children: React.ReactNode
}

interface OrganizationPageStateProps {
  organization: IOrganization
  loading: boolean
  dispatch: AppDispatch
}

type OrganizationPageProps = OrganizationPageOwnProps & OrganizationPageStateProps

const OrganizationPage = (props: OrganizationPageProps) => {
  const { children, organizationId, organization, loading, dispatch } = props

  React.useEffect(() => {
    dispatch(fetchOrganization(organizationId))
    return () => dispatch(clearOrganization())
  }, [organizationId])

  const getDataNavigation = () => {
    return [
      {
        id: nextId(),
        name: 'Регионы присутствия',
        link: withPrefix(`back-office/organizations/${organizationId}/regions-of-presence`),
      },
      {
        id: nextId(),
        name: 'Филиал производителя',
        link: withPrefix(`back-office/organizations/${organizationId}/manufacturer-branches`),
      },
      {
        id: nextId(),
        name: 'Филиал аптечной сети',
        link: withPrefix(`back-office/organizations/${organizationId}/pharmacy-organization-branches`),
      },
      {
        id: nextId(),
        name: 'Филиал регионального менеджера',
        link: withPrefix(`back-office/organizations/${organizationId}/regional-manager-branches`),
      },
    ]
  }

  return (
    <Box>
      <Box pl={2} pt={2} pb={1}>
        <Typography variant="h6" noWrap>
          {loading ? '' : `Организация ${organization.name}`}
        </Typography>
      </Box>
      <Box pl={2} pt={2} pb={1}>
        <Paper elevation={2}>
          <NavigationTab data={getDataNavigation()} />
        </Paper>
        {children}
      </Box>
    </Box>
  )
}

const mapStateToProps = (store: IAppState): OrganizationPageStateProps => {
  return {
    dispatch: store.dispatch,
    organization: store.organization.orgnization,
    loading: store.organization.loading,
  }
}

export default connect(mapStateToProps, null)(OrganizationPage)
