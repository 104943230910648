import MaterialTable, { Column, MTableToolbar, Options, Query, QueryResult } from 'material-table'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { initialize, SubmissionError, submit } from 'redux-form'

import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { tableIcons } from 'external/rp.ui/shared/tableIcons'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Fab, Grid, Toolbar } from '@material-ui/core'
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import { PagingAndSort } from 'shared/proto/models'

import {
  EPharmacyOrganizationType,
  IPharmacyOrgAccountOperationForm,
  IPharmacyOrganizationFormDto,
  IPharmacyOrganizationListDto,
  IPharmacyOrganizationResponse,
  IPharmacyOrganizations,
  PharmacyOrgAccountOperationForm,
  PharmacyOrganizationFormDto,
  PharmacyOrganizationResponse,
  PharmacyOrganizations,
} from '../../../../proto/models'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import EditButton from '../../../Buttons/EditButton'
import AddFundsToAccountForm, {
  IAddFundsToAccountModalContext,
} from '../../../dictionaries/AddFundsToAccountModal/AddFundsToAccountModal'
import PharmacyOrganizationBranchEditWindow from './PharmacyOrganizationBranchEditWindow'

interface IEditWindowContext {
  id: ArrayBuffer[]
  organizationType: string
  pharmacy: string
  pharmacyNetwork: string
  contractCode: string
}

interface IPharmacyOrganizationBranchDictionaryOwnProps {
  dispatch: AppDispatch
  organizationId: string
}

const PharmacyOrganizationBranchDictionary = (props: IPharmacyOrganizationBranchDictionaryOwnProps) => {
  const pharmacyOrganizationsUrl = 'dictionaries/pharmacy-organization'
  const tableRef = React.createRef<any>()

  const [rowType, setRowType] = React.useState(EPharmacyOrganizationType.PharmacyNetwork)
  const [open, setOpen] = React.useState(false)
  const [openAddFundsModal, setOpenAddFundsModal] = React.useState(false)

  const initialFormValues: IEditWindowContext = {
    id: [],
    organizationType: EPharmacyOrganizationType.PharmacyNetwork.toString(),
    pharmacy: '',
    pharmacyNetwork: '',
    contractCode: '',
    organizationRegionPresence: null,
  }

  const onAddButtonClick = () => {
    setRowType(EPharmacyOrganizationType.PharmacyNetwork)
    setOpen(true)
    props.dispatch(initialize('pharmacyOrganizationEditWindow', initialFormValues))
  }

  const onEditButtonClick = async (rowData: IPharmacyOrganizationListDto) => {
    setRowType(rowData.organizationType)

    const pharmacyOrganization = await ProtoClient.get<IPharmacyOrganizationFormDto>(
      pharmacyOrganizationsUrl + '/edit',
      PharmacyOrganizationFormDto,
      { id: GuidHelper.toString(rowData.id) }
    )

    const values: any = {
      contractCode: pharmacyOrganization.contractCode,
      id: pharmacyOrganization.id,
      organizationType: pharmacyOrganization.organizationType.toString(),
      organizationRegionPresence: {
        id: rowData.organizationRegionPresenceId,
        fullName: rowData.organizationRegionPresence,
      },
    }

    const isPharmacy = pharmacyOrganization.organizationType == EPharmacyOrganizationType.Pharmacy
    if (isPharmacy) {
      values.pharmacy = { id: pharmacyOrganization.pharmacyId, name: pharmacyOrganization.pharmacyName }
    } else {
      values.pharmacyNetwork = {
        id: pharmacyOrganization.pharmacyNetworkId,
        name: pharmacyOrganization.pharmacyNetworkName,
      }
    }

    props.dispatch(initialize('pharmacyOrganizationEditWindow', values))

    setOpen(true)
  }

  const fetchPharmacyOrganization = async (
    query: Query<IPharmacyOrganizationListDto>
  ): Promise<QueryResult<IPharmacyOrganizationListDto>> => {
    const paging = PagingAndSort.create({
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: 'name',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
      organizationId: props.organizationId,
    })

    const pharmacyOrganizations = await ProtoClient.get<IPharmacyOrganizations>(
      pharmacyOrganizationsUrl,
      PharmacyOrganizations,
      paging
    )

    return {
      data: pharmacyOrganizations?.items ?? [],
      page: query.page,
      totalCount: pharmacyOrganizations?.rowCount ?? 0,
    }
  }

  const getGrid = () => {
    const localization = {
      toolbar: {
        searchTooltip: 'Поиск',
        searchPlaceholder: 'Поиск',
      },
      pagination: {
        labelRowsSelect: 'строк',
      },
    }

    const onAddFundsButtonClick = (rowData: IPharmacyOrganizationListDto) => {
      props.dispatch(
        initialize('addFundsToAccountForm', {
          id: rowData.id,
          organizationName: rowData.name,
          amount: 0,
          easyTestCount: 0,
          freeTestCount: 0,
          mediumTestCount: 0,
          hardTestCount: 0,
        })
      )
      setOpenAddFundsModal(true)
    }

    const addFundsButtonRenderer = (rowData: IPharmacyOrganizationListDto) => (
      <Fab
        onClick={() => onAddFundsButtonClick(rowData)}
        size="small"
        color="primary"
        aria-label="edit"
        style={{ margin: 0 }}
      >
        <AccountBalanceWallet />
      </Fab>
    )

    const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
      <Box>
        <MTableToolbar {...toolbarProps} />
        <Box ml={2}>
          <Grid container direction={'row'} alignItems={'flex-start'}>
            <Grid item xs={1}>
              <Button variant="contained" color="primary" onClick={onAddButtonClick}>
                Добавить
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )

    const columns: Column<IPharmacyOrganizationListDto>[] = [
      {
        title: '',
        filtering: false,
        sorting: false,
        field: 'edit',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => <EditButton onClick={() => onEditButtonClick(rowData)} />,
      },
      { title: 'Наименование', field: 'name', sorting: true },
      { title: 'Номер договора', field: 'contractCode', sorting: true, defaultSort: 'asc' },
      { title: 'Регион присутствия', field: 'organizationRegionPresence', sorting: false },
      { title: 'Тип организации', field: 'organizationType', sorting: true },
      {
        title: '',
        filtering: false,
        sorting: false,
        field: 'edit',
        render: addFundsButtonRenderer,
      },
    ]

    const options: Options = {
      sorting: true,
      search: true,
      emptyRowsWhenPaging: false,
      debounceInterval: 2000,
      searchFieldAlignment: 'right',
      draggable: false,
      pageSize: 25,
      pageSizeOptions: [25, 50, 75],
    }

    return (
      <MaterialTable<IPharmacyOrganizationListDto>
        tableRef={tableRef}
        localization={localization}
        icons={tableIcons}
        columns={columns}
        options={options}
        data={fetchPharmacyOrganization}
        title="Организации аптек и аптечных сетей"
        components={{
          Toolbar: toolbarRenderer,
        }}
      />
    )
  }

  const onCreateClick = () => {
    props.dispatch(submit('pharmacyOrganizationEditWindow'))
  }

  const onCloseClick = () => {
    setOpen(false)
    props.dispatch(initialize('pharmacyOrganizationEditWindow', initialFormValues))
  }

  const onSubmitForm = async (values: any, dispatch: Dispatch, props: any) => {
    if (props.valid) {
      const data: IPharmacyOrganizationFormDto = {
        id: values.id,
        contractCode: values.contractCode,
        organizationType: +values.organizationType,
        pharmacyId: values.pharmacy ? values.pharmacy.id : '',
        pharmacyNetworkId: values.pharmacyNetwork ? values.pharmacyNetwork.id : '',
        pharmacyChainTeqId: values.pharmacyChainTeq ? values.pharmacyChainTeq.id : '',
        organizationRegionPresenceId: values.organizationRegionPresence.id,
      }

      const formDto = PharmacyOrganizationFormDto.create(data)

      const saveResultTask =
        values.id?.length > 0
          ? ProtoClient.post<IPharmacyOrganizationResponse>(
              pharmacyOrganizationsUrl,
              formDto,
              PharmacyOrganizationFormDto,
              PharmacyOrganizationResponse
            )
          : ProtoClient.put<IPharmacyOrganizationResponse>(
              pharmacyOrganizationsUrl,
              formDto,
              PharmacyOrganizationFormDto,
              PharmacyOrganizationResponse
            )

      const resultResult = await saveResultTask

      if (!resultResult.success) {
        throw new SubmissionError({
          _error: 'Невозможно создать запись. Организация уже создана для аптеки/аптечной сети.',
        })
      }

      tableRef.current && tableRef.current.onQueryChange()
      setOpen(false)
      dispatch(initialize('manufacturersOrganizationEditorForm', initialFormValues))
    }
  }

  const getEditWindow = () => {
    return (
      <PharmacyOrganizationBranchEditWindow
        organizationId={props.organizationId}
        isOpen={open}
        onCreateClick={onCreateClick}
        onCloseClick={onCloseClick}
        organizationType={rowType}
        dispatch={props.dispatch}
        pharmacyOrganizationsUrl={'dictionaries/pharmacy-organization'}
        placeholder={'Плейсхолдер'}
        onSubmit={onSubmitForm}
      />
    )
  }
  const addFundsModalParams: IAddFundsToAccountModalContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('addFundsToAccountForm'))
    },
    handleClose: () => {
      setOpenAddFundsModal(false)
      props.dispatch(initialize('addFundsToAccountForm', { id: [] }))
    },
    isOpen: openAddFundsModal,
    dispatch: props.dispatch,
  }

  const getAddFundsWindow = () => (
    <AddFundsToAccountForm
      {...addFundsModalParams}
      onSubmit={async (values, dispatch, props) => {
        if (props.valid) {
          const data = PharmacyOrgAccountOperationForm.create({
            id: values.id,
            date: DateHelper.toNumber(moment()),
            offset: DateHelper.getOffset(),
            freeTestCount: Number(values.freeTestCount),
            easyTestCount: Number(values.easyTestCount),
            mediumTestCount: Number(values.mediumTestCount),
            hardTestCount: Number(values.hardTestCount),
          })

          const task = ProtoClient.post<IPharmacyOrgAccountOperationForm>(
            pharmacyOrganizationsUrl + '/create-account-operation',
            data,
            PharmacyOrgAccountOperationForm
          )

          await task
          tableRef.current && tableRef.current.onQueryChange()
          setOpenAddFundsModal(false)
          dispatch(initialize('addFundsToAccountForm', { id: [] }))
        }
      }}
    />
  )

  return (
    <>
      {getAddFundsWindow()}
      {getEditWindow()}
      {getGrid()}
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(PharmacyOrganizationBranchDictionary)
