import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Dispatch } from 'redux'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { IAdministratorOrganization, AdministratorOrganization, IPharmacyChainTeq } from '../../proto/models'

const orgUrl = 'registries/administrator-organization'
export interface IAdministratorOrganizationState {
  loading: boolean
  organization: IAdministratorOrganization
  pharmacyChain: IPharmacyChainTeq
  pharmacyChainLoading: boolean
}

const initialState: IAdministratorOrganizationState = {
  loading: false,
  organization: {},
  pharmacyChain: {},
  pharmacyChainLoading: true,
}

export const load = () => async (dispatch: Dispatch) => {
  dispatch(loading())

  const organization = await ProtoClient.get<IAdministratorOrganization>(orgUrl, AdministratorOrganization)

  dispatch(loaded(organization))
}

export const save = (organization: IAdministratorOrganization) => async (dispatch: Dispatch) => {
  await ProtoClient.post(orgUrl, organization, AdministratorOrganization)
}

const LOADING = 'SETTINGS_LOADING'
const loading = createAction(LOADING)
const LOADED = 'SETTINGS_LOADED'
const loaded = createAction<IAdministratorOrganization>(LOADED)

const administratorOrganizationReducer = createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = true
  },
  [LOADED]: (state, action) => {
    state.loading = false
    state.organization = action.payload
  },
})

export default administratorOrganizationReducer
