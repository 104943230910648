import { Box, Button, Fab, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { tableIcons } from 'external/rp.ui/shared/tableIcons'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import MaterialTable, { MTableToolbar, Query, QueryResult } from 'material-table'
import React, { useEffect, useRef, useState } from 'react'
import { IPagingAndSort } from 'shared/proto/models'
import EditIcon from '@material-ui/icons/Edit'
import { CertificatiesListResult, ECertificateStatus, ICertificateList, ICertificatiesListResult } from 'back-office/proto/models'

const url = 'dictionaries/certificates'

interface ICertificateTableProps {
  onEditButtonClick: (rowData: ICertificateList) => any
  onAddButtonClick: () => void
  tableRef: any
}

interface ICertificateProductCategoryPagingAndSort extends IPagingAndSort {
  status: number
}

const CertificateTable = (props: ICertificateTableProps): React.ReactElement<ICertificateTableProps> => {
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = React.useState(0)
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      props.tableRef.current && props.tableRef.current.onQueryChange()
    } else {
      didMountRef.current = true
    }
  }, [status])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as number)
  }

  const fetchData = async (query: Query<ICertificateList>): Promise<QueryResult<ICertificateList>> => {
    const paging: ICertificateProductCategoryPagingAndSort = {
      pageIndex: query.page,
      pageSize: query.pageSize,
      sortColumn: query.orderBy?.field ?? 'name',
      sortDirection: query.orderDirection,
      searchQuery: query.search,
      status: status,
    }

    const certificates = await ProtoClient.get<ICertificatiesListResult>(url, CertificatiesListResult, paging)

    return {
      data: certificates?.items ?? [],
      page: query.page,
      totalCount: certificates?.rowCount ?? 0,
    }
  }

  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <div style={{ marginTop: '20px', display: 'inline-flex' }}>
        <MTableToolbar {...toolbarProps} />
        <FormControl style={{ width: '200px' }}>
          <InputLabel id="demo-controlled-open-select-label">Статус</InputLabel>
          <Select id="status" value={status} onChange={handleChange}>
            <MenuItem value={0}>Все</MenuItem>
            <MenuItem value={ECertificateStatus.Draft}>Черновик</MenuItem>
            <MenuItem value={ECertificateStatus.Published}>Опубликован</MenuItem>
            <MenuItem value={ECertificateStatus.Archived}>Не активен</MenuItem>
          </Select>
        </FormControl>
        <Box ml={2}>
          <Grid container direction={'row'} alignItems={'flex-start'}>
            <Grid item xs={1}>
              <Button
                style={{ marginLeft: 25, width: '300px' }}
                variant="contained"
                color="primary"
                onClick={props.onAddButtonClick}
              >
                Добавить сертификат
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Box>
  )

  const editColumnRenderer = (rowData: ICertificateList): JSX.Element => (
    <Fab
      onClick={() => props.onEditButtonClick(rowData)}
      size="small"
      color="primary"
      aria-label="edit"
      style={{ margin: 0 }}
    >
      <EditIcon />
    </Fab>
  )

  const sertificatePictureColumnRenderer = (rowData: ICertificateList): JSX.Element =>
    rowData.image?.size !== 0 ? (
      <img
        src={
          'data:image/png;base64,' +
          btoa(new Uint8Array(rowData.image.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
        }
        style={{ width: '125px', height: '100px', borderRadius: '10%' }}
      />
    ) : (
      <></>
    )

  return (
    <MaterialTable<ICertificateList>
      tableRef={props.tableRef}
      isLoading={isLoading}
      localization={{
        toolbar: {
          searchTooltip: 'Поиск',
          searchPlaceholder: 'Поиск',
        },
        pagination: {
          labelRowsSelect: 'строк',
        },
      }}
      icons={tableIcons}
      columns={[
        {
          title: 'Изображение',
          field: 'image',
          filtering: false,
          sorting: false,
          render: sertificatePictureColumnRenderer,
        },
        {
          title: 'Продавец',
          field: 'sellerName',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Категории',
          field: 'productCategoryName',
          filtering: false,
          sorting: false,
        },
        {
          title: 'Срок действия, мес',
          field: 'certificateTime',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Номинал',
          field: 'denomination',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Подписка, мес',
          field: 'subscriptionTerm',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Валюта',
          field: 'currencyCode',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Стоимость TEQ',
          field: 'teqCost',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: 'Статус',
          field: 'status',
          sorting: true,
          defaultSort: 'asc',
        },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'edit',
          render: editColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: true,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        searchFieldAlignment: 'left',
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query)}
      title=""
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default CertificateTable
