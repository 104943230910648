import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook';
import { EOrderStatus } from 'back-office/proto/models';
import { loadOrderInfo, rejectOrder, sendOrder } from 'back-office/reducers/orders/ordersReducer';
import classNames from 'classnames';
import { DateHelper } from 'external/rp.ui/helpers/DateHelper';
import { navigate, withPrefix } from 'gatsby-link';
import React, { useEffect } from 'react';
import LoaderSpinner from 'shared/components/LoaderSpinner';
import { IImage } from 'shared/proto/models';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import {
    container, description, fakeImageStyle, header, headerText, imageStyle, parameters, warning
} from './OrderInfo.module.scss';

interface IOrderInfoOwnProps {
  id: string
}

const getStatus = (status: EOrderStatus): string => {
  switch (status) {
    case EOrderStatus.InProgress:
      return 'В обработке'
    case EOrderStatus.Done:
      return 'Завершен'
    case EOrderStatus.Rejected:
      return 'Отклонен'
    default:
      return 'status not inmplement'
  }
}

const OrderInfo = (props: IOrderInfoOwnProps) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.order.loading)
  const orderInfo = useAppSelector((state) => state.order.orderInfo)
  const certificateOrderInfo = useAppSelector((state) => state.order.certificateOrderInfo)

  useEffect(() => {
    dispatch(loadOrderInfo(props.id))
  }, [])

  const sendingOrder = () => {
    dispatch(sendOrder(props.id))
  }

  const rejectingOrder = () => {
    dispatch(rejectOrder(props.id))
  }

  const getImage = (image: IImage): JSX.Element => {
    return image && image?.size !== 0 ? (
      <img
        src={
          'data:image/png;base64,' +
          btoa(new Uint8Array(image.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
        }
        className={imageStyle}
      />
    ) : (
      <div className={fakeImageStyle}></div>
    )
  }

  return (
    <div>
      {loading ? (
        <>
          <LoaderSpinner />
        </>
      ) : (
        <>
          <Box pl={2} pt={2} pb={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(withPrefix('back-office/certificate-settings/order'))
              }}
            >
              Вернуться
            </Button>
          </Box>
          <Box pl={2} pt={1} pb={1}>
            <Typography variant="h6" noWrap>
              Новый сертификат
            </Typography>
          </Box>
          <Box pl={2} pt={1} pb={1}>
            <div className={header}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>Номер заказа</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>ФИО получателя</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>Email получателя</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>Телефон</span>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={headerText}>{orderInfo.number}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={headerText}>{orderInfo.recipient}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={headerText}>{orderInfo.email}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={headerText}>{orderInfo.phone}</span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box pt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>Дата создания курса</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>Отправить не позднее</span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span>Статус</span>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={headerText}>
                        {DateHelper.fromNumber(orderInfo.createDate as number).format('DD.MM.YYYY')}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={classNames(headerText, { [warning]: orderInfo.isDeadLineOver })}>
                        {DateHelper.fromNumber(orderInfo.deadLineDate as number).format('DD.MM.YYYY')}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box pl={2}>
                      <span className={headerText}>{getStatus(orderInfo.status)}</span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>
          <Box pl={2} pt={2} pb={1}>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box pl={2} pt={2}>
                      Изображение
                    </Box>
                    <Box pt={2}>{getImage(certificateOrderInfo.image)}</Box>
                    <Box pl={2} pt={3}>
                      Категория:
                    </Box>
                    <Box pl={2} pt={3}>
                      Продавец:
                    </Box>
                    <Box pl={2} pt={3}>
                      Номинал:
                    </Box>
                    <Box pl={2} pt={3}>
                      Срок действия:
                    </Box>
                    <Box pl={2} pt={3}>
                      Количество:
                    </Box>
                    <Box pl={2} pt={3}>
                      Стоимость TeQ:
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box pl={2} pt={2}>
                      Описание
                    </Box>
                    <Box className={parameters}>
                      <Box pl={2} pt={2} className={description}>
                        <div className={container}>{certificateOrderInfo.description}</div>
                      </Box>
                      <Box pl={2} pt={3}>
                        {certificateOrderInfo.productCategory}
                      </Box>
                      <Box pl={2} pt={3}>
                        {certificateOrderInfo.seller}
                      </Box>
                      <Box pl={2} pt={3}>
                        {certificateOrderInfo.denomination}
                      </Box>
                      <Box pl={2} pt={3}>
                        {certificateOrderInfo.certificateTime} мес.
                      </Box>
                      <Box pl={2} pt={3}>
                        {certificateOrderInfo.quantity} шт.
                      </Box>
                      <Box pl={2} pt={3}>
                        {certificateOrderInfo.teqCost} teq
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Box pl={4} pt={6}>
                  <Button onClick={sendingOrder} disabled={orderInfo.status !== EOrderStatus.InProgress} variant="contained" type="submit" color="primary">
                    Отправить
                  </Button>
                </Box>
                <Box pl={4} pt={4}>
                  <Button onClick={rejectingOrder} disabled={orderInfo.status !== EOrderStatus.InProgress} variant="contained" type="submit" color="primary">
                    Отклонить
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  )
}

export default OrderInfo
