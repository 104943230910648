import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, Typography } from '@material-ui/core'
import { useAppDispatch, useAppSelector } from 'back-office/components/App/hook'
import { ISmsCodeForm } from 'back-office/proto/models'
import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import RadioGroup from 'external/rp.ui/components/MaterialReduxForm/RadioGroup'
import React from 'react'
import { change, Field, Form, InjectedFormProps, reduxForm, submit, Validator } from 'redux-form'

const validate = (values) => {
  const errors = {}

  if(values.isDevMode === 'true') {
    const invalidValue = !values.developmentValidCode

    if(invalidValue || values.developmentValidCode.length != 4) {
      errors.developmentValidCode = 'Код должен быть 4-х значным'
    }

    if(invalidValue || values.developmentValidCode.toString().trim() === '') {
      errors.developmentValidCode = 'Не заполнено обязательное поле'
    }
  }

  return errors
}

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched & invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )
}

const normalizeSmsCode = (value) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length > 3) {
    return onlyNums.slice(0, 4)
  }

  return onlyNums
}

const Forms = (props: InjectedFormProps<ISmsCodeForm, string>) => {
  const [disableCode, setDisableCode] = React.useState(false)
  const { handleSubmit, submitting } = props

  const loading = useAppSelector((state) => state.smsCode.loading)
  const smsCode = useAppSelector((state) => state.smsCode.smsCode)
  const dispatch = useAppDispatch()

  const isLoadProps = Object.keys(smsCode).length !== 0

  React.useEffect(() => {
    if (isLoadProps) {
        setDisable(smsCode.isDevMode.toString())
    }
  }, [smsCode.isDevMode])

  const onIsDevModeChange = (isDevMode: string) => {
    setDisable(isDevMode)
    dispatch(change('smsCode', 'isDevMode', isDevMode))
    dispatch(change('smsCode', 'developmentValidCode', null))
  }

  const setDisable = (isDevMode: string) => {
    if (isDevMode === 'false') {
      setDisableCode(true)
    } else {
      setDisableCode(false)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box>
          <Box pl={2} pt={2} pb={1}>
            <Typography variant="h6" noWrap>
              Отправка СМС
            </Typography>
          </Box>
          <Box p={1} pl={2}>
            <Box pb={1}>
              <FormLabel component="legend">Генерировать случайный код?</FormLabel>
            </Box>
            <FormControl fullWidth>
              <Field component={RadioGroup} name={'isDevMode'} id={'isDevMode'} row>
                <FormControlLabel
                  value={'true'}
                  label="Нет"
                  control={<Radio />}
                  onChange={() => onIsDevModeChange('true')}
                />
                <FormControlLabel
                  value={'false'}
                  label="Да"
                  control={<Radio />}
                  onChange={() => onIsDevModeChange('false')}
                />
              </Field>
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">Задайте 4-значный код</FormLabel>
              <Box pt={2}>
                <Field
                  component={renderTextField}
                  name={'developmentValidCode'}
                  size="small"
                  type="text"
                  placeholder={'Введите код'}
                  normalize={normalizeSmsCode}
                  disabled={disableCode}
                />
              </Box>
            </FormControl>
          </Box>
          <Box p={1} pl={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(submit('smsCode'))
              }}
              disabled={loading || submitting}
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Form>
    </>
  )
}

let SmsCodeForm = reduxForm<ISmsCodeForm>({
  form: 'smsCode', validate
})(Forms)

export default SmsCodeForm
