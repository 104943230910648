import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import {
  IRegionalManagerSearchResult,
  IRegionalManagerSearchResults,
  RegionalManagerSearchResults,
} from 'back-office/proto/models'

import { TextField } from 'external/rp.ui/components/MaterialReduxForm'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'

interface IAutocompleteFieldOwnProps {
  url: string
  additionalOptions: unknown
  placeholder?: string
}

export type AutocompleteFieldProps = IAutocompleteFieldOwnProps & WrappedFieldProps

const onSearchValueChange = async (
  searchString: string,
  url: string,
  additionalOptions?: unknown
): Promise<IRegionalManagerSearchResult[]> => {
  if (!searchString || searchString === '') {
    return []
  }
  const options = {
    searchString: searchString,
  }

  if (additionalOptions) {
    for (const [key, value] of Object.entries(additionalOptions)) {
      options[`${key}`] = value
    }
  }
  const res = await ProtoClient.get<IRegionalManagerSearchResults>(url, RegionalManagerSearchResults, options)
  return res?.searchResults ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const getRegionalManagerSearchField = (props: AutocompleteFieldProps): React.ReactElement<AutocompleteFieldProps> => {
  const [options, setOptions] = React.useState<IRegionalManagerSearchResult[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setLoading(true)
          searchAPIDebounced(value, props.url, props.additionalOptions).then((res) => {
            setOptions(res)
            setLoading(false)
            setOpen(true)
          })
        }
        if (reason === 'clear') {
          setOptions([])
          setLoading(false)
        }
      }}
      loading={loading}
      getOptionSelected={(option, value) => option.fullName === value.fullName}
      options={options}
      renderOption={(option) => option.fullName}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.fullName}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          meta={{ ...props.meta }}
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default getRegionalManagerSearchField
