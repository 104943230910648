import React from 'react'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { submit, initialize } from 'redux-form'
import { connect } from 'react-redux'
import { IAppState } from '../../../../reducers/rootReducer'
import CurrencyExchangeRateTable from './CurrencyExchangeRateTable'
import CurrencyExchangeRateEditForm, { ICurrencyExchangeRateEditWindowContext } from './CurrencyExchangeRateEditWindow'
import { CurrencyExchangeRateForm, ICurrencyExchangeRateForm } from '../../../../proto/models'

const url = 'registries/currency-exchange-rates'

const CurrencyExchangeRateDictionary = (props: any) => {
  const tableRef = React.createRef<any>()
  const [open, setOpen] = React.useState(false)
  const windowParams: ICurrencyExchangeRateEditWindowContext = {
    createButtonClickHandler: () => {
      props.dispatch(submit('currencyExchangeRateForm'))
    },
    handleClose: () => {
      setOpen(false)
      props.dispatch(
        initialize('currencyExchangeRateForm', {
          id: [],
          currency: null,
          toUsdExchangeRate: '',
        })
      )
    },
    isOpen: open,
    url: url,
  }

  return (
    <>
      <CurrencyExchangeRateEditForm
        {...windowParams}
        onSubmit={async (values, dispatch, props) => {
          if (props.valid) {
            var data = CurrencyExchangeRateForm.create({
              id: values.id,
              currencyId: values.currency.id,
              toUsdExchangeRate: values.toUsdExchangeRate,
            })

            const task =
              values.id?.length > 0
                ? ProtoClient.post<ICurrencyExchangeRateForm>(
                    url,
                    data,
                    CurrencyExchangeRateForm,
                    CurrencyExchangeRateForm
                  )
                : ProtoClient.put<ICurrencyExchangeRateForm>(
                    url,
                    data,
                    CurrencyExchangeRateForm,
                    CurrencyExchangeRateForm
                  )

            await task
            tableRef.current && tableRef.current.onQueryChange()
            setOpen(false)
            dispatch(
              initialize('currencyExchangeRateForm', {
                id: [],
                currency: null,
                toUsdExchangeRate: '',
              })
            )
          }
        }}
      />
      <CurrencyExchangeRateTable
        tableRef={tableRef}
        onDeleteButtonClick={(rowData) => ProtoClient.delete(url, GuidHelper.toString(rowData.id))}
        onEditButtonClick={(rowData) => {
          props.dispatch(
            initialize('currencyExchangeRateForm', {
              id: rowData.id,
              currency: { id: rowData.currencyId, fullName: rowData.fullName },
              toUsdExchangeRate: rowData.toUsdExchangeRate,
            })
          )
          setOpen(true)
        }}
        onAddButtonClick={() => {
          setOpen(true)
        }}
        url={url}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect(mapStateToProps, null)(CurrencyExchangeRateDictionary)
