import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Dispatch } from 'redux'
import { createReducer, createAction } from '@reduxjs/toolkit'
import { IAppState } from '../rootReducer'
import { ICourseSettingsForm, CourseSettingsForm } from '../../proto/models'

const url = 'registries/course-settings'
export interface ICourseSettingsState {
  loading: boolean
  settings: ICourseSettingsForm
}

const initialState: ICourseSettingsState = {
  loading: false,
  settings: {},
}

export const loadCourseSettings = () => async (dispatch: Dispatch) => {
  dispatch({
    type: COURSE_SETTINGS_LOADING,
  })

  const settings = await ProtoClient.get<ICourseSettingsForm>(url, CourseSettingsForm)

  dispatch({
    type: COURSE_SETTINGS_LOADED,
    payload: settings,
  })
}

export const saveCourseSettings = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const state = getState()

  var settingsToSave: ICourseSettingsForm = {
    id: state.courseSettings.settings.id,
    simpleCoursePoints: +state.form.courseSettingsForm.values.simpleCoursePoints,
    middleCoursePoints: +state.form.courseSettingsForm.values.middleCoursePoints,
    difficultCoursePoints: +state.form.courseSettingsForm.values.difficultCoursePoints,
    maxAttemptsCount: +state.form.courseSettingsForm.values.maxAttemptsCount,
    freeCoursePoints: +state.form.courseSettingsForm.values.freeCoursePoints,
  }

  dispatch({
    type: COURSE_SETTINGS_LOADING,
  })

  const settings =
    settingsToSave.id.length > 0
      ? await ProtoClient.post<ICourseSettingsForm>(url, settingsToSave, CourseSettingsForm, CourseSettingsForm)
      : await ProtoClient.put<ICourseSettingsForm>(url, settingsToSave, CourseSettingsForm, CourseSettingsForm)

  dispatch({
    type: COURSE_SETTINGS_LOADED,
    payload: settings,
  })
}

const COURSE_SETTINGS_LOADING = 'COURSE_SETTINGS_LOADING'
const COURSE_SETTINGS_LOADED = 'COURSE_SETTINGS_LOADED'

const courseSettingsReducer = createReducer(initialState, {
  [COURSE_SETTINGS_LOADING]: (state, action) => {
    state.loading = true
  },
  [COURSE_SETTINGS_LOADED]: (state, action) => {
    state.loading = false
    state.settings = action.payload
  },
})

export default courseSettingsReducer
