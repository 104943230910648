import React, { ReactNode } from 'react'
import { Field, reduxForm, Form, InjectedFormProps, change, reset } from 'redux-form'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import {
  IPharmacyOrganizationFormDto,
  EPharmacyOrganizationType,
  IPharmacyOrganizationSearch,
  PharmacyOrganizationSearches,
  IPharmacyOrganizationSearches,
} from '../../../../proto/models'
import { Dispatch } from 'redux'
import Autocomplete, { AutocompleteInputChangeReason, RenderInputParams } from '@material-ui/lab/Autocomplete'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { TextField, RadioGroup } from 'external/rp.ui/components/MaterialReduxForm'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import getAutocompleteField, {
  AutocompleteFieldProps,
} from 'back-office/components/shared/AutocompleteSearchField/AutocompleteSearchField'

const searchOrganizationRegionPresence =
  'dictionaries/organization-region-presences/search-organization-region-presence'

interface IPharmacyOrganizationBranchEditWindowProps {
  isOpen: boolean
  onCreateClick: (e: any) => void
  onCloseClick: (values: any) => void
  organizationType: EPharmacyOrganizationType
  dispatch: Dispatch
  pharmacyOrganizationsUrl: string
  placeholder: string
  organizationId: string
}

const onSearchValueChange = async (searchQuery: string, url: string): Promise<IPharmacyOrganizationSearch[]> => {
  if (!searchQuery) {
    return []
  }

  const searchResult = await ProtoClient.get<IPharmacyOrganizationSearches>(url, PharmacyOrganizationSearches, {
    searchQuery,
  })

  return searchResult?.pharmacyOrganizations ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const getPharmacySelectField = (props: any) => {
  const [options, setOptions] = React.useState<IPharmacyOrganizationSearch[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const {
    input: { onChange },
  } = props

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onInputChange = (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setLoading(true)

      searchAPIDebounced(value, props.pharmacyOrganizationsUrl).then((searchResult) => {
        setOptions(searchResult)
        setLoading(false)
        setOpen(true)
      })
    } else if (reason === 'clear') {
      setOptions([])
      setLoading(false)
    }
  }

  const onSelectChange = (event: any, value: any, reason: any, details?: any) => {
    onChange(value)
  }

  const renderInput = (params: RenderInputParams): ReactNode => {
    return (
      <TextField
        meta={{ ...props.meta }}
        {...params}
        placeholder={props.placeholder}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )
  }

  return (
    <Autocomplete
      multiple={false}
      freeSolo
      style={{ width: '400px' }}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={onInputChange}
      loading={loading}
      getOptionSelected={(option, value) => option.name === value.name}
      options={options}
      renderOption={(option) => option.name}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option?.name ?? ''}
      loadingText="Загрузка"
      clearText="Очистить"
      noOptionsText="Нет данных"
      defaultValue={props.input.value}
      onChange={onSelectChange}
      renderInput={renderInput}
    />
  )
}

const PharmacySelect = (props: any): JSX.Element => {
  return getPharmacySelectField(props)
}

const PharmacyNetworkSelect = (props: any): JSX.Element => {
  return getPharmacySelectField(props)
}

const validatePharmacy = (value: any) =>
  value ? undefined : 'Невозможно сохранить запись. Не заполнено обязательное поле “Организация”'
const validateContractCode = (value: any) =>
  value ? undefined : 'Невозможно сохранить запись. Не заполнено обязательное поле “Номер договора”'

const onContractCodeChange = (event: any) => {
  const maxContractCodeLength = 12
  if (event.target.value.length < maxContractCodeLength) {
    return
  }

  event.target.value = event.target.value.slice(0, 12)
}

function getOrgField(
  organizationType: EPharmacyOrganizationType,
  props: IPharmacyOrganizationBranchEditWindowProps &
    InjectedFormProps<IPharmacyOrganizationFormDto, IPharmacyOrganizationBranchEditWindowProps, string>
): React.ReactNode {
  switch (organizationType) {
    case EPharmacyOrganizationType.Pharmacy:
      return (
        <Field
          name={'pharmacy'}
          component={PharmacySelect}
          placeholder={'Введите наименование организации'}
          validate={validatePharmacy}
          pharmacyOrganizationsUrl={props.pharmacyOrganizationsUrl + '/search-pharmacies'}
        />
      )
    case EPharmacyOrganizationType.PharmacyNetwork:
      return (
        <Field
          name={'pharmacyNetwork'}
          component={PharmacyNetworkSelect}
          placeholder={'Введите наименование организации'}
          validate={validatePharmacy}
          pharmacyOrganizationsUrl={props.pharmacyOrganizationsUrl + '/search-pharmacies-networks'}
        />
      )
  }
}

const required = (value) => (value ? undefined : 'Не заполнено обязательное поле')

const RpAutocompleteField = (props: AutocompleteFieldProps) => getAutocompleteField(props)

const EditWindow = (
  props: IPharmacyOrganizationBranchEditWindowProps &
    InjectedFormProps<IPharmacyOrganizationFormDto, IPharmacyOrganizationBranchEditWindowProps, string>
) => {
  const [organizationType, setOrganizationType] = React.useState(props.organizationType)

  const { error, handleSubmit, submitting } = props

  React.useEffect(() => {
    setOrganizationType(props.organizationType)
  }, [props.isOpen])

  const onOrganizationTypeChange = (organizationType: EPharmacyOrganizationType) => {
    setOrganizationType(organizationType)
    props.dispatch(change('pharmacyOrganizationEditWindow', 'organizationType', organizationType.toString()))
    props.dispatch(change('pharmacyOrganizationEditWindow', 'pharmacy', null))
    props.dispatch(change('pharmacyOrganizationEditWindow', 'pharmacyNetwork', null))
    props.dispatch(change('pharmacyOrganizationEditWindow', 'pharmacyChainTeq', null))
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onCloseClick}>
      <DialogTitle>Филиал аптечной организации</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Box p={1}>
            <FormLabel component="legend">Тип организации</FormLabel>
            <Field component={RadioGroup} name={'organizationType'} id={'organizationType'} row>
              <FormControlLabel
                value={`${EPharmacyOrganizationType.Pharmacy}`}
                label="Аптека"
                control={<Radio />}
                onChange={(_) => onOrganizationTypeChange(EPharmacyOrganizationType.Pharmacy)}
              />
              <FormControlLabel
                value={`${EPharmacyOrganizationType.PharmacyNetwork}`}
                label="Аптечная сеть"
                control={<Radio />}
                onChange={(_) => onOrganizationTypeChange(EPharmacyOrganizationType.PharmacyNetwork)}
              />
            </Field>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Организация</FormLabel>
              {getOrgField(organizationType, props)}
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Номер договора</FormLabel>
              <Field
                style={{ width: '400px' }}
                placeholder={'Введите номер договора'}
                name={'contractCode'}
                component={TextField}
                validate={validateContractCode}
                onInput={onContractCodeChange}
              />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl>
              <FormLabel component="legend">Регион присутствия</FormLabel>
              <Field
                placeholder={'Введите регион присутствия'}
                name={'organizationRegionPresence'}
                component={RpAutocompleteField}
                validate={required}
                url={searchOrganizationRegionPresence}
                additionalOptions={{ organizationId: props.organizationId }}
              />
            </FormControl>
          </Box>
          <Box p={1}>{error && <strong>{error}</strong>}</Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.onCreateClick} disabled={submitting}>
          Продолжить
        </Button>

        <Button variant="contained" color="primary" onClick={props.onCloseClick}>
          Назад
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PharmacyOrganizationBranchEditWindow = reduxForm<
  IPharmacyOrganizationFormDto,
  IPharmacyOrganizationBranchEditWindowProps
>({
  form: 'pharmacyOrganizationEditWindow',
})(EditWindow)

export default PharmacyOrganizationBranchEditWindow
