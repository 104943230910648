import MaterialTable, { Query, QueryResult } from 'material-table'
import React from 'react'
import {
  IOrganizationRegionPresence,
  IOrganizationRegionPresences,
  OrganizationRegionPresences,
} from 'back-office/proto/models'

import RecordActionDialog, { RecordActionParams } from 'external/rp.ui/components/Modal/RecordActionModalDialog'
import { LocalizationHelper } from 'external/rp.ui/helpers/LocalizationHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Box, Button, Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import { PagingAndSort } from 'shared/proto/models'

import { tableIcons } from '../../../shared/tableIcons'

interface ITableProps {
  onDeleteButtonClick: (rowData: IOrganizationRegionPresence) => void
  onAddButtonClick: () => void
  url: string
  tableRef: any
  organizationId: string
}

const fetchData = async (
  query: Query<IOrganizationRegionPresence>,
  url: string,
  organizationId: string
): Promise<QueryResult<IOrganizationRegionPresence>> => {
  const paging = PagingAndSort.create({
    pageIndex: query.page,
    pageSize: query.pageSize,
    sortColumn: query.orderBy?.field ?? 'region',
    sortDirection: query.orderDirection || 'asc',
    searchQuery: query.search,
    organizationId: organizationId,
  })

  const records = await ProtoClient.get<IOrganizationRegionPresences>(url, OrganizationRegionPresences, paging)

  const result: QueryResult<IOrganizationRegionPresence> = {
    data: records?.items ?? [],
    page: query.page,
    totalCount: records?.rowCount ?? 0,
  }

  return result
}

const Table = (props: ITableProps) => {
  const ConfirmDeletionModalButton = (props: {
    rowData: IOrganizationRegionPresence
    onAgreeBtnClick: any
    tableRef: any
  }): React.ReactElement<RecordActionParams> => {
    const modalParams: RecordActionParams = {
      agreeCallbackArgs: props.rowData,
      button: (
        <Fab size="small" color="secondary" aria-label="delete" style={{ margin: 0 }}>
          <DeleteIcon />
        </Fab>
      ),
      title: '',
      body: 'Вы уверены, что хотите Удалить \n запись справочника?',
      agreeString: 'Да',
      disagreeString: 'Нет',
      agreeCallback: async (rowData) => {
        await props.onAgreeBtnClick(rowData)
        props.tableRef.current && props.tableRef.current.onQueryChange()
      },
      disagreeCallback: () => undefined,
    }
    return RecordActionDialog(modalParams)
  }
  const deleteColumnRenderer = (rowData: IOrganizationRegionPresence): JSX.Element => (
    <ConfirmDeletionModalButton
      onAgreeBtnClick={props.onDeleteButtonClick}
      rowData={rowData}
      tableRef={props.tableRef}
    />
  )

  const toolbarRenderer: React.ComponentType<any> = (toolbarProps) => (
    <Box>
      <Box ml={2} pt={2}>
        <Grid container direction={'row'} alignItems={'flex-start'}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={props.onAddButtonClick}>
              Добавить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  return (
    <MaterialTable<IOrganizationRegionPresence>
      title={'Регионы присутствия'}
      tableRef={props.tableRef}
      localization={LocalizationHelper.GetLocalization()}
      icons={tableIcons}
      columns={[
        { title: 'Регион', field: 'region', sorting: true },
        {
          title: '',
          filtering: false,
          sorting: false,
          field: 'view',
          render: deleteColumnRenderer,
        },
      ]}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        debounceInterval: 2000,
        draggable: false,
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
      }}
      data={(query) => fetchData(query, props.url, props.organizationId)}
      components={{
        Toolbar: toolbarRenderer,
      }}
    />
  )
}

export default Table
